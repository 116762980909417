import { Product, ProductMarket } from "../services/product.service";

export const MainProductMarkets: ProductMarket[] = ['public_places', 'renovation', 'access'];

/**
 * Check if a product is in the main markets or not.
 * @param product The product to check.
 * @returns Whether the product is in the main markets.
 */
export function isProductInMainMarkets(product: Product): boolean {
  return MainProductMarkets.includes(product.mkt1);
}

/**
 * Cast a product market to `others` if the product is not in the main markets.
 * @param product The product to cast the market.
 * @returns The product market or `others` if the product is not in the main markets.
 */
export function castProductMarketToMainMarketOrOthers(product: Product): ProductMarket | 'others' {
  return isProductInMainMarkets(product) ? product.mkt1 : 'others';
}

/**
 * Get the URL of the details page of a product.
 * @param product The product to get the URL.
 * @returns The URL of the product details page.
 */
export function getProductDetailsUrl(product: Product) {
  return `https://delabie.fr/produits/${product.ref}`;
}