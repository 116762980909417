import { Button, Container, Stack, Typography } from '@mui/material';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import { useNavigate } from 'react-router-dom';
import useTranslate from '../../hooks/useTranslate';
import ScreenCenter from './ScreenCenter';

/** Props for the {@link DesktopForbiddenPage} page. */
export interface DesktopForbiddenPageProps {
  /** The message to display. */
  message?: string;
}

/** A page to display when the user is on a desktop but the page can only be viewed on mobile devices. */
function DesktopForbiddenPage({ message }: DesktopForbiddenPageProps) {
  const { t } = useTranslate();
  const navigate = useNavigate();

  return (
    <ScreenCenter>
      <Container maxWidth="xs">
        <Stack direction="column" spacing={4} alignItems="center">
          <SmartphoneIcon color="primary" />
          <Typography variant="body1" textAlign="center">
            {message}
          </Typography>
          <Button variant="contained" onClick={() => navigate(-1)}>
            {t('global.back')}
          </Button>
        </Stack>
      </Container>
    </ScreenCenter>
  );
}

export default DesktopForbiddenPage;
