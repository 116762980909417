import { useNavigate } from 'react-router-dom';
import { Wholesaler } from '../../../../services/wholesaler.service';
import { TableCell, TableRow } from '@mui/material';
import { getDepartmentNameFromZip } from '../../../../helpers/departments';
import useTranslate from '../../../../hooks/useTranslate';

export interface WholesalerRowProps {
  wholesaler: Wholesaler;
}

function WholesalerRow({ wholesaler }: WholesalerRowProps) {
  const { localeDate } = useTranslate();
  const navigate = useNavigate();

  return (
    <TableRow
      key={wholesaler.id}
      sx={{ '&:last-child td, &:last-child th': { border: 0 }, userSelect: 'none', cursor: 'pointer' }}
      onClick={() => navigate(wholesaler.id)}>
      <TableCell component="th" scope="row">
        {wholesaler.id}
      </TableCell>
      <TableCell component="th" scope="row">
        {wholesaler.name}
      </TableCell>
      <TableCell>
        {wholesaler.city} - {wholesaler.zip}
      </TableCell>
      <TableCell>{getDepartmentNameFromZip(wholesaler.zip)}</TableCell>
      <TableCell>{wholesaler.address1}</TableCell>
      <TableCell>
        {wholesaler.interventionReports[0]?.createdAt
          ? localeDate(wholesaler.interventionReports[0].createdAt, 'short')
          : '-'}
      </TableCell>
    </TableRow>
  );
}

export default WholesalerRow;
