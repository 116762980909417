import { useState } from 'react';
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import useTranslate from '../../../../hooks/useTranslate';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { resetCreateReportFormState } from '../../../../store/slices/create-report-form.slice';

function InterventionReportsCard() {
  const { t } = useTranslate();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const dispatch = useAppDispatch();
  const { wholesalerId, updatedAt, step } = useAppSelector(state => state.createReportForm);
  const [showExistingReportDialog, setShowExistingReportDialog] = useState(false);

  return (
    <>
      <Card variant="outlined">
        <Typography variant="h6" component="h2" fontWeight="regular">
          {t('dashboard.report_creation')}
        </Typography>
        <Typography variant="caption" color="text.secondary" component="p" lineHeight="normal" mt={1} mb={2}>
          {t('dashboard.report_creation_description')}
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          fullWidth={isMobile}
          href="/report/create?step=1"
          onClick={evt => {
            const updateDate = new Date(updatedAt);
            const oneDayAgo = new Date();
            oneDayAgo.setDate(oneDayAgo.getDate() - 1);
            if (wholesalerId && updateDate > oneDayAgo) {
              evt.preventDefault();
              setShowExistingReportDialog(true);
            } else {
              dispatch(resetCreateReportFormState());
            }
          }}>
          {t('dashboard.create_report')}
        </Button>
      </Card>

      <Dialog
        open={showExistingReportDialog}
        onClose={() => setShowExistingReportDialog(false)}
        aria-labelledby="existing-report-dialog-title"
        aria-describedby="existing-report-dialog-description">
        <DialogTitle id="existing-report-dialog-title">{t('report.create.report_in_progress')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="existing-report-dialog-description">
            {t('report.create.report_in_progress_description')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={() => dispatch(resetCreateReportFormState())} href="/report/create?step=1">
            {t('report.create.new_report')}
          </Button>
          <Button variant="contained" href={`/report/create?step=${step}`} autoFocus>
            {t('report.create.resume')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default InterventionReportsCard;
