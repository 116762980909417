import { AppBar, Stack, Toolbar } from '@mui/material';

export interface CreateStatementFormBottomBarProps {
  actionsElement?: JSX.Element;
}

function CreateStatementFormBottomBar({ actionsElement }: CreateStatementFormBottomBarProps) {
  return (
    <AppBar position="fixed" color="transparent" sx={{ top: 'auto', bottom: 0, backgroundColor: 'white' }}>
      <Toolbar>
        <Stack direction="row" spacing={2} justifyContent="space-between" width="100%">
          {actionsElement}
        </Stack>
      </Toolbar>
    </AppBar>
  );
}

export default CreateStatementFormBottomBar;
