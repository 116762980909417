import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Config from '../config';
import { prepareHeaders } from '../helpers/services';

export interface WholesalerSize {
  id: number;
  label: string;
}

export interface WholesalerNetwork {
  id: number;
  label: string;
  sizes: WholesalerSize[];
}

export const wholesalerNetworkApi = createApi({
  reducerPath: 'wholesalerNetworkApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${Config.apiBaseUrl}/wholesaler-network`, prepareHeaders }),
  endpoints: (builder) => ({
    getAllWholesalerNetworks: builder.query<WholesalerNetwork[], void>({
      query: () => '',
    }),
  }),
})

export const { useGetAllWholesalerNetworksQuery } = wholesalerNetworkApi;