import useTranslate from '../../../hooks/useTranslate';
import { Container, Grid, Typography } from '@mui/material';
import ActivityMonitoringCard from './components/ActivityMonitoringCard';
import WholesalersCard from './components/WholesalersCard';
import StatementsCard from './components/StatementsCard';
import { useAppSelector } from '../../../store';
import { UserRole } from '../../../services/user.service';
import InterventionReportsCard from './components/InterventionReportsCard';

/** The dashboard of a user. */
function Dashboard() {
  const { t } = useTranslate();

  const { user } = useAppSelector(state => state.auth);

  const isManagerOrDirector = user?.role === UserRole.SALES_MANAGER || user?.role === UserRole.SALES_DIRECTOR;

  return (
    <Container>
      <Typography variant="h5" component="h1" my={2}>
        {t('dashboard.title')}
      </Typography>
      <Grid container spacing={2}>
        {user?.role === UserRole.SALES && (
          <Grid item xs={12} md={6}>
            <StatementsCard />
          </Grid>
        )}
        {user?.role === UserRole.SALES_COORDINATOR && (
          <Grid item xs={12} md={6}>
            <InterventionReportsCard />
          </Grid>
        )}
        <Grid item xs={12} md={isManagerOrDirector ? 12 : 6}>
          <WholesalersCard />
        </Grid>
        <Grid item xs={12}>
          <ActivityMonitoringCard />
        </Grid>
      </Grid>
    </Container>
  );
}

export default Dashboard;
