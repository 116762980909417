import { useCallback, useEffect, useRef, useState } from 'react';
import { BarcodeDetectorPolyfill } from '@undecaf/barcode-detector-polyfill';
import { Box, Fab } from '@mui/material';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import FlashOffIcon from '@mui/icons-material/FlashOff';

try {
  (window as any)['BarcodeDetector'].getSupportedFormats();
} catch {
  (window as any)['BarcodeDetector'] = BarcodeDetectorPolyfill;
}

const detector = new (window as any)['BarcodeDetector']({ formats: ['ean_13'] });

export interface BarcodeScannerProps {
  onUpdate: (barcodeRef: string) => void;
  onReady?: () => void;
}

function isIOS() {
  //@ts-ignore
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  //@ts-ignore
  return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
}

async function startCamera(useFlashlight?: boolean, onFlashlightError?: () => void) {
  if ('mediaDevices' in navigator && navigator.mediaDevices.getUserMedia) {
    let constraints = {
      audio: false,
      video: {
        facingMode: 'environment', // Utiliser la caméra arrière si possible
        width: { ideal: 1920 }, // Demander la meilleure largeur possible
        height: { ideal: 1080 }, // Demander la meilleure hauteur possible
        experimental: {
          focusMode: 'continuous',
          focusDistance: 0,
        },
      },
    };
    const mediaDevices = await navigator.mediaDevices.enumerateDevices();
    for (let mediaDevice of mediaDevices) {
      if (!isIOS() && mediaDevice.kind === 'videoinput') {
        if (mediaDevice.label.includes('0')) {
          constraints = {
            audio: false,
            video: {
              //@ts-ignore
              deviceId: mediaDevice.deviceId,
            },
          };
        }
      }
    }
    // Demander l'accès à la caméra avec les contraintes spécifiées
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(function (stream) {
        const videoElement = document.getElementById('camera');
        if (videoElement) {
          (videoElement as any).srcObject = stream; // Associer le flux vidéo à l'élément <video>
          if (useFlashlight && stream.getVideoTracks().length > 0) {
            stream
              .getVideoTracks()[0]
              .applyConstraints({
                // @ts-ignore
                advanced: [{ torch: useFlashlight }],
              })
              .catch(() => {
                onFlashlightError?.();
              });
          }
        }
      })
      .catch(function (error) {
        console.error('Erreur d’accès à la caméra : ', error);
      });
  }
}
const BarcodeScanner = ({ onUpdate, onReady }: BarcodeScannerProps) => {
  const webcamRef = useRef<HTMLVideoElement>(null);
  const lastScanTime = useRef<number>(Date.now());
  const lastScanResult = useRef<string | null>(null);

  const [useFlashlight, setUseFlashlight] = useState<boolean>(false);

  const capture = useCallback(async () => {
    try {
      if (webcamRef.current) {
        const barcodes = await detector.detect(webcamRef.current);
        if (barcodes.length > 0) {
          const value = barcodes[0].rawValue;
          if (!isIOS() && value !== lastScanResult.current && Date.now() - lastScanTime.current > 1000) {
            try {
              const bipSound = new Audio('/assets/sounds/scanner-bip.mp3');
              bipSound.play();
            } catch (e) { }
          }
          lastScanResult.current = value;
          lastScanTime.current = Date.now();
          onUpdate(value);
        }
      }
    } catch (err) {
      console.error(err);
    }
    requestAnimationFrame(capture);
  }, [onUpdate]);

  useEffect(() => {
    startCamera(useFlashlight, () => {
      alert('Impossible d’activer le flash.');
      setUseFlashlight(false);
    });
  }, [useFlashlight]);

  return (
    <Box position="relative">
      <Fab
        size="small"
        color="primary"
        aria-label="add"
        sx={{ position: 'absolute', top: 10, right: 10 }}
        onClick={() => setUseFlashlight(!useFlashlight)}>
        {useFlashlight ? <FlashOnIcon /> : <FlashOffIcon />}
      </Fab>

      <video
        id="camera"
        ref={webcamRef}
        autoPlay
        playsInline
        onCanPlay={onReady}
        onPlay={capture}
        style={{ width: '100%', height: 'auto' }}></video>
    </Box>
  );
};

export default BarcodeScanner;
