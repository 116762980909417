import { jwtDecode } from "jwt-decode";
import { UserPayload } from "../store/slices/auth.slice";

/** A REGEX to validate password strengh. */
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\S]{12,}$/g;

/**
 * Decode a JWT token to get the user. If the token is expired, return `null`.
 * @param token The JWT token.
 * @returns The user decoded from the JWT token.
 */
export function getUserFromToken(token: string): UserPayload | null {
  const decoded = jwtDecode<UserPayload & { iat: number; exp: number }>(token);
  const expired = new Date().getTime() / 1000 > decoded.exp;
  if (!expired) {
    return decoded;
  }

  return null;
}

/**
 * Validate a password.
 * @param password The password to validate.
 * @returns Whether the password is valid or not.
 */
export function isPasswordValid(password: string): boolean {
  return PASSWORD_REGEX.test(password);
}