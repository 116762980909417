import { useEffect, useState } from "react";
import { getCurrentYear } from "../../../../utils/date";
import { BenchmarkProposalStatus, BenchmarkProposalSummary, useGetBenchmarkProposalsQuery } from "../../../../services/benchmark-proposal.service";
import { InterventionReportSummaryWithSales, useCountReportsQuery, useGetAllOrphansReportsQuery, useGetNumberOfImplantedMetersQuery } from "../../../../services/intervention-report.service";
import { getMinAndMaxDate } from "../../../../helpers/benchmark-proposal";
import { UserRole, UserSummary, useGetMySubordinatesQuery } from "../../../../services/user.service";

export type ActivityMonotoringRow = {
  type: 'proposal';
  data: BenchmarkProposalSummary;
} | {
  type: 'report';
  data: InterventionReportSummaryWithSales;
};

/** A hook to help with the activity monotoring section of the dashboard. */
function useActivityMonitoring() {
  const [availableYears, setAvailableYears] = useState<number[]>([]);

  const [page, setPage] = useState<number>(1);

  const [year, setYear] = useState<number | 'all'>('all');
  const [month, setMonth] = useState<number | 'all'>('all');
  const [salesId, setSalesId] = useState<number | 'all'>('all');
  const [salesCoordinatorId, setSalesCoordinatorId] = useState<number | 'all'>('all');
  const [state, setState] = useState<BenchmarkProposalStatus | 'all'>('all');

  const [rows, setRows] = useState<ActivityMonotoringRow[]>([]);

  const { data: benchmarkProposals, isFetching: areBenchmarkProposalsLoading } = useGetBenchmarkProposalsQuery({
    page,
    filters: {
      year: year === 'all' ? undefined : year,
      month: month === 'all' ? undefined : month,
      salesId: salesId === 'all' ? undefined : salesId,
      salesCoordinatorId: salesCoordinatorId === 'all' ? undefined : salesCoordinatorId,
      state: state === 'all' ? undefined : state,
    }
  });
  const { data: orphansReports, isFetching: areOrphansReportsLoading } = useGetAllOrphansReportsQuery({
    salesId: salesId === 'all' ? undefined : salesId,
    salesCoordinatorId: salesCoordinatorId === 'all' ? undefined : salesCoordinatorId,
  });
  const { data: numberOfImplantedMeters } = useGetNumberOfImplantedMetersQuery({
    year: year === 'all' ? undefined : year,
    month: month === 'all' ? undefined : month,
    salesId: salesId === 'all' ? undefined : salesId,
    salesCoordinatorId: salesCoordinatorId === 'all' ? undefined : salesCoordinatorId,
    state: state === 'all' ? undefined : state,
  });
  const { data: reportsCount } = useCountReportsQuery({
    year: year === 'all' ? undefined : year,
    month: month === 'all' ? undefined : month,
    salesId: salesId === 'all' ? undefined : salesId,
    salesCoordinatorId: salesCoordinatorId === 'all' ? undefined : salesCoordinatorId,
    state: state === 'all' ? undefined : state,
  });
  const { data: subordinates } = useGetMySubordinatesQuery();

  useEffect(() => {
    const newRows: ActivityMonotoringRow[] = [];
    if (benchmarkProposals) {
      newRows.push(...benchmarkProposals.edges.map(proposal => ({ type: 'proposal', data: proposal }) as ActivityMonotoringRow));
    }
    if (orphansReports) {
      const { minDate, maxDate } = getMinAndMaxDate(benchmarkProposals?.edges ?? []);
      if (benchmarkProposals?.pageInfo?.startCursor === 0) {
        maxDate.setFullYear(maxDate.getFullYear() + 50);
      }
      if (benchmarkProposals?.pageInfo?.hasNextPage === false) {
        minDate.setFullYear(minDate.getFullYear() - 50);
      }

      const relevantOrphansReports = orphansReports.filter(report => {
        if (state !== 'all' && state !== BenchmarkProposalStatus.DONE) {
          return false;
        }
        if (year !== 'all' && new Date(report.createdAt).getFullYear() !== year) {
          return false;
        }
        if (month !== 'all' && new Date(report.createdAt).getMonth() + 1 !== month) {
          return false;
        }
        return new Date(report.createdAt) >= minDate && new Date(report.createdAt) <= maxDate;
      });

      newRows.push(...relevantOrphansReports.map(report => ({ type: 'report', data: report }) as ActivityMonotoringRow));
    }

    newRows.sort((a, b) => {
      const dateA = new Date(a.data.createdAt);
      const dateB = new Date(b.data.createdAt);
      return dateB.getTime() - dateA.getTime();
    });

    setRows(newRows);
  }, [year, month, state, benchmarkProposals, orphansReports]);

  useEffect(() => {
    if (year === 'all') {
      setMonth('all');
    }
  }, [year]);

  // Generate the available years.
  useEffect(() => {
    const currentYear = getCurrentYear();
    const years = [];
    for (let i = currentYear; i >= currentYear - 10; i--) {
      years.push(i);
    }

    setAvailableYears(years);
  }, []);

  const salesCoordinators: UserSummary[] = [];
  for (const subordinate of subordinates ?? []) {
    const managers = subordinate.managedBy.map(relation => relation.manager);
    for (const manager of managers) {
      if (manager.role === UserRole.SALES_COORDINATOR) {
        const alreadyAdded = salesCoordinators.some(sc => sc.id === manager.id);
        if (!alreadyAdded) {
          salesCoordinators.push(manager);
        }
      }
    }
  }

  return {
    year,
    month,
    salesId,
    salesCoordinatorId,
    state,
    availableYears,
    rows,
    loading: areBenchmarkProposalsLoading || areOrphansReportsLoading,
    pageInfo: benchmarkProposals?.pageInfo,
    numberOfImplantedMeters: numberOfImplantedMeters ?? 0,
    reportsCount: reportsCount ?? 0,
    sales: subordinates ?? [],
    salesCoordinators,
    setPage,
    setYear,
    setMonth,
    setSalesId,
    setSalesCoordinatorId,
    setState,
  };
}

export default useActivityMonitoring;
