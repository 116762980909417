import { ThemeOptions, createTheme } from "@mui/material";

const themeOptions: ThemeOptions = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#356B85',
    },
    secondary: {
      main: '#475467',
    },
    background: {
      default: '#fcfcfd',
    },
    text: {
      primary: '#101828',
      secondary: '#475467',
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          padding: '1rem',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        size: "large",
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: '36px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: '0.5rem',
          minHeight: '36px',
        },
      },
    },
  },
});

export default themeOptions;