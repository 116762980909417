import { Alert, Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import ProductStatementRow from './ProductStatementRow';
import useTranslate from '../../hooks/useTranslate';
import { ProductStatement } from '../../store/types';
import { Product } from '../../services/product.service';

export interface ProductStatementTableProps {
  title: string;
  items: ProductStatement[];
  missingProducts?: Product[];
  userQuery?: string;
  onItemClicked?: (item: ProductStatement) => void;
}

function ProductStatementTable({
  title,
  items,
  missingProducts = [],
  userQuery,
  onItemClicked,
}: ProductStatementTableProps) {
  const { t } = useTranslate();

  return (
    <Box>
      {missingProducts.length > 0 && (
        <Alert severity="info" sx={{ mt: 2 }}>
          {t('report.create.missing_product_notice')}
        </Alert>
      )}

      <Table sx={{ '& > tbody tr:nth-of-type(odd)': { backgroundColor: '#00000006' } }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ paddingY: 1 }}>{t('product.title')}</TableCell>
            <TableCell align="right" sx={{ paddingY: 1 }}>
              {t('global.quantity_short')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.length === 0 && missingProducts.length === 0 && (
            <TableRow>
              <TableCell colSpan={2} align="center">
                {Boolean(userQuery)
                  ? t('statement.error.no_product_for_research', {
                      query: userQuery!,
                      market: title,
                    })
                  : t('statement.error.no_product_for_market', {
                      market: title,
                    })}
              </TableCell>
            </TableRow>
          )}
          {items.map(item => (
            <ProductStatementRow key={item.product.ref} item={item} onClick={() => onItemClicked?.(item)} />
          ))}
          {missingProducts.map(product => (
            <ProductStatementRow key={product.ref} item={{ product, quantity: 0 }} showAsMissing />
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}

export default ProductStatementTable;
