import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Hidden,
} from '@mui/material';
import useCreateStatementForm, {
  CREATE_STATEMENT_FORM_PHOTOS_STEP,
  CREATE_STATEMENT_FORM_SCAN_STEP,
  MAX_CREATE_STATEMENT_FORM_STEP,
  MIN_CREATE_STATEMENT_FORM_STEP,
} from './hooks/useCreateStatementForm';
import CreateStatementStep1 from './components/steps/CreateStatementStep1';
import CreateStatementStep2 from './components/steps/CreateStatementStep2';
import CreateStatementFormNavbar from './components/layout/CreateStatementFormNavbar';
import CreateStatementFormBottomBar from './components/layout/CreateStatementFormBottomBar';
import useTranslate from '../../../hooks/useTranslate';
import { useAppDispatch, useAppSelector } from '../../../store';
import CreateStatementStep3 from './components/steps/CreateStatementStep3';
import { useEffect, useState } from 'react';
import CreateStatementStep4 from './components/steps/CreateStatementStep4';
import CreateStatementStep5 from './components/steps/CreateStatementStep5';
import CreateStatementStep6 from './components/steps/CreateStatementStep6';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { resetCreateStatementFormState } from '../../../store/slices/create-statement-form.slice';
import { useCreateBenchmarkStatementMutation } from '../../../services/benchmark-statement.service';
import DesktopForbiddenPage from '../../../components/layout/DesktopForbiddenPage';
import themeConstants from '../../../theme/constants';

function CreateStatement() {
  const { t } = useTranslate();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useAppDispatch();
  const createStatementFromState = useAppSelector(state => state.createStatementForm);
  const { user } = useAppSelector(state => state.auth);

  const { goToStep, isCurrentStepValid, showPhotosUploadingDialog, hidePhotosUploadingDialog } =
    useCreateStatementForm();

  const [showProductList, setShowProductList] = useState<boolean>(searchParams.get('showProductList') === 'true');

  const [createBenchmarkStatement, { isLoading: isCreatingBenchmarkStatement }] = useCreateBenchmarkStatementMutation();

  function renderStep(step: number): JSX.Element | null {
    switch (step) {
      case 1:
        return <CreateStatementStep1 />;
      case 2:
        return <CreateStatementStep2 />;
      case 3:
        return <CreateStatementStep3 />;
      case 4:
        return (
          <CreateStatementStep4
            showProductList={showProductList}
            onHideProductList={() => {
              searchParams.delete('showProductList');
              setSearchParams(searchParams);
            }}
          />
        );
      case 5:
        return <CreateStatementStep5 />;
      case 6:
        return <CreateStatementStep6 />;
      default:
        return null;
    }
  }

  function handleSubmit(evt: React.FormEvent<HTMLFormElement>) {
    evt.preventDefault();
    evt.stopPropagation();

    if (step !== MAX_CREATE_STATEMENT_FORM_STEP) {
      goToStep(step + 1);
      return;
    }

    createBenchmarkStatement({
      salesId: user!.id,
      wholesalerId: createStatementFromState.wholesalerId!,
      wholesalerNetworkId: createStatementFromState.wholesalerNetworkId!,
      wholesalerSizeId: createStatementFromState.wholesalerSizeId!,
      benchmarkLayoutId: createStatementFromState.layoutBenchmarkId!,
      contact: createStatementFromState.contact!,
      contactPosition: createStatementFromState.contactPosition!,
      contactPhone: createStatementFromState.contactPhone!,
      contactEmail: createStatementFromState.contactEmail || undefined,
      trainingRequired: createStatementFromState.isTrainingRequired,
      trainingComments: createStatementFromState.trainingComments || undefined,
      publicPlacesMeters: createStatementFromState.publicPlacesMeters!,
      renovationMeters: createStatementFromState.renovationMeters!,
      accessMeters: createStatementFromState.accessMeters!,
      tabletsAvailable: createStatementFromState.areTabletsAvailable,
      perforedBottomsInstalled: createStatementFromState.arePerforedBottomsInstalled,
      loadBarsAvailable: createStatementFromState.arePerforedBottomsInstalled
        ? undefined
        : createStatementFromState.areLoadBarsAvailable,
      linearHeight: createStatementFromState.linearHeight,
      linearWidth: createStatementFromState.linearWidth,
      photoKeys: createStatementFromState.photoKeys,
      products: createStatementFromState.productStatements.map(stmt => ({
        productRef: stmt.product.ref,
        quantity: stmt.quantity,
      })),
      comments: createStatementFromState.comments || undefined,
    })
      .unwrap()
      .then(response => {
        dispatch(resetCreateStatementFormState());
        navigate(`/benchmark-proposal/${response.benchmarkProposal.id}`);
      })
      .catch(() => {
        alert(t('global.error.unknown'));
      });
  }

  useEffect(() => {
    setShowProductList(searchParams.get('showProductList') === 'true');
  }, [searchParams]);

  const step = createStatementFromState.step;

  let nextStepMessage = t('global.next');
  if (step === 1 && !isCurrentStepValid) {
    nextStepMessage = t('statement.error.select_wholesaler');
  } else if (step === 4) {
    nextStepMessage = t('statement.create.form.listing_over');
  } else if (step === 6) {
    nextStepMessage = t('global.finalize');
  }

  return (
    <>
      <Hidden smDown>
        <DesktopForbiddenPage message={t('statement.error.desktop_forbidden')} />
      </Hidden>

      <Hidden smUp>
        <CreateStatementFormNavbar
          step={step}
          onBackClick={() => goToStep(step - 1)}
          onToggleProductList={() => {
            if (showProductList) {
              searchParams.delete('showProductList');
            } else {
              searchParams.set('showProductList', 'true');
            }
            setSearchParams(searchParams);
          }}
        />

        <form onSubmit={handleSubmit}>
          <Container
            sx={{
              paddingY: step !== CREATE_STATEMENT_FORM_SCAN_STEP ? '1.25rem' : 0,
              paddingX: step !== CREATE_STATEMENT_FORM_SCAN_STEP ? undefined : 0,
              marginBottom: step !== CREATE_STATEMENT_FORM_SCAN_STEP ? `${themeConstants.navbarsHeight}px` : 0,
              marginTop: `${themeConstants.navbarsHeight}px`,
            }}>
            {renderStep(step)}
          </Container>

          <CreateStatementFormBottomBar
            actionsElement={
              <>
                {step > MIN_CREATE_STATEMENT_FORM_STEP && (
                  <Button type="button" variant="outlined" size="medium" onClick={() => goToStep(step - 1)} fullWidth>
                    {t('global.previous')}
                  </Button>
                )}
                <Button type="submit" variant="contained" size="medium" disabled={!isCurrentStepValid} fullWidth>
                  {nextStepMessage}
                </Button>
              </>
            }
          />
        </form>

        <Dialog
          open={showPhotosUploadingDialog}
          onClose={hidePhotosUploadingDialog}
          aria-labelledby="photos-uploading-dialog-title"
          aria-describedby="photos-uploading-dialog-description">
          <DialogTitle id="photos-uploading-dialog-title">
            {t('statement.create.form.photos_uploading_title')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="photos-uploading-dialog-description">
              {t('statement.create.form.photos_uploading_description')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="text"
              onClick={() => goToStep(CREATE_STATEMENT_FORM_PHOTOS_STEP + 1, { ignoreWarnings: true })}>
              {t('global.continue')}
            </Button>
            <Button variant="contained" onClick={hidePhotosUploadingDialog} autoFocus>
              {t('global.wait')}
            </Button>
          </DialogActions>
        </Dialog>

        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={isCreatingBenchmarkStatement}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Hidden>
    </>
  );
}

export default CreateStatement;
