import { useState } from 'react';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import {
  BenchmarkProposal,
  useDeleteBenchmarkProposalAdjustmentMutation,
  useUpdateBenchmarkProposalAdjustmentMutation,
} from '../../../../services/benchmark-proposal.service';
import { ProductAdjustmentWithStatus } from './ProposalCard';
import ProductPresentation from '../../../../components/product/ProductPresentation';
import useTranslate from '../../../../hooks/useTranslate';
import { getProductAdjustmentStatusColor } from '../../../../helpers/benchmark-proposal';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useAppSelector } from '../../../../store';
import { UserRole } from '../../../../services/user.service';

export interface ProposalAdjustmentRowProps {
  /** The proposal the row comes from. */
  proposal: BenchmarkProposal;
  /** The adjustment of to display in the row. */
  adjustment: ProductAdjustmentWithStatus;
  /** Whether the form is disabled. */
  isFormDisabled?: boolean;
  /** Callback fired when the adjustment is deleted. */
  onAdjustmentDeleted?: () => void;
  /** Callback fired when the adjustment is updated. */
  onAdjustmentUpdated?: () => void;
}

function ProposalAdjustmentRow({
  proposal,
  adjustment,
  isFormDisabled,
  onAdjustmentDeleted,
  onAdjustmentUpdated,
}: ProposalAdjustmentRowProps) {
  const { user } = useAppSelector(state => state.auth);

  const { t } = useTranslate();

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);

  const [quantity, setQuantity] = useState<number | null>(adjustment.quantityAdjustment);

  const [deleteProductAdjustment] = useDeleteBenchmarkProposalAdjustmentMutation();
  const [updateProductAdjustment] = useUpdateBenchmarkProposalAdjustmentMutation();

  function onDeleteSubmit(evt: React.FormEvent<HTMLFormElement>) {
    evt.preventDefault();
    evt.stopPropagation();

    if (adjustment.status === 'missing') {
      deleteProductAdjustment({
        benchmarkProposalId: proposal.id,
        adjustmentId: adjustment.id,
      })
        .unwrap()
        .then(() => {
          setShowDeleteDialog(false);
          onAdjustmentDeleted?.();
        })
        .catch(() => {});
    } else {
      let newAdjustmentQuantity = adjustment.quantityAdjustment;
      switch (adjustment.status) {
        case 'keep':
        case 'restock':
          newAdjustmentQuantity = -(statementProduct?.quantity ?? 0);
          break;
        case 'remove':
          newAdjustmentQuantity = 0;
          break;
      }

      updateProductAdjustment({
        benchmarkProposalId: proposal.id,
        adjustmentId: adjustment.id,
        quantity: newAdjustmentQuantity,
      })
        .unwrap()
        .then(() => {
          setShowDeleteDialog(false);
          onAdjustmentDeleted?.();
        })
        .catch(() => {});
    }
  }

  function onUpdateSubmit(evt: React.FormEvent<HTMLFormElement>) {
    evt.preventDefault();
    evt.stopPropagation();

    updateProductAdjustment({
      benchmarkProposalId: proposal.id,
      adjustmentId: adjustment.id,
      quantity: quantity ?? 0,
    })
      .unwrap()
      .then(() => {
        setShowEditDialog(false);
        onAdjustmentUpdated?.();
      })
      .catch(() => {});
  }

  const statementProduct = proposal.benchmarkStatement.products.find(
    item => item.product.ref === adjustment.product.ref,
  );

  const layoutProduct = proposal.benchmarkStatement.benchmarkLayout.products.find(
    item => item.product.ref === adjustment.product.ref,
  );

  return (
    <>
      <TableRow key={adjustment.id}>
        <TableCell>
          <ProductPresentation product={adjustment.product} redirectToDetailsOnClick />
        </TableCell>
        <TableCell>
          <Chip
            label={t(`product_adjustment.status.${adjustment.status}`)}
            size="small"
            color={getProductAdjustmentStatusColor(adjustment.status)}
          />
        </TableCell>
        <TableCell>
          <Typography variant="body2" color={adjustment.status === 'keep' ? 'success.main' : undefined}>
            {statementProduct?.quantity ?? 0}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" color={adjustment.status !== 'keep' ? 'error.main' : undefined}>
            {adjustment.quantityAdjustment}
          </Typography>
        </TableCell>
        <TableCell>{layoutProduct?.minimumQuantity ?? '-'}</TableCell>
        {user?.role === UserRole.SALES && (
          <TableCell align="right">
            <Stack direction="row" spacing={1}>
              <IconButton
                aria-label="delete"
                color="secondary"
                onClick={() => setShowDeleteDialog(true)}
                disabled={isFormDisabled}>
                {(adjustment.status === 'keep' || adjustment.status === 'restock') && <RemoveIcon fontSize="medium" />}
                {adjustment.status === 'remove' && <AddIcon fontSize="medium" />}
                {adjustment.status === 'missing' && <DeleteIcon fontSize="small" />}
              </IconButton>
              <IconButton
                aria-label="edit"
                color="secondary"
                onClick={() => setShowEditDialog(true)}
                disabled={isFormDisabled}>
                <EditIcon fontSize="small" />
              </IconButton>
            </Stack>
          </TableCell>
        )}
      </TableRow>

      <Dialog
        open={showEditDialog}
        onClose={() => setShowEditDialog(false)}
        aria-labelledby="edit-adjustment-title"
        aria-describedby="edit-adjustment-description">
        <form onSubmit={onUpdateSubmit}>
          <DialogTitle id="edit-adjustment-title">{t('product_adjustment.edit_quantity')}</DialogTitle>
          <DialogContent>
            <ProductPresentation product={adjustment.product} />
            <FormGroup sx={{ mt: 2 }}>
              <TextField
                type="number"
                label={t('product_adjustment.value')}
                variant="outlined"
                value={quantity ?? ''}
                onChange={evt => setQuantity(evt.target.value === '' ? null : Number(evt.target.value))}
                fullWidth
                autoFocus
                required
              />
            </FormGroup>
          </DialogContent>
          <DialogActions>
            <Button type="button" variant="outlined" onClick={() => setShowEditDialog(false)}>
              {t('global.cancel')}
            </Button>
            <Button type="submit" variant="contained">
              {t('global.confirm')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Dialog
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        aria-labelledby="delete-adjustment-title"
        aria-describedby="delete-adjustment-description">
        <form onSubmit={onDeleteSubmit}>
          <DialogTitle id="delete-adjustment-title">
            {adjustment.status === 'missing'
              ? t('product_adjustment.confirm_delete')
              : t('product_adjustment.confirm_move')}
          </DialogTitle>
          <DialogContent>
            <ProductPresentation product={adjustment.product} />
            <Typography variant="body1" sx={{ mt: 2 }}>
              {adjustment.status === 'missing'
                ? t('product_adjustment.delete_description')
                : t(`product_adjustment.move_${adjustment.status}`)}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button type="button" variant="outlined" onClick={() => setShowDeleteDialog(false)}>
              {t('global.cancel')}
            </Button>
            <Button type="submit" variant="contained" autoFocus>
              {t('global.confirm')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default ProposalAdjustmentRow;
