import { Chip, Hidden, TableCell, TableRow, Typography } from '@mui/material';
import { getFullName } from '../../../../helpers/user';
import { InterventionReportSummaryWithSales } from '../../../../services/intervention-report.service';
import { getStatusColor } from '../../../../helpers/benchmark-proposal';
import useTranslate from '../../../../hooks/useTranslate';
import { useNavigate } from 'react-router-dom';
import { BenchmarkProposalStatus } from '../../../../services/benchmark-proposal.service';

export interface InterventionReportRowProps {
  report: InterventionReportSummaryWithSales;
}

function InterventionReportRow({ report }: InterventionReportRowProps) {
  const { t, localeDate } = useTranslate();
  const navigate = useNavigate();

  return (
    <TableRow
      sx={{
        '&:last-child td, &:last-child th': { border: 0 },
        userSelect: 'none',
        cursor: 'pointer',
      }}
      onClick={() => navigate(`/report/${report.id}`)}>
      <TableCell>-</TableCell>
      <Hidden smDown>
        <TableCell>
          <Typography variant="body2">{localeDate(report.createdAt, 'short')}</Typography>
        </TableCell>
        <TableCell>{report.sales ? getFullName(report.sales) : '-'}</TableCell>
        <TableCell>{getFullName(report.coordinator)}</TableCell>
        <TableCell>{report.wholesaler.name}</TableCell>
        <TableCell>{report.wholesaler.zip}</TableCell>
        <TableCell>{report.wholesaler.city}</TableCell>
      </Hidden>
      <TableCell>
        <Chip
          label={t(`benchmark_proposal.status.done`)}
          size="small"
          color={getStatusColor(BenchmarkProposalStatus.DONE)}
        />
      </TableCell>
    </TableRow>
  );
}

export default InterventionReportRow;
