import { useState } from 'react';
import { ChevronLeft, Error } from '@mui/icons-material';
import { Box, Button, Drawer, DrawerProps, IconButton, Stack, Toolbar, Typography } from '@mui/material';
import ProductStatementList from './ProductStatementList';
import useTranslate from '../../hooks/useTranslate';
import ManualRefEntryDrawer from './ManualRefEntryDrawer';
import { ProductStatement } from '../../store/types';

export interface LineItemListDrawerProps {
  existingStatements: ProductStatement[];
  visible?: boolean;
  onClose?: DrawerProps['onClose'];
  onItemClicked?: (item: ProductStatement) => void;
  onAddProduct?: (productStatement: ProductStatement) => void;
}

function ProductStatementListDrawer({
  existingStatements,
  visible = false,
  onClose,
  onItemClicked,
  onAddProduct,
}: LineItemListDrawerProps) {
  const { t } = useTranslate();

  const [showManualRefEntry, setShowManualRefEntry] = useState<boolean>(false);

  return (
    <>
      <Drawer anchor="right" open={visible} onClose={onClose}>
        <Box sx={{ width: '100vw' }}>
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="back"
              sx={{ mr: 1 }}
              onClick={evt => onClose?.(evt, 'backdropClick')}>
              <ChevronLeft />
            </IconButton>
            <Typography variant="subtitle1" color="inherit" component="div" sx={{ flexGrow: 1 }}>
              {t('product.list')}
            </Typography>
          </Toolbar>
          <Box px={2}>
            <ProductStatementList
              items={existingStatements}
              onItemClicked={onItemClicked}
              actionsComponent={
                <Stack direction="column" spacing={1} py={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    fullWidth
                    onClick={() => setShowManualRefEntry(true)}>
                    {t('statement.create.manual_ref_entry')}
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="medium"
                    startIcon={<Error />}
                    fullWidth
                    onClick={evt => onClose?.(evt, 'backdropClick')}>
                    {t('statement.create.back_to_scan')}
                  </Button>
                </Stack>
              }
            />
          </Box>
        </Box>
      </Drawer>

      <ManualRefEntryDrawer
        existingStatements={existingStatements}
        visible={showManualRefEntry}
        onClose={() => setShowManualRefEntry(false)}
        onSubmit={onAddProduct}
      />
    </>
  );
}

export default ProductStatementListDrawer;
