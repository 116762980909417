import { useEffect } from 'react';
import {
  AppBar,
  Box,
  Chip,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import useTranslate from '../../../../hooks/useTranslate';
import { getStatusColor } from '../../../../helpers/benchmark-proposal';
import { BenchmarkProposalStatus } from '../../../../services/benchmark-proposal.service';
import { getFullName } from '../../../../helpers/user';
import { UserRole } from '../../../../services/user.service';
import { useAppSelector } from '../../../../store';
import { InterventionReportWithSales } from '../../../../services/intervention-report.service';

export type ReportTabType = 'report' | 'deviation';

export interface ReportHeaderProps {
  report: InterventionReportWithSales;
  selectedTab?: ReportTabType;
  onTabSelected?: (tab?: ReportTabType) => void;
}

function ReportHeader({ report, selectedTab, onTabSelected }: ReportHeaderProps) {
  const { t } = useTranslate();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const { user } = useAppSelector(state => state.auth);

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const tabs: { value: ReportTabType; label: string }[] = [
    { value: 'report', label: t('report.title') },
    { value: 'deviation', label: t('report.deviation_from_proposal') },
  ];

  function handleTabSelected(tab: ReportTabType) {
    setSearchParams(prev => {
      prev.set('tab', tab);
      return prev;
    });
  }

  useEffect(() => {
    const tabFromParams = searchParams.get('tab');
    const exists = tabs.some(tab => tab.value === tabFromParams);
    if (exists) {
      onTabSelected?.(tabFromParams as ReportTabType);
    } else {
      onTabSelected?.(tabs[0]?.value);
    }
  }, [tabs, searchParams]);

  return (
    <AppBar position="relative" color="transparent" sx={{ backgroundColor: 'white', pt: 1 }}>
      <Toolbar>
        <Stack direction="column" spacing={1} flexGrow={1}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="back"
              sx={{ mr: 1, p: 1 }}
              onClick={() => navigate(-1)}>
              <ChevronLeftIcon />
            </IconButton>
            <Chip
              label={t(`benchmark_proposal.status.done`)}
              size="small"
              color={getStatusColor(BenchmarkProposalStatus.DONE)}
            />
            <Typography
              variant={isMobile ? 'subtitle2' : 'subtitle1'}
              color="inherit"
              component="div"
              sx={{ flexGrow: 1 }}>
              {t('report.no_associated_statement')}
            </Typography>
          </Stack>
          <Stack direction={isMobile ? 'column' : 'row'} spacing={isMobile ? 1 : 4} pb={2}>
            <div>
              <Typography variant="caption" component="small" color="text.secondary">
                {t('wholesaler.id')}
              </Typography>
              <Typography variant="body1">{report.wholesaler.id}</Typography>
            </div>
            <div>
              <Typography variant="caption" component="small" color="text.secondary">
                {t('wholesaler.name')}
              </Typography>
              <Typography variant="body1">{report.wholesaler.name}</Typography>
            </div>
            <div>
              <Typography variant="caption" component="small" color="text.secondary">
                {t('global.city')}
              </Typography>
              <Typography variant="body1">
                {report.wholesaler.city} - {report.wholesaler.zip}
              </Typography>
            </div>
            {user?.role !== UserRole.SALES_COORDINATOR && (
              <div>
                <Typography variant="caption" component="small" color="text.secondary">
                  {t('user.role.sales_coordinator')}
                </Typography>
                <Typography variant="body1">{getFullName(report.coordinator)}</Typography>
              </div>
            )}
            {user?.role !== UserRole.SALES && (
              <div>
                <Typography variant="caption" component="small" color="text.secondary">
                  {t('user.role.sales')}
                </Typography>
                <Typography variant="body1">{report.sales ? getFullName(report.sales) : '-'}</Typography>
              </div>
            )}
          </Stack>

          <Box sx={{ marginTop: 1 }}>
            <Tabs
              orientation={isMobile ? 'vertical' : 'horizontal'}
              value={selectedTab}
              onChange={(_, value) => handleTabSelected(value)}
              aria-label="markets">
              <Tab value="report" label={t('report.title')} wrapped sx={{ maxWidth: isMobile ? 'none' : undefined }} />
              <Tab
                value="deviation"
                label={t('report.deviation_from_proposal')}
                wrapped
                sx={{ maxWidth: isMobile ? 'none' : undefined }}
              />
            </Tabs>
          </Box>
        </Stack>
      </Toolbar>
    </AppBar>
  );
}

export default ReportHeader;
