import React from 'react';
import {
  Box,
  Card,
  FormControl,
  Hidden,
  InputLabel,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import useTranslate from '../../../../hooks/useTranslate';
import useActivityMonitoring from '../hooks/useActivityMonitoring';
import { BenchmarkProposalStatus } from '../../../../services/benchmark-proposal.service';
import { UserRole } from '../../../../services/user.service';
import { useAppSelector } from '../../../../store';
import BenchmarkProposalRow from './BenchmarkProposalRow';
import InterventionReportRow from './InterventionReportRow';
import { getFullName } from '../../../../helpers/user';

function ActivityMonitoringCard() {
  const { user } = useAppSelector(state => state.auth);

  const { t, localeNumber } = useTranslate();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const {
    year,
    month,
    salesId,
    salesCoordinatorId,
    state,
    rows,
    loading,
    pageInfo,
    numberOfImplantedMeters,
    reportsCount,
    sales,
    salesCoordinators,
    setPage,
    setYear,
    setMonth,
    setSalesId,
    setSalesCoordinatorId,
    setState,
    availableYears,
  } = useActivityMonitoring();

  const isSales = user?.role === UserRole.SALES;
  const isManagerOrDirector = user?.role === UserRole.SALES_MANAGER || user?.role === UserRole.SALES_DIRECTOR;

  return (
    <Card variant="outlined">
      <Stack
        direction={(isManagerOrDirector && isTablet) || isMobile ? 'column' : 'row'}
        alignItems={(isManagerOrDirector && isTablet) || isMobile ? 'flex-start' : 'center'}
        spacing={2}
        mb={2}>
        <Typography variant="h6" component="h2" fontWeight="regular">
          {t('dashboard.activity_monitoring')}
        </Typography>

        <Stack direction={isManagerOrDirector && isTablet ? 'column' : 'row'} spacing={2}>
          <FormControl size="small" sx={{ minWidth: 100 }} fullWidth>
            <InputLabel id="year-select-label">{t('global.year')}</InputLabel>
            <Select
              labelId="year-select-label"
              id="year-select"
              value={year}
              label={t('global.year')}
              onChange={evt => setYear(evt.target.value === 'all' ? 'all' : Number(evt.target.value))}>
              <MenuItem value="all">{t('global.all_female')}</MenuItem>
              {availableYears.map(year => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl size="small" sx={{ minWidth: 100 }} fullWidth>
            <InputLabel id="month-select-label">{t('global.month')}</InputLabel>
            <Select
              labelId="month-select-label"
              id="month-select"
              value={month.toString()}
              label={t('global.month')}
              onChange={evt => setMonth(evt.target.value === 'all' ? 'all' : Number(evt.target.value))}
              disabled={year === 'all'}>
              <MenuItem value="all">{t('global.all_male')}</MenuItem>
              <MenuItem value={1}>{t('global.month.january')}</MenuItem>
              <MenuItem value={2}>{t('global.month.february')}</MenuItem>
              <MenuItem value={3}>{t('global.month.march')}</MenuItem>
              <MenuItem value={4}>{t('global.month.april')}</MenuItem>
              <MenuItem value={5}>{t('global.month.may')}</MenuItem>
              <MenuItem value={6}>{t('global.month.june')}</MenuItem>
              <MenuItem value={7}>{t('global.month.july')}</MenuItem>
              <MenuItem value={8}>{t('global.month.august')}</MenuItem>
              <MenuItem value={9}>{t('global.month.september')}</MenuItem>
              <MenuItem value={10}>{t('global.month.october')}</MenuItem>
              <MenuItem value={11}>{t('global.month.november')}</MenuItem>
              <MenuItem value={12}>{t('global.month.december')}</MenuItem>
            </Select>
          </FormControl>
          {!isSales && (
            <FormControl size="small" sx={{ minWidth: 200 }} fullWidth>
              <InputLabel id="sales-select-label">{t('user.role.sales')}</InputLabel>
              <Select
                labelId="sales-select-label"
                id="sales-select"
                value={salesId}
                label={t('user.role.sales')}
                onChange={evt => setSalesId(evt.target.value as number | 'all')}>
                <MenuItem value="all">{t('user.all_sales')}</MenuItem>
                {sales.map(sale => (
                  <MenuItem key={sale.id} value={sale.id}>
                    {getFullName(sale)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {isManagerOrDirector && (
            <FormControl size="small" sx={{ minWidth: 200 }} fullWidth>
              <InputLabel id="sales-coordinator-select-label">{t('user.role.sales_coordinator')}</InputLabel>
              <Select
                labelId="sales-coordinator-select-label"
                id="sales-coordinator-select"
                value={salesCoordinatorId}
                label={t('user.role.sales_coordinator')}
                onChange={evt => setSalesCoordinatorId(evt.target.value as number | 'all')}>
                <MenuItem value="all">{t('user.all_sales_coordinators')}</MenuItem>
                {salesCoordinators.map(salesCoordinator => (
                  <MenuItem key={salesCoordinator.id} value={salesCoordinator.id}>
                    {getFullName(salesCoordinator)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <FormControl size="small" sx={{ minWidth: 100 }} fullWidth>
            <InputLabel id="state-select-label">{t('global.status')}</InputLabel>
            <Select
              labelId="state-select-label"
              id="state-select"
              value={state ?? ''}
              label={t('global.status')}
              onChange={evt =>
                setState(evt.target.value === 'all' ? 'all' : (evt.target.value as BenchmarkProposalStatus))
              }>
              <MenuItem value="all">{t('global.all_male')}</MenuItem>
              {user?.role === UserRole.SALES ||
                (user?.role === UserRole.SALES_DIRECTOR && (
                  <MenuItem value="to_negociate">{t('benchmark_proposal.status.to_negociate')}</MenuItem>
                ))}
              {user?.role !== UserRole.SALES_COORDINATOR && (
                <MenuItem value="accepted_by_wholesaler">
                  {t('benchmark_proposal.status.accepted_by_wholesaler')}
                </MenuItem>
              )}
              <MenuItem value="accepted_by_sales_manager">
                {t('benchmark_proposal.status.accepted_by_sales_manager')}
              </MenuItem>
              {user?.role !== UserRole.SALES_COORDINATOR && (
                <MenuItem value="to_be_revised">{t('benchmark_proposal.status.to_be_revised')}</MenuItem>
              )}
              <MenuItem value="done">{t('benchmark_proposal.status.done')}</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </Stack>

      <Stack direction={isMobile ? 'column' : 'row'} spacing={2} mb={2}>
        <Paper variant="outlined" sx={{ padding: 2, marginBottom: 2, flexGrow: 1 }}>
          <Typography variant="body2" color="text.secondary">
            {t('dashboard.number_of_reports')}
          </Typography>
          <Typography variant="h6" color="text.primary">
            {localeNumber(reportsCount)}
          </Typography>
        </Paper>

        <Paper variant="outlined" sx={{ padding: 2, marginBottom: 2, flexGrow: 1 }}>
          <Typography variant="body2" color="text.secondary">
            {t('dashboard.number_of_implanted_meters')}
          </Typography>
          <Typography variant="h6" color="text.primary">
            {localeNumber(numberOfImplantedMeters)}m
          </Typography>
        </Paper>
      </Stack>

      <TableContainer component={props => <Paper variant="outlined" {...props} />}>
        <Table
          aria-label="benchmark proposals table"
          sx={{ '& > tbody tr:nth-of-type(odd)': { backgroundColor: '#00000006' } }}>
          <TableHead>
            <TableRow>
              <TableCell>{t('benchmark_proposal.intial_statement')}</TableCell>
              <Hidden smDown>
                <TableCell>{t('benchmark_proposal.date')}</TableCell>
                <TableCell>{t('benchmark_proposal.sales')}</TableCell>
                <TableCell>{t('user.role.sales_coordinator')}</TableCell>
                <TableCell>{t('benchmark_proposal.name')}</TableCell>
                <TableCell>{t('benchmark_proposal.zip')}</TableCell>
                <TableCell>{t('benchmark_proposal.city')}</TableCell>
              </Hidden>
              <TableCell>{t('benchmark_proposal.status')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <React.Fragment key={`${row.type}_${row.data.id}`}>
                {row.type === 'proposal' && <BenchmarkProposalRow proposal={row.data} />}
                {row.type === 'report' && <InterventionReportRow report={row.data} />}
              </React.Fragment>
            ))}

            {rows.length === 0 && !loading && (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  {t('benchmark_proposal.empty_for_filters')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {(pageInfo?.numberOfPages ?? 0) > 1 && (
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Pagination count={pageInfo?.numberOfPages} color="primary" onChange={(_, value) => setPage(value)} />
        </Box>
      )}
    </Card>
  );
}

export default ActivityMonitoringCard;
