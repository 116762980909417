import { useState } from 'react';
import {
  Box,
  Card,
  Divider,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material';
import { BenchmarkStatement } from '../../../../services/benchmark-statement.service';
import useTranslate from '../../../../hooks/useTranslate';
import { ProductMarket } from '../../../../services/product.service';
import ProductPresentation from '../../../../components/product/ProductPresentation';

export interface StatementCardProps {
  statement: BenchmarkStatement;
}

function StatementCard({ statement }: StatementCardProps) {
  const { t, localeDate } = useTranslate();

  const [market, setMarket] = useState<ProductMarket | 'others'>('public_places');

  const items = statement.products.filter(item => {
    if (market === 'others') return !['public_places', 'renovation', 'access'].includes(item.product.mkt1);
    return item.product.mkt1 === market;
  });

  return (
    <Card variant="outlined" component="section">
      <Typography variant="h6" component="h1" mb={2}>
        {t('statement.date', { date: localeDate(statement.createdAt, 'short') })}
      </Typography>
      <Divider />
      <Box sx={{ marginTop: 1, marginBottom: 2 }}>
        <Tabs variant="scrollable" value={market} onChange={(_, value) => setMarket(value)} aria-label="markets">
          <Tab value="public_places" label={t('market.public_places')} wrapped />
          <Tab value="renovation" label={t('market.renovation')} wrapped />
          <Tab value="access" label={t('market.access')} wrapped />
          <Tab value="others" label={t('market.others')} wrapped />
        </Tabs>
        <Divider />
      </Box>

      <TableContainer component={props => <Paper variant="outlined" {...props} />}>
        <Table aria-label="products table" sx={{ '& > tbody tr:nth-of-type(odd)': { backgroundColor: '#00000006' } }}>
          <TableHead>
            <TableRow>
              <TableCell>{t('product.name')}</TableCell>
              <TableCell>{t('global.quantity_short')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map(item => (
              <TableRow key={item.product.ref}>
                <TableCell>
                  <ProductPresentation product={item.product} redirectToDetailsOnClick />
                </TableCell>
                <TableCell>{item.quantity}</TableCell>
              </TableRow>
            ))}
            {items.length === 0 && (
              <TableRow>
                <TableCell colSpan={2} align="center">
                  {t('product.empty')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}

export default StatementCard;
