import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetReportByIdQuery } from '../../../services/intervention-report.service';
import ReportHeader, { ReportTabType } from './components/ReportHeader';
import NotFound from '../../NoFound';
import { Container } from '@mui/material';
import ReportCard from './components/ReportCard';
import DeviationCard from './components/DeviationCard';

function InterventionReport() {
  const { id } = useParams<{ id: string }>();

  const { data: report, isLoading } = useGetReportByIdQuery(id ?? '');

  const [selectedTab, setSelectedTab] = useState<ReportTabType | undefined>(undefined);

  if (!report) {
    if (isLoading) {
      return null;
    }

    return <NotFound />;
  }

  return (
    <>
      <ReportHeader report={report} selectedTab={selectedTab} onTabSelected={tab => setSelectedTab(tab)} />
      <Container sx={{ paddingY: 4 }}>
        {selectedTab === 'report' && <ReportCard report={report} />}
        {selectedTab === 'deviation' && <DeviationCard />}
      </Container>
    </>
  );
}

export default InterventionReport;
