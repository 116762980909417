import { useState } from 'react';
import { Alert, Button, Drawer, DrawerProps, Snackbar, Stack, TextField, Typography } from '@mui/material';
import useTranslate from '../../hooks/useTranslate';
import { useLazyGetProductByRefQuery } from '../../services/product.service';
import { ProductStatement } from '../../store/types';

export interface ManualRefEntryDrawerProps {
  existingStatements: ProductStatement[];
  visible?: boolean;
  onSubmit?: (productStatement: ProductStatement) => void;
  onClose?: DrawerProps['onClose'];
}

function ManualRefEntryDrawer({ existingStatements, visible = false, onSubmit, onClose }: ManualRefEntryDrawerProps) {
  const { t } = useTranslate();

  const [getProduct] = useLazyGetProductByRefQuery();

  const [reference, setReference] = useState<string>('');
  const [referenceError, setReferenceError] = useState<string | null>(null);
  const [quantity, setQuantity] = useState<number | null>(null);
  const [quantityError, setQuantityError] = useState<string | null>(null);

  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);

  function resetDrawer() {
    setReference('');
    setReferenceError(null);
    setQuantity(null);
    setQuantityError(null);
  }

  async function handleSubmit(evt: React.FormEvent<HTMLFormElement>) {
    evt.preventDefault();
    evt.stopPropagation();

    setReferenceError(null);
    setQuantityError(null);

    if (reference.length === 0) {
      setReferenceError(t('statement.error.reference_required'));
      return;
    }

    if (quantity === null || quantity < 0) {
      setQuantityError(t('statement.error.quantity_required'));
      return;
    }

    const response = await getProduct(reference);
    if (!response.data) {
      setReferenceError(t('statement.error.unknown_reference'));
      return;
    }

    const existingItem = existingStatements.find(stmt => stmt.product.ref === response.data!.ref);
    if (existingItem) {
      setReferenceError(t('statement.error.reference_already_added'));
      return;
    } else {
      onSubmit?.({ product: response.data, quantity });
    }

    resetDrawer();
    onClose?.(evt, 'backdropClick');
    setSnackbarMessage(t('statement.create.product_added'));
  }

  return (
    <>
      <Drawer
        anchor="bottom"
        open={visible}
        onClose={evt => {
          resetDrawer();
          onClose?.(evt, 'backdropClick');
        }}>
        <form onSubmit={handleSubmit}>
          <Stack direction="column" spacing={2} p={2}>
            <Typography variant="body1" component="div" p={0}>
              {t('statement.create.manual_ref_entry')}
            </Typography>
            <TextField
              id="reference"
              label={'Référence produit'}
              variant="outlined"
              value={reference}
              onChange={evt => setReference(evt.target.value)}
              error={!!referenceError}
              helperText={referenceError}
              required
              fullWidth
            />
            <TextField
              type="number"
              id="quantity"
              label={t('statement.create.form.current_quantity_label')}
              variant="outlined"
              value={quantity ?? ''}
              onChange={evt => setQuantity(evt.target.value.length ? Number(evt.target.value) : null)}
              error={!!quantityError}
              helperText={quantityError}
              required
              fullWidth
            />
            <Button type="submit" variant="contained" fullWidth>
              {t('global.add')}
            </Button>
          </Stack>
        </form>
      </Drawer>

      <Snackbar
        open={!!snackbarMessage}
        autoHideDuration={1000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => {
          setSnackbarMessage(null);
        }}>
        <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default ManualRefEntryDrawer;
