import { Card, Divider, Typography } from '@mui/material';
import useTranslate from '../../../../hooks/useTranslate';

function DeviationCard() {
  const { t } = useTranslate();

  return (
    <Card variant="outlined" component="section">
      <Typography variant="h6" component="h1" mb={2}>
        {t('report.deviation_from_proposal')}
      </Typography>
      <Divider />
      <Typography variant="body1" mt={2}>
        {t('report.no_associated_proposal')}
      </Typography>
    </Card>
  );
}

export default DeviationCard;
