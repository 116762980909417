import { FormLabel, Paper, Stack, TextField } from '@mui/material';
import useTranslate from '../../../../../hooks/useTranslate';
import PhotoInput from '../../../../../components/ui/form/PhotoInput';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { setCreateReportFormState } from '../../../../../store/slices/create-report-form.slice';

function CreateReportStep5() {
  const { t } = useTranslate();

  const dispatch = useAppDispatch();
  const { photoKeys, comments } = useAppSelector(state => state.createReportForm);

  return (
    <>
      <Paper elevation={1} sx={{ mb: 2 }}>
        <Stack direction="column" spacing={2} p={2}>
          <FormLabel>{t('global.photos')}</FormLabel>
          <PhotoInput
            entity="report"
            label={t('statement.create.form.add_photo')}
            infoMessage={t('statement.create.form.photos_label')}
            photoKeys={photoKeys}
            onPhotosUploaded={keys => {
              dispatch(setCreateReportFormState({ photoKeys: [...photoKeys, ...keys] }));
            }}
            onRemovePhoto={key => {
              dispatch(setCreateReportFormState({ photoKeys: photoKeys.filter(k => k !== key) }));
            }}
          />
        </Stack>
      </Paper>
      <Paper elevation={1} sx={{ p: 2 }}>
        <TextField
          label={t('statement.create.comments_label')}
          variant="outlined"
          value={comments}
          onChange={evt => dispatch(setCreateReportFormState({ comments: evt.target.value }))}
          minRows={6}
          multiline
          fullWidth
        />
      </Paper>
    </>
  );
}

export default CreateReportStep5;
