import React, { useEffect } from 'react';

/** A hook to handle scroll related features of an HTML element. */
type UseScrollableHook = (
  /** The reference of the HTML element, or `"window"` for reference to the global `window` object. */
  ref: React.RefObject<HTMLElement> | 'window',
  /** The optional options of the hook. */
  options?: {
    /** The callback to call when the bottom of the scrollable area is reached. */
    onBottomReached?: () => void;
  }
) => void;

/** See {@link UseScrollableHook}. */
const useScrollable: UseScrollableHook = (ref, options) => {
  useEffect(() => {
    let isScrolledToBottom: () => boolean;
    if (ref === 'window') {
      isScrolledToBottom = () => {
        const { innerHeight, scrollY } = window;
        const { scrollHeight } = document.body;
        return innerHeight + scrollY >= scrollHeight;
      }
    }
    else {
      isScrolledToBottom = () => {
        if (!ref.current) return false;
        return ref.current.scrollHeight - ref.current.scrollTop === ref.current.clientHeight;
      };
    }

    const onScroll = () => {
      if (isScrolledToBottom()) {
        options?.onBottomReached?.();
      }
    };

    const element = ref === 'window' ? window : ref.current;
    element?.addEventListener('scroll', onScroll);
    return () => {
      element?.removeEventListener('scroll', onScroll);
    };
  }, [ref, options]);
};

export default useScrollable;