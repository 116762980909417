import Navbar from './Navbar';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import themeConstants from '../../theme/constants';

/** The main layout for the app. */
function Layout() {
  return (
    <>
      <Navbar />
      <Box component="main" paddingTop={`${themeConstants.headerHeight}px`}>
        <Outlet />
      </Box>
    </>
  );
}

export default Layout;
