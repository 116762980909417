import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Config from '../config';
import { prepareHeaders } from '../helpers/services';
import { Product } from './product.service';

export interface LayoutBenchmarkProduct {
  product: Product;
  minimumQuantity: number;

}

export interface LayoutBenchmarkSummary {
  id: number;
  name: string;
  network: {
    id: number;
    label: string;
  };
  size: {
    id: number;
    label: string;
  };
  updatedAt: string;
  createdAt: string;
}

export interface LayoutBenchmark {
  id: number;
  name: string;
  network: {
    id: number;
    label: string;
  };
  size: {
    id: number;
    label: string;
  };
  products: LayoutBenchmarkProduct[];
  updatedAt: string;
  createdAt: string;
}

export const layoutBenchmarkApi = createApi({
  reducerPath: 'layoutBenchmarkApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${Config.apiBaseUrl}/layout-benchmark`, prepareHeaders }),
  endpoints: (builder) => ({
    getAllLayoutBenchmarks: builder.query<LayoutBenchmarkSummary[], void>({
      query: () => '',
    }),
  }),
})

export const { useGetAllLayoutBenchmarksQuery } = layoutBenchmarkApi;