import { Box, Stack, Typography } from '@mui/material';
import { Product } from '../../services/product.service';
import ProductAvatar from './ProductAvatar';
import useTranslate from '../../hooks/useTranslate';
import { getProductDetailsUrl } from '../../helpers/product';

export interface ProductPresentationProps {
  product: Product;
  redirectToDetailsOnClick?: boolean;
}

function ProductPresentation({ product, redirectToDetailsOnClick = false }: ProductPresentationProps) {
  const { t } = useTranslate();

  return (
    <a
      style={{ textDecoration: 'none', color: 'inherit', cursor: redirectToDetailsOnClick ? 'pointer' : 'default' }}
      href={redirectToDetailsOnClick ? getProductDetailsUrl(product) : 'javascript:void(0)'}
      target={redirectToDetailsOnClick ? `_blank` : undefined}>
      <Stack direction="row" alignItems="center" spacing={2}>
        <ProductAvatar product={product} />
        <Box>
          <Typography variant="body2" component="div" p={0}>
            {product.title}
          </Typography>
          <Typography variant="caption" component="div" p={0} color="text.secondary">
            {t('global.ref')} {product.ref}
          </Typography>
        </Box>
      </Stack>
    </a>
  );
}

export default ProductPresentation;
