import { useState } from 'react';
import useTranslate from '../../../../../hooks/useTranslate';
import { Box, Tab, Tabs } from '@mui/material';
import CreateReportStep1ProposalTab from './CreateReportStep1ProposalTab';
import CreateReportStep1WholesalerTab from './CreateReportStep1WholesalerTab';

function CreateReportStep1() {
  const { t } = useTranslate();

  const [selectedTab, setSelectedTab] = useState<'proposals' | 'wholesalers'>('proposals');

  return (
    <Box>
      <Tabs value={selectedTab} onChange={(_, value) => setSelectedTab(value)} variant="scrollable">
        <Tab label={t('report.pending_reports')} value="proposals" />
        <Tab label={t('wholesaler.select')} value="wholesalers" />
      </Tabs>

      <Box marginTop={2}>
        <Box display={selectedTab === 'proposals' ? 'initial' : 'none'}>
          <CreateReportStep1ProposalTab />
        </Box>
        <Box display={selectedTab === 'wholesalers' ? 'initial' : 'none'}>
          <CreateReportStep1WholesalerTab />
        </Box>
      </Box>
    </Box>
  );
}

export default CreateReportStep1;
