import { useEffect, useState } from "react";
import { useGetWholesalerBenchmarkProposalsQuery } from "../../../../services/wholesaler.service";
import { InterventionReportSummaryWithSales, useGetAllOrphansReportsQuery } from "../../../../services/intervention-report.service";
import { BenchmarkProposalSummary } from "../../../../services/benchmark-proposal.service";
import { getMinAndMaxDate } from "../../../../helpers/benchmark-proposal";

export type WholesalerProposalRow = {
  type: 'proposal';
  data: BenchmarkProposalSummary;
} | {
  type: 'report';
  data: InterventionReportSummaryWithSales;
};


function useWholesalerProposals(wholesalerId: string) {
  const [page, setPage] = useState<number>(1);
  const [rows, setRows] = useState<WholesalerProposalRow[]>([]);

  const { data: benchmarkProposals, isFetching: areBenchmarkProposalsLoading } =
    useGetWholesalerBenchmarkProposalsQuery({
      id: wholesalerId,
      page,
    });

  const { data: orphanReports, isFetching: areOrphanReportsLoading } = useGetAllOrphansReportsQuery({
    wholesalerId,
  });

  useEffect(() => {
    const newRows: WholesalerProposalRow[] = [];
    if (benchmarkProposals) {
      newRows.push(...benchmarkProposals.edges.map(proposal => ({ type: 'proposal', data: proposal }) as WholesalerProposalRow));
    }
    if (orphanReports) {
      const { minDate, maxDate } = getMinAndMaxDate(benchmarkProposals?.edges ?? []);
      if (benchmarkProposals?.pageInfo?.startCursor === 0) {
        maxDate.setFullYear(maxDate.getFullYear() + 50);
      }
      if (benchmarkProposals?.pageInfo?.hasNextPage === false) {
        minDate.setFullYear(minDate.getFullYear() - 50);
      }

      const relevantOrphanReports = orphanReports.filter(report => {
        return new Date(report.createdAt) >= minDate && new Date(report.createdAt) <= maxDate;
      });

      newRows.push(...relevantOrphanReports.map(report => ({ type: 'report', data: report }) as WholesalerProposalRow));
    }

    newRows.sort((a, b) => {
      const dateA = new Date(a.data.createdAt);
      const dateB = new Date(b.data.createdAt);
      return dateB.getTime() - dateA.getTime();
    });

    setRows(newRows);
  }, [benchmarkProposals, orphanReports]);

  return {
    page,
    rows,
    pageInfo: benchmarkProposals?.pageInfo,
    loading: areBenchmarkProposalsLoading || areOrphanReportsLoading,
    setPage,
  }
}

export default useWholesalerProposals;
