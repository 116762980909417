import { Box, Card, Chip, Radio, Stack, Typography } from '@mui/material';
import { Wholesaler } from '../../services/wholesaler.service';
import { getFullAddress } from '../../helpers/wholesaler';
import { BenchmarkProposalStatus } from '../../services/benchmark-proposal.service';
import { getStatusColor } from '../../helpers/benchmark-proposal';
import useTranslate from '../../hooks/useTranslate';

export interface WholesalerCardProps {
  wholesaler: Wholesaler;
  proposalState?: BenchmarkProposalStatus;
  selected?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

function WholesalerCard({ wholesaler, proposalState, selected, onClick }: WholesalerCardProps) {
  const { t } = useTranslate();

  return (
    <Card variant="outlined" onClick={onClick}>
      <Stack direction="row" spacing={2} mb={1} alignItems="center">
        <Radio style={{ padding: 0 }} checked={selected} />
        <Typography variant="body1" component="div">
          {wholesaler.name}
        </Typography>
      </Stack>
      {proposalState && (
        <Box mb={1}>
          <Chip
            label={t(`benchmark_proposal.status.${proposalState}`)}
            size="small"
            color={getStatusColor(proposalState)}
          />
        </Box>
      )}
      <Typography variant="caption" component="small">
        {getFullAddress(wholesaler)}
      </Typography>
    </Card>
  );
}

export default WholesalerCard;
