import { AppBar, Stack, Toolbar } from '@mui/material';

export interface CreateReportFormBottomBarProps {
  actionsElement?: JSX.Element;
}

function CreateReportFormBottomBar({ actionsElement }: CreateReportFormBottomBarProps) {
  return (
    <AppBar position="fixed" color="transparent" sx={{ top: 'auto', bottom: 0, backgroundColor: 'white' }}>
      <Toolbar>
        <Stack direction="row" spacing={2} justifyContent="space-between" width="100%">
          {actionsElement}
        </Stack>
      </Toolbar>
    </AppBar>
  );
}

export default CreateReportFormBottomBar;
