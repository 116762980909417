import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Config from '../config';
import { prepareHeaders } from '../helpers/services';

/** User roles. */
export enum UserRole {
  /** A salesman. */
  SALES = "sales",
  /** A sales coordinator. */
  SALES_COORDINATOR = "sales_coordinator",
  /** A sales manager. */
  SALES_MANAGER = "sales_manager",
  /** A sales director. */
  SALES_DIRECTOR = "sales_director",
}

/** A user of the app. */
export interface UserSummary {
  /** The user's id. */
  id: number;
  /** The user's code. */
  code: string;
  /** The user's email. */
  email: string;
  /** The user's first name. */
  firstName: string;
  /** The user's last name. */
  lastName: string;
  /** The user's role. */
  role: UserRole;
}

/** A relation between two users. */
export interface UserRelation {
  /** The manager in the relation. */
  manager: UserSummary;
  /** The subordinate in the relation. */
  subordinate: UserSummary;
}

/** A user of the app with full data. */
export interface User extends UserSummary {
  /** The user managers. */
  managedBy: UserRelation[];
  /** The users that are managed by the user. */
  manages: UserRelation[];
}

/** An API for authentication. */
export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${Config.apiBaseUrl}/user`, prepareHeaders }),
  endpoints: (builder) => ({
    /**
     * Get the current user subordinates.
     */
    getMySubordinates: builder.query<User[], void>({
      query: () => '/subordinates',
    }),
  }),
})

export const { useGetMySubordinatesQuery } = userApi;