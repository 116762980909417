import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Config from '../config';
import { prepareHeaders } from '../helpers/services';

/** A set of JSON Web Tokens to handle authentication. */
export interface JwtSet {
  /** The access token of the user. */
  access_token: string;
  /** the refresh token to use to renew the access token. */
  refresh_token: string;
}

/** An API for authentication. */
export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${Config.apiBaseUrl}/auth`, prepareHeaders }),
  endpoints: (builder) => ({
    /**
     * Sign in a user.
     * @param email The user's email.
     * @param password The user's password.
     * @returns A set of JSON Web Tokens to handle authentication.
     */
    signIn: builder.mutation<JwtSet, { email: string, password: string }>({
      query: (params) => ({
        url: '/signin',
        method: 'POST',
        body: params,
      }),
    }),

    /**
     * Refresh the access token. The refresh token is automatically sent in the request.
     * @returns A set of JSON Web Tokens to handle authentication.
     */
    refreshToken: builder.mutation<JwtSet, void>({
      query: () => ({
        url: '/refresh-token',
        method: 'POST',
      }),
    }),

    /**
     * Reset a user's password.
     * @param password The user's new password.
     * @param passwordResetToken The password reset token.
     * @returns A set of JSON Web Tokens to handle authentication.
     */
    resetPassword: builder.mutation<JwtSet, { password: string, passwordResetToken: string }>({
      query: (params) => ({
        url: '/reset-password',
        method: 'POST',
        body: params,
      }),
    }),
  }),
})

export const { useSignInMutation, useRefreshTokenMutation, useResetPasswordMutation } = authApi;