import { useEffect, useState } from 'react';
import { Button, Chip, Drawer, DrawerProps, Stack, TextField } from '@mui/material';
import useTranslate from '../../hooks/useTranslate';
import { Product } from '../../services/product.service';
import ProductPresentation from '../../components/product/ProductPresentation';

export interface ScanResultDrawerProps {
  product?: Product;
  initialQuantity?: number | null;
  productAlreadyAdded?: boolean;
  visible?: boolean;
  autoFocus?: boolean;
  onSubmit?: (quantity: number) => void;
  onRemove?: () => void;
  onClose?: DrawerProps['onClose'];
}

function ScanResultDrawer({
  product,
  initialQuantity = null,
  productAlreadyAdded = false,
  visible = false,
  autoFocus = false,
  onSubmit,
  onRemove,
  onClose,
}: ScanResultDrawerProps) {
  const { t } = useTranslate();

  const [quantity, setQuantity] = useState<number | null>(initialQuantity);
  const [quantityError, setQuantityError] = useState<string | null>(null);

  function handleSubmit(evt: React.FormEvent<HTMLFormElement>) {
    evt.preventDefault();
    evt.stopPropagation();

    setQuantityError(null);
    if (quantity === null || quantity < 0) {
      setQuantityError(t('statement.error.quantity_required'));
      return;
    }

    onSubmit?.(quantity);
    setQuantity(null);
  }

  useEffect(() => {
    setQuantity(initialQuantity);
  }, [initialQuantity, product]);

  if (!product) return null;

  return (
    <Drawer anchor="bottom" open={visible} onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <Stack direction="column" spacing={2} p={2}>
          <ProductPresentation product={product} />
          {productAlreadyAdded && <Chip label={t('statement.create.product_already_added')} color="warning" />}
          <TextField
            type="number"
            id="quantity"
            label={t('statement.create.form.current_quantity_label')}
            variant="outlined"
            value={quantity ?? ''}
            onChange={evt => setQuantity(evt.target.value.length ? Number(evt.target.value) : null)}
            error={!!quantityError}
            helperText={quantityError}
            autoFocus={autoFocus}
            onFocus={evt => {
              if (autoFocus) evt.target.select();
            }}
            required
            fullWidth
          />
          <Stack direction="column" spacing={1}>
            <Button type="submit" variant="contained" fullWidth>
              {productAlreadyAdded ? t('statement.create.form.update_quantity') : t('global.add')}
            </Button>
            {productAlreadyAdded && (
              <Button type="button" variant="outlined" color="error" fullWidth onClick={onRemove}>
                {t('global.remove')}
              </Button>
            )}
          </Stack>
        </Stack>
      </form>
    </Drawer>
  );
}

export default ScanResultDrawer;
