import { useEffect } from 'react';
import { useRefreshTokenMutation } from '../../services/auth.service';
import { store, useAppDispatch } from '../../store';
import { signIn } from '../../store/slices/auth.slice';
import { getUserFromToken } from '../../helpers/auth';

/** Props for the {@link AuthInitializer} component. */
interface AuthInitializerProps {
  onInitialized?: () => void;
}

/**
 * A component to initialize authentication.
 * It will sign in the user from the refresh token stored in the persisted Redux store.
 */
function AuthInitializer({ onInitialized }: AuthInitializerProps) {
  const dispatch = useAppDispatch();
  const [renewToken] = useRefreshTokenMutation();

  // On component mount, we try to sign in the user from the local storage.
  useEffect(() => {
    const { auth } = store.getState();

    let mustRenewToken = false;
    if (auth.accessToken) {
      const user = getUserFromToken(auth.accessToken);
      if (!user && auth.refreshToken) {
        mustRenewToken = true;
      }
    } else if (auth.refreshToken) {
      mustRenewToken = true;
    }

    if (!mustRenewToken) {
      onInitialized?.();
      return;
    }

    renewToken()
      .unwrap()
      .then(({ access_token, refresh_token }) => {
        dispatch(signIn({ accessToken: access_token, refreshToken: refresh_token }));
      })
      .finally(onInitialized);
  }, []);

  return null;
}

export default AuthInitializer;
