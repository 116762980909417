import { useEffect, useState } from "react";
import { addStatusToProductAdjustments, compareProductAdjustmentsByStatus } from "../../../../helpers/benchmark-proposal";
import { getProductDetailsUrl, isProductInMainMarkets } from "../../../../helpers/product";
import useTranslate from "../../../../hooks/useTranslate";
import { BenchmarkProposal } from "../../../../services/benchmark-proposal.service";
import { ProductAdjustmentWithStatus } from "../components/ProposalCard";
import { Data as CsvData, Headers as CsvHeaders } from "react-csv/lib/core";

function useProposalExport(proposal: BenchmarkProposal) {
  const { t } = useTranslate();

  const [csvHeaders, setCsvHeaders] = useState<CsvHeaders>([]);
  const [csvData, setCsvData] = useState<CsvData>([]);

  function getCsvDataFromAdjustment(adjustment: ProductAdjustmentWithStatus) {
    const statementProduct = proposal.benchmarkStatement.products.find(
      item => item.product.ref === adjustment.product.ref,
    );

    return {
      market: t(`market.${adjustment.product.mkt1}`),
      productRef: adjustment.product.ref,
      productTitle: adjustment.product.title.replace(/"/g, '""'),
      detailsUrl: getProductDetailsUrl(adjustment.product),
      status: t(`product_adjustment.status.${adjustment.status}`).toUpperCase(),
      currentQuantity: (statementProduct?.quantity ?? 0).toString(),
      adjustment: adjustment.quantityAdjustment.toString(),
    };
  }

  function populateCsvData() {
    const headers = [
      { label: t('market.title'), key: 'market' },
      { label: t('product_adjustment.product_ref'), key: 'productRef' },
      { label: t('product_adjustment.product_name'), key: 'productTitle' },
      { label: t('product.details_url'), key: 'detailsUrl' },
      { label: t('global.status'), key: 'status' },
      { label: t('product_adjustment.current_quantity_short'), key: 'currentQuantity' },
      { label: t('product_adjustment.adjustment'), key: 'adjustment' },
    ];

    const adjustmentsWithStatus = addStatusToProductAdjustments(proposal);
    adjustmentsWithStatus.sort(compareProductAdjustmentsByStatus);

    const publicPlacesAdjustments = adjustmentsWithStatus.filter(
      adjustment => adjustment.product.mkt1 === 'public_places',
    );
    const renovationAdjustments = adjustmentsWithStatus.filter(adjustment => adjustment.product.mkt1 === 'renovation');
    const accessAdjustments = adjustmentsWithStatus.filter(adjustment => adjustment.product.mkt1 === 'access');
    const otherAdjustments = adjustmentsWithStatus.filter(adjustment => !isProductInMainMarkets(adjustment.product));

    const tables = [
      { title: t('market.public_places'), adjustments: publicPlacesAdjustments },
      { title: t('market.renovation'), adjustments: renovationAdjustments },
      { title: t('market.access'), adjustments: accessAdjustments },
      { title: t('market.others'), adjustments: otherAdjustments },
    ];

    const data = [];
    for (const table of tables) {
      if (table.adjustments.length > 0) {
        for (const adjustment of table.adjustments) {
          data.push(getCsvDataFromAdjustment(adjustment));;
        }
      }
    }

    setCsvHeaders(headers);
    setCsvData(data);
  }

  useEffect(() => {
    populateCsvData();
  }, [proposal]);

  return {
    csvHeaders,
    csvData,
  };
}

export default useProposalExport;