import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { MIN_CREATE_REPORT_FORM_STEP } from '../../pages/intervention-report/create/hooks/useCreateReportForm';
import { ProductStatement } from '../types';
/**
 * Redux state for the create report form.  
 * A report is made for a wholesaler to track an intervention made in their store.
 */
export interface CreateReportFormState {
  /** The current step of the form. */
  step: number;
  /** The last time the form was updated. */
  updatedAt: string;

  /* --- Step 1 --- */
  /** The wholesaler id. */
  wholesalerId: string | null;
  /** The benchmark proposal id. */
  benchmarkProposalId: number | null;

  /* --- Step 2 --- */
  /** The number of linear meters for the public places market section. */
  publicPlacesMeters: number | null;
  /** The number of linear meters for the renovation market section. */
  renovationMeters: number | null;
  /** The number of linear meters for the new construction market section. */
  accessMeters: number | null;

  /* --- Step 3 --- */
  /** The products statements available from the wholesaler. */
  productStatements: ProductStatement[];

  /* --- Step 4 --- */
  // Nothing needed for this step.

  /* --- Step 5 --- */
  /** The key of the uploaded photos for this report. */
  photoKeys: string[];
  /** Comments from the coordinator for the report. */
  comments: string;
}

/** The initial state for the create report form slice. */
const initialState: CreateReportFormState = {
  step: MIN_CREATE_REPORT_FORM_STEP,
  updatedAt: new Date().toISOString(),
  wholesalerId: null,
  benchmarkProposalId: null,
  publicPlacesMeters: null,
  renovationMeters: null,
  accessMeters: null,
  productStatements: [],
  photoKeys: [],
  comments: '',
}

export const createReportFormSlice = createSlice({
  name: 'createReportForm',
  initialState,
  reducers: {
    /** Set the create report form state. */
    setCreateReportFormState(state, action: PayloadAction<Partial<CreateReportFormState>>) {
      return {
        ...state,
        ...action.payload,
        updatedAt: new Date().toISOString(),
      }
    },

    /** Reset the create report form state. */
    resetCreateReportFormState() {
      return initialState;
    }
  }
})

export const { setCreateReportFormState, resetCreateReportFormState } = createReportFormSlice.actions;

export default createReportFormSlice.reducer;