import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fab,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Pagination,
  Paper,
  Radio,
  RadioGroup,
  Snackbar,
  Stack,
  Tab,
  Tabs,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import FilePdf from '@mui/icons-material/PictureAsPdf';
import CsvIcon from '@mui/icons-material/TableView';
import {
  BenchmarkProposal,
  BenchmarkProposalProductAdjustment,
  BenchmarkProposalStatus,
} from '../../../../services/benchmark-proposal.service';
import { ProductAdjustmentStatus, getProposalRecapTitle } from '../../../../helpers/benchmark-proposal';
import useProductAdjustments from '../hooks/useProductAdjustments';
import useTranslate from '../../../../hooks/useTranslate';
import ProposalAdjustmentsTable from './ProposalAdjustmentsTable';
import SearchInput from '../../../../components/ui/form/SearchInput';
import AddAdjustmentDialog from './AddAdjustmentDialog';
import usePriceOffer from '../hooks/usePriceOffer';
import { useAppSelector } from '../../../../store';
import { UserRole } from '../../../../services/user.service';
import { pdf } from '@react-pdf/renderer';
import BenchmarkProposalRecap from '../../../../components/benchmark-proposal/pdf/BenchmarkProposalRecap';
import useProposalExport from '../hooks/useProposalExport';
import { CSVLink } from 'react-csv';

/** A product adjustment with a status. */
export interface ProductAdjustmentWithStatus extends BenchmarkProposalProductAdjustment {
  /** The status of the product adjustment. */
  status: ProductAdjustmentStatus;
}

export interface ProposalCardProps {
  /** The benchmark proposal. */
  proposal: BenchmarkProposal;
  /** A callback for when the proposal have been updated and must be fetch again. */
  onRefreshProposalRequest?: () => void;
  /** A callback for when the proposal is submitted. */
  onSubmitProposal?: (salesComments?: string) => void;
  /** A callback for when the sales manager ask for a review. */
  onAskReview?: (salesManagerComments?: string) => void;
  /** A callback for when the proposal is accepted. */
  onAccept?: () => void;
}

function ProposalCard({
  proposal,
  onRefreshProposalRequest,
  onSubmitProposal,
  onAskReview,
  onAccept,
}: ProposalCardProps) {
  const { user } = useAppSelector(state => state.auth);

  const { t } = useTranslate();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const isArchived = proposal.wholesaler.deletedAt !== null;

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showAddAdjustmentDialog, setShowAddAdjustmentDialog] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [showSnackbar, setShowSnackbar] = useState(false);

  const [salesComments, setSalesComments] = useState<string>(proposal.salesComments ?? '');
  const [salesManagerComments, setSalesManagerComments] = useState<string>(proposal.salesManagerComments ?? '');

  const [disableForm, setDisableForm] = useState<boolean>(
    proposal.state === BenchmarkProposalStatus.ACCEPTED_BY_WHOLESALER ||
      proposal.state === BenchmarkProposalStatus.ACCEPTED_BY_SALES_MANAGER ||
      proposal.state === BenchmarkProposalStatus.DONE ||
      isArchived,
  );
  const [canManagerAskForReview, setCanManagerAskForReview] = useState<boolean>(
    proposal.state === BenchmarkProposalStatus.ACCEPTED_BY_WHOLESALER ||
      proposal.state === BenchmarkProposalStatus.TO_BE_REVISED,
  );

  const {
    searchQuery,
    handleSearchQueryChange,
    market,
    setMarket,
    status,
    setStatus,
    productAdjustments,
    pageCount,
    page,
    setPage,
  } = useProductAdjustments(proposal);

  const { csvData, csvHeaders } = useProposalExport(proposal);

  const { getMailToHref } = usePriceOffer();

  function handleSubmit(evt: React.FormEvent<HTMLFormElement>) {
    evt.preventDefault();
    evt.stopPropagation();
    setShowConfirmDialog(true);
  }

  function handleSubmitConfirm(evt: React.FormEvent<HTMLFormElement>) {
    evt.preventDefault();
    evt.stopPropagation();
    setShowConfirmDialog(false);
    onSubmitProposal?.(salesComments);
  }

  function handleAcceptProposal(evt: React.FormEvent<HTMLFormElement>) {
    evt.preventDefault();
    evt.stopPropagation();
    onAccept?.();
  }

  function handleAskReview(evt: React.FormEvent<HTMLFormElement>) {
    evt.preventDefault();
    evt.stopPropagation();
    onAskReview?.(salesManagerComments);
  }

  async function downloadRecapAsPdf() {
    const blob = await pdf(<BenchmarkProposalRecap proposal={proposal} />).toBlob();
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `${getProposalRecapTitle(proposal)}.pdf`;
    link.click();
    window.URL.revokeObjectURL(link.href);
  }

  const isProposalAcceptedOrDone =
    proposal.state === BenchmarkProposalStatus.ACCEPTED_BY_SALES_MANAGER ||
    proposal.state === BenchmarkProposalStatus.DONE;

  return (
    <Card variant="outlined" component="section">
      <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6" component="h1">
          {isProposalAcceptedOrDone ? t('benchmark_proposal.title_accepted') : t('benchmark_proposal.title')}
        </Typography>
        <Box>
          <Stack direction="row" spacing={1}>
            <CSVLink
              headers={csvHeaders}
              data={csvData}
              separator={';'}
              filename={`${getProposalRecapTitle(proposal)}.csv`}>
              <Fab color="primary" aria-label="add" size="small">
                <CsvIcon fontSize="medium" />
              </Fab>
            </CSVLink>
            <Fab color="primary" aria-label="add" size="small" onClick={downloadRecapAsPdf}>
              <FilePdf fontSize="medium" />
            </Fab>
          </Stack>
        </Box>
      </Stack>
      <Divider />
      <Box sx={{ mt: 1, mb: 2 }}>
        <Tabs variant="scrollable" value={market} onChange={(_, value) => setMarket(value)} aria-label="markets">
          <Tab value="all" label={t('global.all_male')} wrapped />
          <Tab value="public_places" label={t('market.public_places')} wrapped />
          <Tab value="renovation" label={t('market.renovation')} wrapped />
          <Tab value="access" label={t('market.access')} wrapped />
        </Tabs>
        <Divider />
      </Box>

      <Paper variant="outlined" sx={{ padding: 2 }}>
        <Box mb={2}>
          <Tabs variant="scrollable" value={status} onChange={(_, value) => setStatus(value)} aria-label="status">
            <Tab value="all" label={t('global.all_male')} wrapped />
            <Tab value="keep" label={t('product_adjustment.status.keep')} wrapped />
            <Tab value="restock" label={t('product_adjustment.status.restock')} wrapped />
            <Tab value="missing" label={t('product_adjustment.status.missing')} wrapped />
            <Tab value="remove" label={t('product_adjustment.status.remove')} wrapped />
          </Tabs>
          <Divider />
        </Box>

        <Stack direction={isMobile ? 'column' : 'row'} my={2} spacing={2} justifyContent="space-between">
          <form>
            <SearchInput
              label={t('product.search_ref')}
              value={searchQuery}
              onChange={evt => handleSearchQueryChange(evt.target.value)}
              size="small"
              sx={{ minWidth: isMobile ? undefined : 400 }}
              fullWidth={isMobile}
            />
          </form>
          {user?.role === UserRole.SALES && (
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<AddIcon />}
              onClick={() => setShowAddAdjustmentDialog(true)}
              disabled={disableForm}>
              {t('product_adjustment.add')}
            </Button>
          )}
        </Stack>

        <Paper variant="outlined">
          <ProposalAdjustmentsTable
            proposal={proposal}
            adjustments={productAdjustments}
            isFormDisabled={disableForm}
            onAdjustmentDeleted={() => {
              setSnackbarMessage(t('product_adjustment.deleted'));
              setShowSnackbar(true);
              onRefreshProposalRequest?.();
            }}
            onAdjustmentUpdated={() => {
              setSnackbarMessage(t('product_adjustment.updated'));
              setShowSnackbar(true);
              onRefreshProposalRequest?.();
            }}
          />
          {pageCount > 1 && (
            <Box m={2} display="flex" justifyContent="flex-end">
              <Pagination count={pageCount} color="primary" page={page} onChange={(_, value) => setPage(value)} />
            </Box>
          )}
        </Paper>
      </Paper>

      <Paper variant="outlined" sx={{ padding: 2, marginTop: 2 }}>
        <FormGroup>
          <FormLabel component="legend" sx={{ marginBottom: 2 }}>
            {t('statement.training')}
          </FormLabel>
          <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
            <Paper variant="outlined" sx={{ padding: 2, marginTop: 2, flexGrow: 1 }}>
              <FormControl disabled>
                <FormLabel id="training-required-label">{t('statement.create.form.required_training_label')}</FormLabel>
                <RadioGroup
                  aria-labelledby="training-required-label"
                  value={proposal.benchmarkStatement.trainingRequired ? 'yes' : 'no'}
                  name="training-required"
                  row>
                  <FormControlLabel value="yes" control={<Radio />} label={t('global.yes')} />
                  <FormControlLabel value="no" control={<Radio />} label={t('global.no')} />
                </RadioGroup>
              </FormControl>
            </Paper>
            {proposal.benchmarkStatement.trainingRequired && (
              <Paper variant="outlined" sx={{ padding: 2, marginTop: 2, flexGrow: 1 }}>
                <TextField
                  variant="outlined"
                  label={t('statement.create.form.required_training_comments')}
                  value={proposal.benchmarkStatement.trainingComments}
                  multiline
                  rows={5}
                  fullWidth
                  disabled
                />
              </Paper>
            )}
          </Stack>
        </FormGroup>
      </Paper>

      <Stack direction={isMobile ? 'column' : 'row'} spacing={2} mt={4}>
        {user?.role !== UserRole.SALES_COORDINATOR && (
          <Box flex="1 1 0px">
            <form onSubmit={handleSubmit}>
              <Stack direction="column" spacing={2}>
                <TextField
                  id="sales-comments"
                  label={t('comments.sales')}
                  multiline
                  rows={5}
                  value={salesComments}
                  onChange={evt => setSalesComments(evt.target.value)}
                  disabled={disableForm || user?.role !== UserRole.SALES}
                  fullWidth
                />
                {!isArchived && user?.role === UserRole.SALES && proposal.state !== BenchmarkProposalStatus.DONE && (
                  <Stack direction={isMobile ? 'column-reverse' : 'row'} spacing={2} mt={2}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      onClick={evt => {
                        if (disableForm) {
                          evt.preventDefault();
                          setDisableForm(false);
                        }
                      }}>
                      {disableForm ? t('benchmark_proposal.form.edit') : t('benchmark_proposal.form.submit')}
                    </Button>
                    <Button type="button" variant="outlined" color="primary" href={getMailToHref(proposal)}>
                      {t('benchmark_proposal.form.ask_price_offer')}
                    </Button>
                  </Stack>
                )}
              </Stack>
            </form>
          </Box>
        )}

        <Box flex="1 1 0px">
          <form onSubmit={handleAskReview}>
            <Stack direction="column" spacing={2}>
              <TextField
                id="sales-manager-comments"
                label={t('comments.sales_manager')}
                multiline
                rows={5}
                value={salesManagerComments}
                onChange={evt => setSalesManagerComments(evt.target.value)}
                disabled={!canManagerAskForReview || user?.role !== UserRole.SALES_MANAGER}
                fullWidth
                required={canManagerAskForReview && user?.role === UserRole.SALES_MANAGER}
              />
              {canManagerAskForReview && user?.role === UserRole.SALES_MANAGER && (
                <Button type="submit" variant="outlined" color="error" sx={{ alignSelf: 'flex-start' }}>
                  {t('review.submit')}
                </Button>
              )}
            </Stack>
          </form>
        </Box>
      </Stack>

      {proposal.state === BenchmarkProposalStatus.ACCEPTED_BY_WHOLESALER && user?.role === UserRole.SALES_MANAGER && (
        <form onSubmit={handleAcceptProposal}>
          <Button type="submit" variant="contained" fullWidth sx={{ mt: 2 }}>
            {t('benchmark_proposal.accept')}
          </Button>
        </form>
      )}

      <Dialog
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        aria-labelledby="confirm-submit-title"
        aria-describedby="confirm-submit-description">
        <form onSubmit={handleSubmitConfirm}>
          <DialogTitle id="confirm-submit-title">{t('benchmark_proposal.form.confirm_submit')}</DialogTitle>
          <DialogContent>
            <Typography variant="body1" sx={{ mt: 2 }}>
              {t('benchmark_proposal.form.confirm_submit_description')}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button type="button" variant="outlined" onClick={() => setShowConfirmDialog(false)}>
              {t('global.cancel')}
            </Button>
            <Button type="submit" variant="contained" autoFocus>
              {t('global.confirm')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <AddAdjustmentDialog
        open={showAddAdjustmentDialog}
        proposal={proposal}
        onAdjustmentCreated={() => {
          setShowAddAdjustmentDialog(false);
          setSnackbarMessage(t('product_adjustment.created'));
          setShowSnackbar(true);
          onRefreshProposalRequest?.();
        }}
        onClose={() => setShowAddAdjustmentDialog(false)}
      />

      <Snackbar
        sx={{ display: 'flex', justifyContent: 'center' }}
        open={showSnackbar}
        autoHideDuration={2000}
        onClose={() => setShowSnackbar(false)}
        message={snackbarMessage}
      />
    </Card>
  );
}

export default ProposalCard;
