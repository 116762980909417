import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { MIN_CREATE_STATEMENT_FORM_STEP } from '../../pages/statement/create/hooks/useCreateStatementForm';
import { ProductStatement } from '../types';

/**
 * Redux state for the create statement form.  
 * A statement is made for a wholesaler to track the current state of their store.
 */
export interface CreateStatementFormState {
  /** The current step of the form. */
  step: number;
  /** The last time the form was updated. */
  updatedAt: string;

  /* --- Step 1 --- */
  /** The wholesaler id. */
  wholesalerId: string | null;

  /* --- Step 2 --- */
  /** The wholesaler network id. */
  wholesalerNetworkId: number | null;
  /** The wholesaler size id. */
  wholesalerSizeId: number | null;
  /** The benchmark layout id. */
  layoutBenchmarkId: number | null;
  /** The fullname of the wholesaler contact. */
  contact: string;
  /** The position of the wholesaler contact. */
  contactPosition: string;
  /** The phone number of the wholesaler contact. */
  contactPhone: string;
  /** The email of the wholesaler contact. */
  contactEmail: string;
  /** Whether the wholesaler needs a training. */
  isTrainingRequired: boolean;
  /** The comments about the training if needed. */
  trainingComments: string;

  /* --- Step 3 --- */
  /** The number of linear meters for the public places market section. */
  publicPlacesMeters: number | null;
  /** The number of linear meters for the renovation market section. */
  renovationMeters: number | null;
  /** The number of linear meters for the new construction market section. */
  accessMeters: number | null;
  /** Whether the wholesaler has a tablets available. */
  areTabletsAvailable: boolean;
  /** Whether the wholesaler has perfirated bottoms installed in his sections. */
  arePerforedBottomsInstalled: boolean;
  /** Whether the wholesaler has load bars available. */
  areLoadBarsAvailable: boolean;
  /** The linear height of the Delabie sections. */
  linearHeight: string;
  /** The linear width of the Delabie sections. */
  linearWidth: string;
  /** Whether the photos are currently uploading. */
  arePhotosUploading: boolean;
  /** A list S3 keys of photos of the section. */
  photoKeys: string[];

  /* --- Step 4 --- */
  /** The products statements available from the wholesaler. */
  productStatements: ProductStatement[];

  /* --- Step 5 --- */
  // Nothing needed for this step.

  /* --- Step 6 --- */
  /** Optional comments about the statement. */
  comments: string;
}

/** The initial state for the create statement form slice. */
const initialState: CreateStatementFormState = {
  step: MIN_CREATE_STATEMENT_FORM_STEP,
  updatedAt: new Date().toISOString(),
  wholesalerId: null,
  wholesalerNetworkId: null,
  wholesalerSizeId: null,
  layoutBenchmarkId: null,
  contact: '',
  contactPosition: '',
  contactPhone: '',
  contactEmail: '',
  isTrainingRequired: true,
  trainingComments: '',
  publicPlacesMeters: null,
  renovationMeters: null,
  accessMeters: null,
  areTabletsAvailable: false,
  arePerforedBottomsInstalled: false,
  areLoadBarsAvailable: false,
  linearHeight: '',
  linearWidth: '',
  arePhotosUploading: false,
  photoKeys: [],
  productStatements: [],
  comments: '',
}

export const createStatementFormSlice = createSlice({
  name: 'createStatementForm',
  initialState,
  reducers: {
    /** Set the create statement form state. */
    setCreateStatementFormState(state, action: PayloadAction<Partial<CreateStatementFormState>>) {
      return {
        ...state,
        ...action.payload,
        updatedAt: new Date().toISOString(),
      }
    },

    /** Reset the create statement form state. */
    resetCreateStatementFormState() {
      return initialState;
    }
  }
})

export const { setCreateStatementFormState, resetCreateStatementFormState } = createStatementFormSlice.actions;

export default createStatementFormSlice.reducer;