/**
 * Get the current year.
 * @returns The current year.
 */
export function getCurrentYear(): number {
  return new Date().getFullYear();
}

/**
 * Get the current month.
 * @returns The current month.
 */
export function getCurrentMonth(): number {
  return new Date().getMonth();
}
