import { useMemo, useState } from 'react';
import {
  Box,
  Card,
  Chip,
  Divider,
  FormGroup,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import useTranslate from '../../../../hooks/useTranslate';
import { BenchmarkProposal } from '../../../../services/benchmark-proposal.service';
import { ProductMarket } from '../../../../services/product.service';
import {
  ProductDeviationStatus,
  getProductDeviationStatusColor,
  getProductDeviationsFromProposal,
} from '../../../../helpers/intervention-report';
import ProductPresentation from '../../../../components/product/ProductPresentation';

export interface DeviationCardProps {
  proposal: BenchmarkProposal;
}

function DeviationCard({ proposal }: DeviationCardProps) {
  const { t } = useTranslate();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const [market, setMarket] = useState<ProductMarket>('public_places');
  const [status, setStatus] = useState<ProductDeviationStatus | 'all'>('all');

  const deviations = useMemo(() => getProductDeviationsFromProposal(proposal), [proposal]);
  const deviationsToDisplay = deviations.filter(deviation => {
    if (deviation.product.mkt1 !== market) return false;
    if (status === 'all') return true;
    return deviation.status === status;
  });

  return (
    <Card variant="outlined" component="section">
      <Typography variant="h6" component="h1" mb={2}>
        {t('report.deviation_from_proposal')}
      </Typography>
      <Divider />
      <Box sx={{ mt: 1, mb: 2 }}>
        <Tabs variant="scrollable" value={market} onChange={(_, value) => setMarket(value)} aria-label="markets">
          <Tab value="public_places" label={t('market.public_places')} wrapped />
          <Tab value="renovation" label={t('market.renovation')} wrapped />
          <Tab value="access" label={t('market.access')} wrapped />
        </Tabs>
        <Divider />
      </Box>
      <Paper variant="outlined" sx={{ padding: 2 }}>
        <Box sx={{ mt: 1, mb: 2 }}>
          <Tabs
            variant="scrollable"
            value={status}
            onChange={(_, value) => setStatus(value)}
            aria-label="deviation-status">
            <Tab value="all" label={t('product_deviation.status.all')} wrapped />
            <Tab value="additional" label={t('product_deviation.status.additional')} wrapped />
            <Tab value="missing" label={t('product_deviation.status.missing')} wrapped />
          </Tabs>
          <Divider />
        </Box>

        <TableContainer component={props => <Paper variant="outlined" {...props} />}>
          <Table
            aria-label="product deviations table"
            sx={{ '& > tbody tr:nth-of-type(odd)': { backgroundColor: '#00000006' } }}>
            <TableHead>
              <TableRow>
                <TableCell>{t('product.name')}</TableCell>
                <TableCell>{t('global.status')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {deviationsToDisplay.map(item => (
                <TableRow key={item.product.ref}>
                  <TableCell>
                    <ProductPresentation product={item.product} redirectToDetailsOnClick />
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={t(`product_deviation.status.${item.status}`)}
                      size="small"
                      color={getProductDeviationStatusColor(item.status)}
                    />
                  </TableCell>
                </TableRow>
              ))}
              {deviationsToDisplay.length === 0 && (
                <TableRow>
                  <TableCell colSpan={2} align="center">
                    {t('product_deviation.empty')}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <form>
          <FormGroup>
            <Stack direction={isMobile ? 'column' : 'row'} spacing={2} mt={4}>
              <TextField
                id="sales-comments"
                label={t('comments.sales')}
                multiline
                rows={5}
                value={proposal.salesComments}
                disabled
                fullWidth
              />
              <TextField
                id="sales-manager-comments"
                label={t('comments.sales_manager')}
                multiline
                rows={5}
                value={proposal.salesManagerComments}
                disabled
                fullWidth
              />
            </Stack>
          </FormGroup>
        </form>
      </Paper>
    </Card>
  );
}

export default DeviationCard;
