import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';
import authReducer from './slices/auth.slice';
import createStatementFormReducer from './slices/create-statement-form.slice';
import createReportFormReducer from './slices/create-report-form.slice';
import { setupListeners } from '@reduxjs/toolkit/query';
import { wholesalerNetworkApi } from '../services/wholesaler-network.service';
import { productApi } from '../services/product.service';
import { formSuggestionApi } from '../services/form-suggestion.service';
import { layoutBenchmarkApi } from '../services/layout-benchmark.service';
import { wholesalerApi } from '../services/wholesaler.service';
import { authApi } from '../services/auth.service';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import persistStore from 'redux-persist/es/persistStore';
import { benchmarkStatementApi } from '../services/benchmark-statement.service';
import { benchmarkProposalApi } from '../services/benchmark-proposal.service';
import { interventionReportApi } from '../services/intervention-report.service';
import { userApi } from '../services/user.service';

const reducers = combineReducers({
  auth: authReducer,
  createStatementForm: createStatementFormReducer,
  createReportForm: createReportFormReducer,
  [authApi.reducerPath]: authApi.reducer,
  [wholesalerNetworkApi.reducerPath]: wholesalerNetworkApi.reducer,
  [productApi.reducerPath]: productApi.reducer,
  [formSuggestionApi.reducerPath]: formSuggestionApi.reducer,
  [layoutBenchmarkApi.reducerPath]: layoutBenchmarkApi.reducer,
  [wholesalerApi.reducerPath]: wholesalerApi.reducer,
  [benchmarkStatementApi.reducerPath]: benchmarkStatementApi.reducer,
  [benchmarkProposalApi.reducerPath]: benchmarkProposalApi.reducer,
  [interventionReportApi.reducerPath]: interventionReportApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
});

const persistedReducer = persistReducer({
  key: 'root',
  storage,
  whitelist: ['auth', 'createStatementForm', 'createReportForm'],
}, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ['persist/PERSIST']
    }
  }).concat(
    authApi.middleware,
    wholesalerNetworkApi.middleware,
    productApi.middleware,
    formSuggestionApi.middleware,
    layoutBenchmarkApi.middleware,
    wholesalerApi.middleware,
    benchmarkStatementApi.middleware,
    benchmarkProposalApi.middleware,
    interventionReportApi.middleware,
    userApi.middleware,
  ),
});

export const persistor = persistStore(store);

setupListeners(store.dispatch);

// Types
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Use throughout the app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;