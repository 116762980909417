import ProductionConfig from "./prod";
import StagingConfig from "./staging";
import DevelopmentConfig from "./dev";

/** A configuration object for the project. */
export interface ProjectConfig {
  /** The current environment of the project. */
  env: 'production' | 'staging' | 'development';
  /** The base URL for the backend API. */
  apiBaseUrl: string;
  /** The URL of the S3 bucket where medias are stored. */
  s3MediasBucketUrl: string;
  /** The mail to send price offers to. */
  priceOfferMailTo: string;
}

/**
 * Returns the configuration object for the current environment.
 * @return {ProjectConfig} The configuration object.
 */
const getConfig = (): ProjectConfig => {
  const env = process.env.REACT_APP_ENV ?? process.env.NODE_ENV;
  if (env === "production") {
    return ProductionConfig;
  } else if (env === "staging") {
    return StagingConfig;
  } else {
    return DevelopmentConfig;
  }
}

/** The project's configuration. */
const Config = getConfig();

export default Config;