import { FormLabel, Paper, Stack, TextField } from '@mui/material';
import useTranslate from '../../../../../hooks/useTranslate';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { setCreateReportFormState } from '../../../../../store/slices/create-report-form.slice';

function CreateReportStep2() {
  const { t } = useTranslate();

  const dispatch = useAppDispatch();
  const { publicPlacesMeters, renovationMeters, accessMeters } = useAppSelector(state => state.createReportForm);

  return (
    <Paper elevation={1}>
      <Stack direction="column" spacing={3} p={2}>
        <FormLabel>{t('report.number_of_meters')}</FormLabel>
        <TextField
          type="number"
          id="public-places-meters"
          label={t('market.public_places')}
          variant="outlined"
          placeholder="#m"
          value={publicPlacesMeters ?? ''}
          onChange={evt =>
            dispatch(
              setCreateReportFormState({
                publicPlacesMeters: evt.target.value !== '' ? Number(evt.target.value) : null,
              }),
            )
          }
          required
        />
        <TextField
          type="number"
          id="renovation-meters"
          label={t('market.renovation')}
          variant="outlined"
          placeholder="#m"
          value={renovationMeters ?? ''}
          onChange={evt =>
            dispatch(
              setCreateReportFormState({
                renovationMeters: evt.target.value !== '' ? Number(evt.target.value) : null,
              }),
            )
          }
          required
        />
        <TextField
          type="number"
          id="accesories-meters"
          label={t('market.access')}
          variant="outlined"
          placeholder="#m"
          value={accessMeters ?? ''}
          onChange={evt =>
            dispatch(
              setCreateReportFormState({
                accessMeters: evt.target.value !== '' ? Number(evt.target.value) : null,
              }),
            )
          }
          required
        />
      </Stack>
    </Paper>
  );
}

export default CreateReportStep2;
