import { useState } from 'react';
import { useAppSelector } from '../../../../../store';
import ProductStatementList from '../../../../../components/product-statement/ProductStatementList';
import ScanResultDrawer from '../../../../../components/product-statement/ScanResultDrawer';
import { Product } from '../../../../../services/product.service';
import useTranslate from '../../../../../hooks/useTranslate';
import { Alert, AlertColor, Snackbar } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setCreateStatementFormState } from '../../../../../store/slices/create-statement-form.slice';

function CreateStatementStep5() {
  const { t } = useTranslate();

  const dispatch = useDispatch();
  const { productStatements } = useAppSelector(state => state.createStatementForm);

  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [showBottomDrawer, setShowBottomDrawer] = useState<boolean>(false);

  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>('info');

  /** Add or update a product in the statement. */
  function handleAddProduct(quantity: number) {
    if (!selectedProduct) return;

    setTimeout(() => {
      let message: string;
      const existingItem = productStatements.find(stmt => stmt.product.ref === selectedProduct!.ref);
      if (existingItem) {
        dispatch(
          setCreateStatementFormState({
            productStatements: productStatements.map(stmt => {
              if (stmt.product.ref === existingItem.product.ref) {
                return { ...stmt, quantity };
              }
              return stmt;
            }),
          }),
        );

        message = t('statement.create.quantity_updated');
      } else {
        dispatch(
          setCreateStatementFormState({
            productStatements: [...productStatements, { product: selectedProduct!, quantity }],
          }),
        );

        message = t('statement.create.product_added');
      }

      setSnackbarSeverity('success');
      setSnackbarMessage(message);
      setSelectedProduct(null);
    }, 200);
  }

  /** Remove a product from the statement. */
  function handleRemoveProduct() {
    if (!selectedProduct) return;
    setShowBottomDrawer(false);
    setTimeout(() => {
      dispatch(
        setCreateStatementFormState({
          productStatements: productStatements.filter(stmt => stmt.product.ref !== selectedProduct!.ref),
        }),
      );
      setSnackbarSeverity('success');
      setSnackbarMessage(t('statement.create.product_removed'));
      setSelectedProduct(null);
    }, 50);
  }

  return (
    <>
      <ProductStatementList
        items={productStatements}
        onItemClicked={item => {
          setSelectedProduct(item.product);
          setShowBottomDrawer(true);
        }}
      />
      {selectedProduct && (
        <ScanResultDrawer
          visible={!!showBottomDrawer}
          product={selectedProduct}
          productAlreadyAdded={productStatements.some(stmt => stmt.product.ref === selectedProduct.ref)}
          initialQuantity={productStatements.find(stmt => stmt.product.ref === selectedProduct.ref)?.quantity}
          onClose={() => {
            setShowBottomDrawer(false);
            setSelectedProduct(null);
          }}
          onSubmit={quantity => handleAddProduct(quantity)}
          onRemove={handleRemoveProduct}
        />
      )}
      <Snackbar
        open={!!snackbarMessage}
        autoHideDuration={1000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => {
          setSnackbarMessage(null);
          setSelectedProduct(null);
        }}>
        <Alert severity={snackbarSeverity} variant="filled" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default CreateStatementStep5;
