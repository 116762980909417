import { BaseDictionary } from '../locales';
import FrDictionary from '../locales/fr';

/** A hook for managing translations. */
const useTranslate = () => {
  /**
   * Get a translation from the dictionary.
   * @param key The key of the translation to get.
   * @param params Parameters to replace in the translation.
   * @returns The translation.
   */
  function t(key: keyof BaseDictionary, params: { [key: string]: string } = {}) {
    // For now, we only have a French dictionary.
    // If we add more languages, we'll need to add more dictionaries in the `/src/locales` folder and use the correct one here.
    // The dictionnary would selected based on the user's selected locale, that could be stored in the Redux store.
    if (FrDictionary[key]) {
      let translation = FrDictionary[key];
      Object.keys(params).forEach(key => {
        translation = translation.replace(`{${key}}`, params[key]);
      });
      return translation;
    }

    return key;
  }

  /**
   * Convert a date to the locale's date format.
   * @param date The date to convert.
   * @param type The type of date to convert to. "short" for a short date (e.g. 01/01/2021), "long" for a long date (e.g. January 1, 2021).
   * @param includeTime Whether to include the time or not.
   * @returns The date as a string in the locale's date format.
   */
  function localeDate(date: Date | number | string, type: 'short' | 'long', includeTime: boolean = false) {
    const dateObject = new Date(date);
    return dateObject.toLocaleDateString('fr-FR', {
      year: 'numeric',
      month: type === 'short' ? '2-digit' : 'long',
      day: type === 'short' ? '2-digit' : 'numeric',
      hour: includeTime ? '2-digit' : undefined,
      minute: includeTime ? '2-digit' : undefined,
    });
  }

  /**
   * Format a number to the locale's number format.
   * @param number The number to format.
   * @param options Options to pass to the Intl.NumberFormat constructor.
   * @returns The formatted number.
   */
  function localeNumber(number: number, options?: Intl.NumberFormatOptions) {
    return new Intl.NumberFormat('fr-FR', options).format(number);
  }

  return { t, localeDate, localeNumber };
}

export default useTranslate;
