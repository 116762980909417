import { Button, Container, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useTranslate from '../hooks/useTranslate';

function NotFound() {
  const { t } = useTranslate();
  const navigate = useNavigate();

  return (
    <Container>
      <Stack spacing={2} sx={{ mt: 4 }}>
        <Typography variant="h2" component="h1" textAlign="center">
          {t('404.title')}
        </Typography>
        <Typography variant="subtitle1" textAlign="center">
          {t('404.description')}
        </Typography>
        <Button variant="contained" onClick={() => navigate('/')}>
          {t('404.back_home')}
        </Button>
      </Stack>
    </Container>
  );
}

export default NotFound;
