import { ChevronLeft, List } from '@mui/icons-material';
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import { CREATE_REPORT_FORM_SCAN_STEP, MIN_CREATE_REPORT_FORM_STEP } from '../../hooks/useCreateReportForm';
import useTranslate from '../../../../../hooks/useTranslate';

/** Props for the {@link CreateReportFormNavbar} component. */
export interface CreateReportFormNavbarProps {
  /** The step number. */
  step: number;
  /** A callback to go to the previous step. */
  onBackClick?: () => void;
  /** A callback to toggle the product list. */
  onToggleProductList?: () => void;
}

/** The navbar for the create report form. */
function CreateReportFormNavbar({ step, onBackClick, onToggleProductList }: CreateReportFormNavbarProps) {
  const { t } = useTranslate();

  /**
   * Get the title of a step.
   * @param step The step number.
   * @returns The step title.
   */
  function getStepTitle(step: number): string {
    switch (step) {
      case 1:
        return t('report.create.step_1.title');
      case 2:
        return t('report.create.step_2.title');
      case 3:
        return t('report.create.step_3.title');
      case 4:
        return t('report.create.step_4.title');
      case 5:
        return t('report.create.step_5.title');
      default:
        return '';
    }
  }

  return (
    <AppBar position="fixed" color="transparent" sx={{ backgroundColor: 'white', marginTop: 'var(--navbar-height)' }}>
      <Toolbar>
        {step !== MIN_CREATE_REPORT_FORM_STEP && (
          <IconButton size="large" edge="start" color="inherit" aria-label="back" sx={{ mr: 1 }} onClick={onBackClick}>
            <ChevronLeft />
          </IconButton>
        )}
        <Typography variant="subtitle1" color="inherit" component="div" sx={{ flexGrow: 1 }}>
          {t('global.step')} {step} : {getStepTitle(step)}
        </Typography>
        {step === CREATE_REPORT_FORM_SCAN_STEP && (
          <IconButton color="inherit" aria-label="product-list" sx={{ ml: 1 }} onClick={onToggleProductList}>
            <List />
          </IconButton>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default CreateReportFormNavbar;
