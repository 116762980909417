import { useState } from 'react';
import { Alert, Backdrop, Button, CircularProgress, IconButton, ImageList, ImageListItem, Stack } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ClearIcon from '@mui/icons-material/Clear';
import { useLazyGetStatementUploadPhotoUrlQuery } from '../../../services/benchmark-statement.service';
import { getPhotoUrl } from '../../../helpers/benchmark-statement';
import { useLazyGetReportUploadPhotoUrlQuery } from '../../../services/intervention-report.service';

/** Props for the {@link PhotoInput} component. */
export interface PhotoInputProps {
  /** For which entity the photo input is beeing used. */
  entity: 'statement' | 'report';
  /** The label to display on the button. */
  label: string;
  /** The message to display above the button. */
  infoMessage?: string;
  /** The keys of the photo to display. */
  photoKeys?: string[];
  /** A callback for when the upload starts. */
  onStartUpload?: () => void;
  /** A callback for when new photos have been uploaded. */
  onPhotosUploaded?: (keys: string[]) => void;
  /** A callback for when a photo remove button is clicked. */
  onRemovePhoto?: (key: string) => void;
}

/** An input for uploading photos. */
function PhotoInput({
  entity,
  label,
  infoMessage,
  photoKeys = [],
  onStartUpload,
  onPhotosUploaded,
  onRemovePhoto,
}: PhotoInputProps) {
  const [getStatementUploadUrl] = useLazyGetStatementUploadPhotoUrlQuery();
  const [getReportUploadUrl] = useLazyGetReportUploadPhotoUrlQuery();

  const [tempPhotos, setTempPhotos] = useState<string[]>([]);

  async function handleAddPhotos(evt: React.ChangeEvent<HTMLInputElement>) {
    if (!evt.target.files) return;

    onStartUpload?.();

    const files = Array.from(evt.target.files);
    setTempPhotos(state => [...state, ...files.map(file => URL.createObjectURL(file))]);

    const newKeys: string[] = [];
    for (const file of files) {
      const getUploadUrl = entity === 'statement' ? getStatementUploadUrl : getReportUploadUrl;
      const { data, error } = await getUploadUrl(file.type);
      if (error || !data) {
        continue;
      }

      const { signedUrl, key } = data;
      newKeys.push(key);

      await fetch(signedUrl, {
        method: 'PUT',
        headers: { 'Content-Type': 'image/*' },
        body: file,
      });
    }

    setTempPhotos([]);
    onPhotosUploaded?.(newKeys);
  }

  const previewPhotos: { key: string | null; url: string }[] = [
    ...photoKeys.map(key => ({ key, url: getPhotoUrl(key) })),
    ...tempPhotos.map(url => ({ key: null, url })),
  ];

  return (
    <Stack direction="column" spacing={2}>
      {infoMessage && <Alert severity="warning">{infoMessage}</Alert>}
      {previewPhotos.length > 0 && (
        <ImageList cols={3} gap={4}>
          {previewPhotos.map((photo, index) => (
            <ImageListItem key={index} sx={{ position: 'relative' }}>
              {photo.key === null && (
                <Backdrop sx={{ color: 'white', position: 'absolute' }} open>
                  <CircularProgress color="inherit" />
                </Backdrop>
              )}
              {Boolean(onRemovePhoto) && photo.key !== null && (
                <IconButton
                  aria-label="delete"
                  size="small"
                  sx={{ position: 'absolute', top: 4, right: 4, zIndex: 1 }}
                  onClick={() => onRemovePhoto!(photo.key!)}>
                  <ClearIcon fontSize="small" sx={{ color: 'white' }} />
                </IconButton>
              )}
              <img src={photo.url} loading="lazy" />
            </ImageListItem>
          ))}
        </ImageList>
      )}
      <Button component="label" variant="contained" startIcon={<CloudUploadIcon />} size="small" sx={{ py: 1 }}>
        {label}
        <input accept="image/*" type="file" capture="environment" hidden multiple onChange={handleAddPhotos} />
      </Button>
    </Stack>
  );
}

export default PhotoInput;
