import { useState } from 'react';
import {
  AppBar,
  Box,
  Card,
  Container,
  IconButton,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useNavigate } from 'react-router-dom';
import useTranslate from '../../../hooks/useTranslate';
import SearchInput from '../../../components/ui/form/SearchInput';
import { useGetWholesalersQuery } from '../../../services/wholesaler.service';
import { FrenchDepartments } from '../../../helpers/departments';
import { nonBreaking } from '../../../utils/string';
import WholesalerRow from './components/WholesalerRow';
import themeConstants from '../../../theme/constants';

function WholesalerList() {
  const { t } = useTranslate();
  const navigate = useNavigate();

  const [page, setPage] = useState<number>(1);
  const [query, setQuery] = useState<string>('');

  const { data, isFetching } = useGetWholesalersQuery({ page, query: formatQuery(query) });

  function formatQuery(query: string) {
    const departement = FrenchDepartments.find(department => department.name.toLowerCase() === query.toLowerCase());
    if (departement) {
      return departement.code;
    }
    return query;
  }

  return (
    <>
      <AppBar position="fixed" color="transparent" sx={{ backgroundColor: 'white', marginTop: 'var(--navbar-height)' }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="back"
            sx={{ mr: 1 }}
            onClick={() => navigate(-1)}>
            <ChevronLeftIcon />
          </IconButton>
          <Typography variant="subtitle1" color="inherit" component="div" sx={{ flexGrow: 1 }}>
            {t('wholesaler.list')}
          </Typography>
        </Toolbar>
      </AppBar>

      <Container sx={{ marginTop: `${themeConstants.navbarsHeight}px`, paddingY: 4 }}>
        <Card variant="outlined">
          <Stack direction="column" spacing={2}>
            <SearchInput
              placeholder={t('wholesaler.search')}
              value={query}
              onChange={evt => setQuery(evt.target.value)}
              size="small"
              fullWidth
            />
            <TableContainer>
              <Table
                aria-label="wholesalers table"
                sx={{ '& > tbody tr:nth-of-type(odd)': { backgroundColor: '#00000006' } }}>
                <TableHead>
                  <TableRow>
                    <TableCell>{nonBreaking(t('wholesaler.id_code'))}</TableCell>
                    <TableCell>{t('global.name')}</TableCell>
                    <TableCell>{t('global.city')}</TableCell>
                    <TableCell>{t('global.department')}</TableCell>
                    <TableCell>{t('global.address')}</TableCell>
                    <TableCell>{nonBreaking(t('wholesaler.last_benchmark'))}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.edges.map(wholesaler => <WholesalerRow key={wholesaler.id} wholesaler={wholesaler} />)}
                  {data?.edges.length === 0 && !isFetching && (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        {t('wholesaler.empty_for_filters')}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {(data?.pageInfo.numberOfPages ?? 0) > 1 && (
              <Box mt={2} display="flex" justifyContent="flex-end">
                <Pagination
                  count={data?.pageInfo.numberOfPages}
                  color="primary"
                  onChange={(_, value) => setPage(value)}
                />
              </Box>
            )}
          </Stack>
        </Card>
      </Container>
    </>
  );
}

export default WholesalerList;
