import React, { useState } from 'react';
import { Box, Button, Card, Container, Stack, TextField, Typography } from '@mui/material';
import useTranslate from '../../hooks/useTranslate';
import ScreenCenter from '../../components/layout/ScreenCenter';
import { useSignInMutation } from '../../services/auth.service';
import { useAppDispatch } from '../../store';
import { signIn as signInAction } from '../../store/slices/auth.slice';
import styled from '@emotion/styled';

function SignIn() {
  const { t } = useTranslate();
  const dispatch = useAppDispatch();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);

  const [signIn] = useSignInMutation();

  function handleSignIn(evt: React.FormEvent) {
    evt.preventDefault();
    evt.stopPropagation();

    setError(null);

    if (!email || !password) {
      setError(t('auth.error.empty_email_or_password'));
      return;
    }

    signIn({ email, password })
      .unwrap()
      .then(({ access_token, refresh_token }) => {
        dispatch(signInAction({ accessToken: access_token, refreshToken: refresh_token }));
      })
      .catch(() => {
        setError(t('auth.error.invalid_email_or_password'));
      });
  }

  return (
    <ScreenCenter>
      <Container maxWidth="xs">
        <Card variant="outlined">
          <Box display="flex" justifyContent="center" marginY={1}>
            <Logo src="/assets/delabie-logo.webp" />
          </Box>
          <Typography component="h1" variant="h5" textAlign="center" marginY={2}>
            {t('auth.login')}
          </Typography>
          <form onSubmit={handleSignIn}>
            <Stack direction="column" spacing={2}>
              <TextField
                id="email"
                type="email"
                label={t('auth.email')}
                placeholder="mail@mail.com"
                variant="outlined"
                autoComplete="email"
                onChange={evt => setEmail(evt.currentTarget.value)}
              />
              <TextField
                id="password"
                type="password"
                label={t('auth.password')}
                variant="outlined"
                autoComplete="current-password"
                onChange={evt => setPassword(evt.currentTarget.value)}
                error={!!error}
                helperText={error}
              />

              <Button variant="contained" type="submit">
                {t('auth.sign_in')}
              </Button>
            </Stack>
          </form>
        </Card>
      </Container>
    </ScreenCenter>
  );
}

const Logo = styled.img`
  height: 24px;
`;

export default SignIn;
