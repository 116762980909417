import { useEffect, useState } from 'react';
import { Backdrop, Button, CircularProgress, Container, Hidden } from '@mui/material';
import CreateReportFormNavbar from './components/layout/CreateReportFormNavbar';
import DesktopForbiddenPage from '../../../components/layout/DesktopForbiddenPage';
import useTranslate from '../../../hooks/useTranslate';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useCreateReportForm, {
  CREATE_REPORT_FORM_SCAN_STEP,
  MAX_CREATE_REPORT_FORM_STEP,
  MIN_CREATE_REPORT_FORM_STEP,
} from './hooks/useCreateReportForm';
import { useAppDispatch, useAppSelector } from '../../../store';
import CreateReportStep1 from './components/steps/CreateReportStep1';
import CreateReportFormBottomBar from './components/layout/CreateReportFormBottomBar';
import CreateReportStep2 from './components/steps/CreateReportStep2';
import CreateReportStep3 from './components/steps/CreateReportStep3';
import CreateReportStep4 from './components/steps/CreateReportStep4';
import CreateReportStep5 from './components/steps/CreateReportStep5';
import { useCreateInterventionReportMutation } from '../../../services/intervention-report.service';
import { resetCreateReportFormState } from '../../../store/slices/create-report-form.slice';
import themeConstants from '../../../theme/constants';

function CreateInterventionReport() {
  const { t } = useTranslate();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const createReportFormState = useAppSelector(state => state.createReportForm);

  const [createInterventionReport, { isLoading: isCreatingInterventionReport }] = useCreateInterventionReportMutation();

  const [showProductList, setShowProductList] = useState<boolean>(searchParams.get('showProductList') === 'true');

  const { goToStep, isCurrentStepValid } = useCreateReportForm();

  function renderStep(step: number): JSX.Element | null {
    switch (step) {
      case 1:
        return <CreateReportStep1 />;
      case 2:
        return <CreateReportStep2 />;
      case 3:
        return (
          <CreateReportStep3
            showProductList={showProductList}
            onHideProductList={() => {
              searchParams.delete('showProductList');
              setSearchParams(searchParams);
            }}
          />
        );
      case 4:
        return <CreateReportStep4 />;
      case 5:
        return <CreateReportStep5 />;
      default:
        return null;
    }
  }

  function handleSubmit(evt: React.FormEvent<HTMLFormElement>) {
    evt.preventDefault();
    evt.stopPropagation();

    if (step !== MAX_CREATE_REPORT_FORM_STEP) {
      goToStep(step + 1);
      return;
    }

    createInterventionReport({
      benchmarkProposalId: createReportFormState.benchmarkProposalId ?? undefined,
      wholesalerId: createReportFormState.wholesalerId!,
      photoKeys: createReportFormState.photoKeys,
      products: createReportFormState.productStatements.map(stmt => ({
        productRef: stmt.product.ref,
        quantity: stmt.quantity,
      })),
      publicPlacesMeters: createReportFormState.publicPlacesMeters!,
      renovationMeters: createReportFormState.renovationMeters!,
      accessMeters: createReportFormState.accessMeters!,
      comments: createReportFormState.comments,
    })
      .unwrap()
      .then(report => {
        dispatch(resetCreateReportFormState());
        if (report.benchmarkProposal) {
          navigate(`/benchmark-proposal/${report.benchmarkProposal.id}`);
        } else {
          navigate(`/report/${report.id}`);
        }
      })
      .catch(() => {
        alert(t('global.error.unknown'));
      });
  }

  useEffect(() => {
    setShowProductList(searchParams.get('showProductList') === 'true');
  }, [searchParams]);

  const step = createReportFormState.step;

  let nextStepMessage = t('global.next');
  if (step === 1 && !isCurrentStepValid) {
    nextStepMessage = t('report.error.select_wholesaler');
  } else if (step === 3) {
    nextStepMessage = t('report.create.form.listing_over');
  } else if (step === 5) {
    nextStepMessage = t('global.finalize');
  }

  return (
    <>
      <Hidden smDown>
        <DesktopForbiddenPage message={t('report.error.desktop_forbidden')} />
      </Hidden>

      <Hidden smUp>
        <CreateReportFormNavbar
          step={step}
          onBackClick={() => goToStep(step - 1)}
          onToggleProductList={() => {
            if (showProductList) {
              searchParams.delete('showProductList');
            } else {
              searchParams.set('showProductList', 'true');
            }
            setSearchParams(searchParams);
          }}
        />

        <form onSubmit={handleSubmit}>
          <Container
            sx={{
              paddingY: step !== CREATE_REPORT_FORM_SCAN_STEP ? '1.25rem' : 0,
              paddingX: step !== CREATE_REPORT_FORM_SCAN_STEP ? undefined : 0,
              marginBottom: step !== CREATE_REPORT_FORM_SCAN_STEP ? `${themeConstants.navbarsHeight}px` : 0,
              marginTop: `${themeConstants.navbarsHeight}px`,
            }}>
            {renderStep(step)}
          </Container>

          <CreateReportFormBottomBar
            actionsElement={
              <>
                {step > MIN_CREATE_REPORT_FORM_STEP && (
                  <Button type="button" variant="outlined" size="medium" onClick={() => goToStep(step - 1)} fullWidth>
                    {t('global.previous')}
                  </Button>
                )}
                <Button type="submit" variant="contained" size="medium" disabled={!isCurrentStepValid} fullWidth>
                  {nextStepMessage}
                </Button>
              </>
            }
          />
        </form>

        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={isCreatingInterventionReport}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Hidden>
    </>
  );
}

export default CreateInterventionReport;
