import { ProjectConfig } from '.';

/** Project configuration for the staging environment. */
const StagingConfig: ProjectConfig = {
  env: 'staging',
  apiBaseUrl: 'https://staging.api.merch.delabie.fr',
  s3MediasBucketUrl: 'https://delabie-merch-medias.s3.eu-west-3.amazonaws.com/',
  priceOfferMailTo: 'offre-prix@delabie.fr',
};

export default StagingConfig;
