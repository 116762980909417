import Config from "../../../../config";
import { addStatusToProductAdjustments, getProposalCoordinators } from "../../../../helpers/benchmark-proposal";
import { getProductDetailsUrl } from "../../../../helpers/product";
import { getFullName } from "../../../../helpers/user";
import { BenchmarkProposal, BenchmarkProposalProductAdjustment } from "../../../../services/benchmark-proposal.service";
import { useAppSelector } from "../../../../store";

const MailTemplate =
  `Bonjour,

Pourriez-vous s’il vous plaît faire une offre à [wholesalerName] ([wholesalerId]) à [wholesalerCity] pour [contact] / [contactMail], avec :

À remise 45 + 5 :
[adjustmentsToAdd]

À remise 45 :
[adjustmentsToRestock]

La référence pour l’offre est « Mise en stock ».

Date de validité de l’offre : 2 mois. Non renouvelable.

Pourriez-vous s’il vous plaît mettre [salesCoordinatorsEmail] en copie de l’envoi de l’offre ?

En vous remerciant,

Bien cordialement,

[userName]
`;

function usePriceOffer() {
  const { user } = useAppSelector(state => state.auth);

  function getAdjustmentListItem(adjustment: BenchmarkProposalProductAdjustment) {
    return ` • ${adjustment.quantityAdjustment} x ${adjustment.product.ref} (${getProductDetailsUrl(adjustment.product)})`;
  }

  function getMailToHref(proposal: BenchmarkProposal) {
    const contactToDisplay = proposal.benchmarkStatement.contactEmail || proposal.benchmarkStatement.contactPhone;
    const salesCoordinators = getProposalCoordinators(proposal);

    const adjustmentsWithStatuses = addStatusToProductAdjustments(proposal);
    const adjustmentsToAdd = adjustmentsWithStatuses.filter(adj => adj.status === 'missing');
    const adjustmentsToRestock = adjustmentsWithStatuses.filter(adj => adj.status === 'restock');

    const adjustmentsToAddText = adjustmentsToAdd.length > 0
      ? adjustmentsToAdd.map(adj => getAdjustmentListItem(adj)).join('\n')
      : 'Aucun produit.';

    const adjustmentsToRestockText = adjustmentsToRestock.length > 0
      ? adjustmentsToRestock.map(adj => getAdjustmentListItem(adj)).join('\n')
      : 'Aucun produit.';

    const subject = `Mise en stock - ${proposal.wholesaler.name} ${proposal.wholesaler.city}`;
    const body = MailTemplate
      .replace('[wholesalerName]', proposal.wholesaler.name)
      .replace('[wholesalerId]', proposal.wholesaler.id)
      .replace('[wholesalerCity]', proposal.wholesaler.city)
      .replace('[contact]', proposal.benchmarkStatement.contact)
      .replace('[contactMail]', contactToDisplay)
      .replace('[adjustmentsToAdd]', adjustmentsToAddText)
      .replace('[adjustmentsToRestock]', adjustmentsToRestockText)
      .replace('[salesCoordinatorsEmail]', salesCoordinators.map(sales => `“${sales.email}”`).join(', '))
      .replace('[userName]', getFullName(user!));

    return `mailto:${Config.priceOfferMailTo}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  }

  return {
    getMailToHref
  }
}

export default usePriceOffer;
