import {
  Autocomplete,
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import useTranslate from '../../../../../hooks/useTranslate';
import { useGetAllFormSuggestionsQuery } from '../../../../../services/form-suggestion.service';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { setCreateStatementFormState } from '../../../../../store/slices/create-statement-form.slice';
import PhotoInput from '../../../../../components/ui/form/PhotoInput';

function CreateStatementStep3() {
  const { t } = useTranslate();

  const dispatch = useAppDispatch();
  const {
    publicPlacesMeters,
    renovationMeters,
    accessMeters,
    areTabletsAvailable,
    arePerforedBottomsInstalled,
    areLoadBarsAvailable,
    linearHeight,
    linearWidth,
    photoKeys,
  } = useAppSelector(state => state.createStatementForm);

  const { data: suggestions } = useGetAllFormSuggestionsQuery();

  return (
    <Stack direction="column" spacing={2}>
      <Paper elevation={1}>
        <Stack direction="column" spacing={3} p={2}>
          <FormLabel>{t('statement.create.form.linear_meters_implanted_label')}</FormLabel>
          <TextField
            type="number"
            id="public-places-meters"
            label={t('market.public_places')}
            variant="outlined"
            placeholder="#m"
            value={publicPlacesMeters ?? ''}
            onChange={evt =>
              dispatch(
                setCreateStatementFormState({
                  publicPlacesMeters: evt.target.value !== '' ? Number(evt.target.value) : null,
                }),
              )
            }
            required
          />
          <TextField
            type="number"
            id="renovation-meters"
            label={t('market.renovation')}
            variant="outlined"
            placeholder="#m"
            value={renovationMeters ?? ''}
            onChange={evt =>
              dispatch(
                setCreateStatementFormState({
                  renovationMeters: evt.target.value !== '' ? Number(evt.target.value) : null,
                }),
              )
            }
            required
          />
          <TextField
            type="number"
            id="accesories-meters"
            label={t('market.access')}
            variant="outlined"
            placeholder="#m"
            value={accessMeters ?? ''}
            onChange={evt =>
              dispatch(
                setCreateStatementFormState({
                  accessMeters: evt.target.value !== '' ? Number(evt.target.value) : null,
                }),
              )
            }
            required
          />
        </Stack>
      </Paper>

      <Paper elevation={1}>
        <Box p={2}>
          <Typography variant="overline" component="h3">
            {t('statement.create.form.available_tablets_label')}
          </Typography>
          <FormControl>
            <RadioGroup
              row
              name="tablets-available"
              value={areTabletsAvailable ? 'yes' : 'no'}
              onChange={evt =>
                dispatch(setCreateStatementFormState({ areTabletsAvailable: evt.target.value === 'yes' }))
              }>
              <FormControlLabel value="yes" control={<Radio />} label={t('global.yes')} />
              <FormControlLabel value="no" control={<Radio />} label={t('global.no')} />
            </RadioGroup>
          </FormControl>
        </Box>
      </Paper>

      <Paper elevation={1}>
        <Box p={2}>
          <Typography variant="overline" component="h3">
            {t('statement.create.form.perforated_bottoms_label')}
          </Typography>
          <FormControl>
            <RadioGroup
              row
              name="perforated-bottoms"
              value={arePerforedBottomsInstalled ? 'yes' : 'no'}
              onChange={evt =>
                dispatch(setCreateStatementFormState({ arePerforedBottomsInstalled: evt.target.value === 'yes' }))
              }>
              <FormControlLabel value="yes" control={<Radio />} label={t('global.yes')} />
              <FormControlLabel value="no" control={<Radio />} label={t('global.no')} />
            </RadioGroup>
          </FormControl>
        </Box>
      </Paper>

      {!arePerforedBottomsInstalled && (
        <Paper elevation={1}>
          <Box p={2}>
            <Typography variant="overline" component="h3">
              {t('statement.create.form.available_load_bars_label')}
            </Typography>
            <FormControl>
              <RadioGroup
                row
                name="available-load-bars"
                value={areLoadBarsAvailable ? 'yes' : 'no'}
                onChange={evt =>
                  dispatch(setCreateStatementFormState({ areLoadBarsAvailable: evt.target.value === 'yes' }))
                }>
                <FormControlLabel value="yes" control={<Radio />} label={t('global.yes')} />
                <FormControlLabel value="no" control={<Radio />} label={t('global.no')} />
              </RadioGroup>
            </FormControl>
          </Box>
        </Paper>
      )}

      <Paper elevation={1}>
        <Stack direction="column" spacing={2} p={2}>
          <FormLabel>{t('statement.create.form.linear_meters_label')}</FormLabel>
          <Autocomplete
            id="linear-height"
            options={suggestions?.linearHeight.map(s => s.value) ?? []}
            renderInput={params => (
              <TextField {...params} label={t('statement.create.form.linear_height_label')} required />
            )}
            inputValue={linearHeight}
            onInputChange={(_, value) => dispatch(setCreateStatementFormState({ linearHeight: value ?? '' }))}
            freeSolo
          />
          <Autocomplete
            id="linear-width"
            options={suggestions?.linearWidth.map(s => s.value) ?? []}
            renderInput={params => (
              <TextField {...params} label={t('statement.create.form.linear_width_label')} required />
            )}
            inputValue={linearWidth}
            onInputChange={(_, value) => dispatch(setCreateStatementFormState({ linearWidth: value ?? '' }))}
            freeSolo
          />
        </Stack>
      </Paper>

      <Paper elevation={1}>
        <Stack direction="column" spacing={2} p={2}>
          <FormLabel>{t('global.photos')}</FormLabel>
          <PhotoInput
            entity="statement"
            label={t('statement.create.form.add_photo')}
            infoMessage={t('statement.create.form.photos_label')}
            photoKeys={photoKeys}
            onStartUpload={() => dispatch(setCreateStatementFormState({ arePhotosUploading: true }))}
            onPhotosUploaded={keys => {
              dispatch(setCreateStatementFormState({ photoKeys: [...photoKeys, ...keys] }));
              dispatch(setCreateStatementFormState({ arePhotosUploading: false }));
            }}
            onRemovePhoto={key => {
              dispatch(setCreateStatementFormState({ photoKeys: photoKeys.filter(k => k !== key) }));
            }}
          />
        </Stack>
      </Paper>
    </Stack>
  );
}

export default CreateStatementStep3;
