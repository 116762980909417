import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { getUserFromToken } from '../../helpers/auth';
import { UserSummary } from '../../services/user.service';

/** A user of the app. */
export type UserPayload = UserSummary;

/** Redux state for authentication. */
export interface AuthState {
  /** The user access token. */
  accessToken: string | null;
  /** The user refresh token. */
  refreshToken: string | null;
  /** Whether the user is authenticated. */
  isAuthenticated: boolean;
  /** The user. */
  user: UserPayload | null;
}

/** The initial state for the auth slice. */
const initialState: AuthState = {
  accessToken: null,
  refreshToken: null,
  isAuthenticated: false,
  user: null,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    /** Sign in a user. */
    signIn(state, action: PayloadAction<{ accessToken: string, refreshToken: string }>) {
      const { accessToken, refreshToken } = action.payload;

      const user = getUserFromToken(accessToken);
      if (!user) return;

      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      state.user = user;
      state.isAuthenticated = true;
    },

    /** Sign out a user. */
    signOut(state) {
      state.accessToken = null;
      state.refreshToken = null;
      state.user = null;
      state.isAuthenticated = false;
    },
  }
})

export const { signIn, signOut } = authSlice.actions;

export default authSlice.reducer;