import { Search } from '@mui/icons-material';
import { InputAdornment, TextField, TextFieldProps } from '@mui/material';

/** An input for searching. */
function SearchInput(props: Omit<TextFieldProps, 'variant' | 'InputProps'>) {
  return (
    <TextField
      {...props}
      variant="outlined"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
      }}
    />
  );
}

export default SearchInput;
