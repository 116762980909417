import { Box, Paper, TextField } from '@mui/material';
import useTranslate from '../../../../../hooks/useTranslate';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { setCreateStatementFormState } from '../../../../../store/slices/create-statement-form.slice';

function CreateStatementStep6() {
  const { t } = useTranslate();

  const dispatch = useAppDispatch();
  const { comments } = useAppSelector(state => state.createStatementForm);

  return (
    <Paper elevation={1}>
      <Box p={2}>
        <TextField
          id="comments"
          label={t('statement.create.comments_label')}
          variant="outlined"
          value={comments}
          onChange={evt => dispatch(setCreateStatementFormState({ comments: evt.target.value }))}
          minRows={6}
          multiline
          fullWidth
        />
      </Box>
    </Paper>
  );
}

export default CreateStatementStep6;
