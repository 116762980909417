import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../../store';
import ProductStatementList from '../../../../../components/product-statement/ProductStatementList';
import ScanResultDrawer from '../../../../../components/product-statement/ScanResultDrawer';
import { Product } from '../../../../../services/product.service';
import useTranslate from '../../../../../hooks/useTranslate';
import { Alert, AlertColor, Snackbar } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setCreateReportFormState } from '../../../../../store/slices/create-report-form.slice';
import { useLazyGetBenchmarkProposalQuery } from '../../../../../services/benchmark-proposal.service';
import { getProductAdjustmentStatus } from '../../../../../helpers/benchmark-proposal';

function CreateReportStep4() {
  const { t } = useTranslate();

  const [getBenchmarkProposal] = useLazyGetBenchmarkProposalQuery();

  const dispatch = useDispatch();
  const { benchmarkProposalId, productStatements } = useAppSelector(state => state.createReportForm);

  const [missingProducts, setMissingProducts] = useState<Product[]>([]);

  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [showBottomDrawer, setShowBottomDrawer] = useState<boolean>(false);

  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>('info');

  /** Add or update a product in the statement. */
  function handleAddProduct(quantity: number) {
    if (!selectedProduct) return;

    setTimeout(() => {
      let message: string;
      const existingItem = productStatements.find(stmt => stmt.product.ref === selectedProduct!.ref);
      if (existingItem) {
        dispatch(
          setCreateReportFormState({
            productStatements: productStatements.map(stmt => {
              if (stmt.product.ref === existingItem.product.ref) {
                return { ...stmt, quantity };
              }
              return stmt;
            }),
          }),
        );

        message = t('statement.create.quantity_updated');
      } else {
        dispatch(
          setCreateReportFormState({
            productStatements: [...productStatements, { product: selectedProduct!, quantity }],
          }),
        );

        message = t('statement.create.product_added');
      }

      setSnackbarSeverity('success');
      setSnackbarMessage(message);
      setSelectedProduct(null);
    }, 200);
  }

  /** Remove a product from the statement. */
  function handleRemoveProduct() {
    if (!selectedProduct) return;
    setShowBottomDrawer(false);
    setTimeout(() => {
      dispatch(
        setCreateReportFormState({
          productStatements: productStatements.filter(stmt => stmt.product.ref !== selectedProduct!.ref),
        }),
      );
      setSnackbarSeverity('success');
      setSnackbarMessage(t('statement.create.product_removed'));
      setSelectedProduct(null);
    }, 50);
  }

  useEffect(() => {
    if (benchmarkProposalId === null) return;

    getBenchmarkProposal(benchmarkProposalId)
      .unwrap()
      .then(response => {
        const productsToHaveInStock = response.productAdjustments
          .filter(adj => getProductAdjustmentStatus(adj) !== 'remove')
          .map(adj => adj.product);

        setMissingProducts(
          productsToHaveInStock.filter(p => !productStatements.some(stmt => stmt.product.ref === p.ref)),
        );
      })
      .catch(() => {
        setMissingProducts([]);
      });
  }, [benchmarkProposalId, productStatements]);

  return (
    <>
      <ProductStatementList
        items={productStatements}
        missingProducts={missingProducts}
        onItemClicked={item => {
          setSelectedProduct(item.product);
          setShowBottomDrawer(true);
        }}
      />
      {selectedProduct && (
        <ScanResultDrawer
          visible={!!showBottomDrawer}
          product={selectedProduct}
          productAlreadyAdded={productStatements.some(stmt => stmt.product.ref === selectedProduct.ref)}
          initialQuantity={productStatements.find(stmt => stmt.product.ref === selectedProduct.ref)?.quantity}
          onClose={() => {
            setShowBottomDrawer(false);
            setSelectedProduct(null);
          }}
          onSubmit={quantity => handleAddProduct(quantity)}
          onRemove={handleRemoveProduct}
        />
      )}
      <Snackbar
        open={!!snackbarMessage}
        autoHideDuration={1000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => {
          setSnackbarMessage(null);
          setSelectedProduct(null);
        }}>
        <Alert severity={snackbarSeverity} variant="filled" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default CreateReportStep4;
