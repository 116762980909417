import { useEffect, useState } from 'react';
import useTranslate from '../../../../../hooks/useTranslate';
import { CircularProgress, LinearProgress, Stack, Typography } from '@mui/material';
import WholesalerCard from '../../../../../components/wholesaler/WholesalerCard';
import SearchInput from '../../../../../components/ui/form/SearchInput';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import useScrollable from '../../../../../hooks/useScrollable';
import {
  BenchmarkProposalStatus,
  BenchmarkProposalSummary,
  useGetBenchmarkProposalsQuery,
} from '../../../../../services/benchmark-proposal.service';
import { setCreateReportFormState } from '../../../../../store/slices/create-report-form.slice';

function CreateReportStep1ProposalTab() {
  const { t } = useTranslate();

  const dispatch = useAppDispatch();
  const { benchmarkProposalId } = useAppSelector(state => state.createReportForm);

  const [page, setPage] = useState<number>(1);
  const [query, setQuery] = useState<string>('');

  const [proposals, setProposals] = useState<BenchmarkProposalSummary[]>([]);
  const { data, isLoading, isFetching } = useGetBenchmarkProposalsQuery({
    page,
    filters: { state: BenchmarkProposalStatus.ACCEPTED_BY_SALES_MANAGER, wholesalerQuery: query || undefined },
  });

  useScrollable('window', {
    onBottomReached: () => {
      if (isLoading || isFetching || !data?.pageInfo.hasNextPage) return;
      setPage(page + 1);
    },
  });

  function handleSearch(evt: React.ChangeEvent<HTMLInputElement>) {
    setQuery(evt.currentTarget.value);
    setPage(1);
  }

  // Merge new proposals with the previous ones
  useEffect(() => {
    if (!data) return;

    if (page === 1) {
      setProposals(data.edges);
    } else {
      setProposals(prev => [...prev, ...data.edges]);
    }
  }, [data]);

  return (
    <Stack direction="column" spacing={2}>
      <SearchInput
        id="search-bar"
        placeholder={t('wholesaler.search')}
        size="small"
        value={query}
        onChange={handleSearch}
      />

      {isLoading && <LinearProgress color="primary" />}

      {proposals.map(proposal => (
        <WholesalerCard
          key={proposal.id}
          wholesaler={proposal.wholesaler}
          proposalState={proposal.state}
          selected={proposal.id === benchmarkProposalId}
          onClick={() =>
            dispatch(
              setCreateReportFormState({
                wholesalerId: proposal.wholesaler.id,
                benchmarkProposalId: proposal.id,
              }),
            )
          }
        />
      ))}

      <Stack direction="column" alignItems="center" py={1}>
        {data?.pageInfo.hasNextPage ? (
          <CircularProgress color="primary" size={30} />
        ) : (
          <Typography variant="caption" component="div">
            {t('wholesaler.end_of_the_list')}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}

export default CreateReportStep1ProposalTab;
