import { Box, Button, IconButton, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import styled from '@emotion/styled';
import { useAppDispatch, useAppSelector } from '../../store';
import { signOut } from '../../store/slices/auth.slice';
import useTranslate from '../../hooks/useTranslate';
import { useNavigate } from 'react-router-dom';
import Config from '../../config';

/** The main navbar of the app. */
function Navbar() {
  const { t } = useTranslate();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAppSelector(state => state.auth);

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  function handleSignOut() {
    dispatch(signOut());
    navigate('/');
    window.location.reload();
  }

  return (
    <Header>
      <Stack direction="row" alignItems="center" justifyContent="center" sx={{ height: '100%' }}>
        <Box flexGrow={1} display="flex" flex={1}>
          {(Config.env === 'development' || Config.env === 'staging') && (
            <Typography variant="caption" sx={{ color: 'white' }}>
              {Config.env.toUpperCase()}
            </Typography>
          )}
        </Box>
        <Box flexGrow={1} display="flex" flex={1} justifyContent="center">
          <a href="/">
            <Logo src="/assets/delabie-logo.webp" />
          </a>
        </Box>
        <Box flexGrow={1} display="flex" flex={1} justifyContent="flex-end">
          {isAuthenticated && (
            <>
              {isMobile ? (
                <IconButton aria-label="logout" sx={{ color: 'white' }} onClick={handleSignOut}>
                  <LogoutIcon />
                </IconButton>
              ) : (
                <Button variant="text" size="small" sx={{ color: 'white' }} onClick={handleSignOut}>
                  {t('auth.sign_out')}
                </Button>
              )}
            </>
          )}
        </Box>
      </Stack>
    </Header>
  );
}

const Header = styled.header`
  box-sizing: border-box;
  width: 100%;
  background-color: var(--navbar-background-color);
  position: fixed;
  top: 0;
  left: 0;
  height: var(--navbar-height);
  padding: 0 2rem;
  z-index: 100;
  @media screen and (max-width: 600px) {
    padding: 0 1rem;
  }
`;

const Logo = styled.img`
  height: 19px;
  filter: brightness(0) invert(1);
`;

export default Navbar;
