import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useAppSelector } from '../store';
import Layout from '../components/layout/Layout';
import Dashboard from '../pages/account/Dashboard/Dashboard';
import SignIn from '../pages/auth/SignIn';
import Password from '../pages/auth/Password';
import AuthGuard from './AuthGuard';
import CreateStatement from '../pages/statement/create/CreateStatement';
import WholesalerList from '../pages/wholesaler/list/WholesalerList';
import NotFound from '../pages/NoFound';
import Wholesaler from '../pages/wholesaler/show/Wholesaler';
import BenchmarkProposal from '../pages/benchmark-proposal/show/BenchmarkProposal';
import CreateInterventionReport from '../pages/intervention-report/create/CreateInterventionReport';
import InterventionReport from '../pages/intervention-report/show/InterventionReport';

/** The App Router. */
function AppRouter() {
  const { isAuthenticated } = useAppSelector(state => state.auth);

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          {/* Public routes */}
          <Route path="/" element={isAuthenticated ? <Dashboard /> : <SignIn />} />
          <Route path="/password" element={<Password />} />

          {/* Authenticated routes */}
          <Route element={<AuthGuard />}>
            <Route path="/statement">
              <Route path="/statement/create" element={<CreateStatement />} />
            </Route>

            <Route path="/wholesaler">
              <Route path="/wholesaler/:id" element={<Wholesaler />} />
              <Route path="/wholesaler" element={<WholesalerList />} />
            </Route>

            <Route path="/benchmark-proposal">
              <Route path="/benchmark-proposal" element={<NotFound />} />
              <Route path="/benchmark-proposal/:id" element={<BenchmarkProposal />} />
            </Route>

            <Route path="/report">
              <Route path="/report" element={<NotFound />} />
              <Route path="/report/:id" element={<InterventionReport />} />
              <Route path="/report/create" element={<CreateInterventionReport />} />
            </Route>
          </Route>

          {/* 404 */}
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
