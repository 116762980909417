// import { FetchBaseQueryArgs } from '@reduxjs/toolkit/query';
import { RootState } from "../store";

/** A helper that prepares the headers for the RTK Query servics. */
export const prepareHeaders /*: FetchBaseQueryArgs['prepareHeaders']*/ = (headers: any, { getState, endpoint }: any) => {
  const state = getState() as RootState;

  let token = state.auth.accessToken;
  if (endpoint === 'refreshToken') {
    token = state.auth.refreshToken;
  }

  if (token) {
    headers.set('Authorization', `Bearer ${token}`);
  }

  return headers;
}