import { Chip, TableCell, TableRow } from '@mui/material';
import { BenchmarkProposalSummary } from '../../../../services/benchmark-proposal.service';
import { useNavigate } from 'react-router-dom';
import { getFullName } from '../../../../helpers/user';
import { getStatusColor } from '../../../../helpers/benchmark-proposal';
import useTranslate from '../../../../hooks/useTranslate';

export interface WholesalerProposalRowProps {
  proposal: BenchmarkProposalSummary;
}

function WholesalerProposalRow({ proposal }: WholesalerProposalRowProps) {
  const { t, localeDate } = useTranslate();
  const navigate = useNavigate();

  return (
    <TableRow
      key={proposal.id}
      sx={{
        '&:last-child td, &:last-child th': { border: 0 },
        userSelect: 'none',
        cursor: 'pointer',
      }}
      onClick={() => navigate(`/benchmark-proposal/${proposal.id}`)}>
      <TableCell>{localeDate(proposal.createdAt, 'short')}</TableCell>
      <TableCell>{getFullName(proposal.sales)}</TableCell>
      <TableCell>
        <Chip
          label={t(`benchmark_proposal.status.${proposal.state}`)}
          size="small"
          color={getStatusColor(proposal.state)}
        />
      </TableCell>
    </TableRow>
  );
}

export default WholesalerProposalRow;
