import { User, UserRole, UserSummary } from "../services/user.service";
import { UserPayload } from "../store/slices/auth.slice";

/**
 * Get the user's full name.
 * @param user The user.
 * @returns The user's full name.
 */
export function getFullName(user: User | UserSummary | UserPayload): string {
  return `${user.firstName} ${user.lastName}`;
}

/**
 * Get the manager of the user with the given role.  
 * If the user has several managers with the given role, the first one is returned.
 * @param user The user to get the manager from.
 * @param role The role of the manager.
 * @returns The manager of the user with the given role, or null if the user has no manager with the given role.
 */
export function getManager(user: User, role: UserRole): UserSummary | null {
  const managersWithRole = user.managedBy.filter(relation => relation.manager.role === role);
  return managersWithRole.length > 0 ? managersWithRole[0].manager : null;
}

/**
 * Get the managers of the user with the given role.  
 * @param user The user to get the managers from.
 * @param role The role of the managers.
 * @returns The managers of the user with the given role.
 */
export function getManagers(user: User, role: UserRole): UserSummary[] {
  return user.managedBy.filter(relation => relation.manager.role === role).map(relation => relation.manager);
}