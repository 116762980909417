import React from 'react';
import { Box } from '@mui/material';

/** Props for the {@link ScreenCenter} component. */
export interface ScreenCenterProps {
  children?: React.ReactNode;
}

/** Displays it's children at the center of the screen. */
function ScreenCenter({ children }: ScreenCenterProps) {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="80vh">
      {children}
    </Box>
  );
}

export default ScreenCenter;
