import { ProjectConfig } from '.';

/** Project configuration for the development environment. */
const DevelopmentConfig: ProjectConfig = {
  env: 'development',
  apiBaseUrl: 'http://localhost:3001',
  s3MediasBucketUrl: 'https://delabie-merch-medias.s3.eu-west-3.amazonaws.com/',
  priceOfferMailTo: 'offre-prix@delabie.fr',
};

export default DevelopmentConfig;
