import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { setCreateStatementFormState } from '../../../../store/slices/create-statement-form.slice';
import { boundNumber } from '../../../../utils/number';

/** The minimum step number of the form. */
export const MIN_CREATE_STATEMENT_FORM_STEP = 1;

/** The maximum step number of the form. */
export const MAX_CREATE_STATEMENT_FORM_STEP = 6;

export const CREATE_STATEMENT_FORM_PHOTOS_STEP = 3;

/** The step number of the scanner step. */
export const CREATE_STATEMENT_FORM_SCAN_STEP = 4;

/** Options for the {@link useCreateStatementForm~goToStep} function. */
export interface GoToStepOptions {
  /** Whether to force the navigation and ignore warnings. */
  ignoreWarnings?: boolean;
}

/** A hook for managing the create statement form. */
function useCreateStatementForm() {
  const dispatch = useAppDispatch();
  const createStatementFormState = useAppSelector(state => state.createStatementForm);

  const [isCurrentStepValid, setIsCurrentStepValid] = useState<boolean>(true);
  const [showPhotosUploadingDialog, setShowPhotosUploadingDialog] = useState<boolean>(false);

  /**
   * Go to a specific step.
   * @param step The step to go to.
   */
  function goToStep(step: number, options?: GoToStepOptions) {
    const validStep = boundNumber(step, MIN_CREATE_STATEMENT_FORM_STEP, MAX_CREATE_STATEMENT_FORM_STEP);
    if (validStep === createStatementFormState.step) return;

    if (
      options?.ignoreWarnings !== true &&
      validStep === CREATE_STATEMENT_FORM_PHOTOS_STEP + 1 &&
      createStatementFormState.arePhotosUploading
    ) {
      setShowPhotosUploadingDialog(true);
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('step', String(validStep));
    window.location.search = urlParams.toString();
  }

  /**
   * Validate the first step of the form.
   * @returns Whether the first step of the form is valid.
   */
  function validateStep1(): boolean {
    if (!createStatementFormState.wholesalerId) {
      return false;
    }

    return true;
  }

  /**
   * Validate the second step of the form.
   * @returns Whether the second step of the form is valid.
   */
  function validateStep2(): boolean {
    if (!createStatementFormState.wholesalerNetworkId) {
      return false;
    }
    if (!createStatementFormState.wholesalerSizeId) {
      return false;
    }
    if (!createStatementFormState.layoutBenchmarkId) {
      return false;
    }
    if (!createStatementFormState.contact) {
      return false;
    }
    if (!createStatementFormState.contactPosition) {
      return false;
    }
    if (!createStatementFormState.contactPhone) {
      return false;
    }

    return true;
  }

  /**
   * Validate the third step of the form.
   * @returns Whether the third step of the form is valid.
   */
  function validateStep3(): boolean {
    if (createStatementFormState.publicPlacesMeters === null) {
      return false;
    }
    if (createStatementFormState.renovationMeters === null) {
      return false;
    }
    if (createStatementFormState.accessMeters === null) {
      return false;
    }
    if (!createStatementFormState.linearHeight) {
      return false;
    }
    if (!createStatementFormState.linearWidth) {
      return false;
    }

    return true;
  }

  /**
   * Get the step from the URL.
   * @returns The step from the URL or `null` if there is no step in the URL.
   */
  function getStepFromUrl(): number | null {
    const params = new URLSearchParams(window.location.search);
    if (params.has('step')) {
      return boundNumber(Number(params.get('step')), MIN_CREATE_STATEMENT_FORM_STEP, MAX_CREATE_STATEMENT_FORM_STEP);
    }

    return null;
  }

  // Update the step in the URL when the step changes.
  useEffect(() => {
    const stepFromUrl = getStepFromUrl();
    dispatch(setCreateStatementFormState({ step: stepFromUrl ?? MIN_CREATE_STATEMENT_FORM_STEP }));
  }, []);

  // Update the form validity when the inputs change.
  useEffect(() => {
    if (createStatementFormState.step === 1) {
      setIsCurrentStepValid(validateStep1());
    } else if (createStatementFormState.step === 2) {
      setIsCurrentStepValid(validateStep2());
    } else if (createStatementFormState.step === 3) {
      setIsCurrentStepValid(validateStep3());
    } else {
      setIsCurrentStepValid(true);
    }
  }, [createStatementFormState]);

  return {
    /** Whether the current step is valid. */
    isCurrentStepValid,
    /** Whether the photos are currently uploading. */
    showPhotosUploadingDialog,
    /** Hide the photos uploading dialog. */
    hidePhotosUploadingDialog: () => setShowPhotosUploadingDialog(false),
    goToStep,
  };
}

export default useCreateStatementForm;
