import { Container, Typography } from '@mui/material';
import useTranslate from '../../hooks/useTranslate';
import ScreenCenter from '../../components/layout/ScreenCenter';

function Forbidden() {
  const { t } = useTranslate();

  return (
    <ScreenCenter>
      <Container maxWidth="xs">
        <Typography variant="h1" textAlign="center" fontSize="1.5rem">
          {t('auth.error.forbidden')}
        </Typography>
      </Container>
    </ScreenCenter>
  );
}

export default Forbidden;
