import { useState } from 'react';
import { Box, Container, Snackbar, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import {
  useAcceptBenchmarkProposalMutation,
  useAskBenchmarkProposalReviewMutation,
  useGetBenchmarkProposalQuery,
  useSubmitBenchmarkProposalMutation,
} from '../../../services/benchmark-proposal.service';
import NotFound from '../../NoFound';
import ProposalCard from './components/ProposalCard';
import ProposalHeader, { ProposalTabType } from './components/ProposalHeader';
import LayoutCard from './components/LayoutCard';
import StatementCard from './components/StatementCard';
import useTranslate from '../../../hooks/useTranslate';
import NoReportCard from './components/NoReportCard';
import ReportCard from './components/ReportCard';
import DeviationCard from './components/DeviationCard';

function BenchmarkProposal() {
  const { id } = useParams<{ id: string }>();

  const { t } = useTranslate();

  const { data: proposal, isLoading, refetch } = useGetBenchmarkProposalQuery(id ?? '');

  const [submitBenchmarkProposal] = useSubmitBenchmarkProposalMutation();
  const [askBenchmarkProposalReview] = useAskBenchmarkProposalReviewMutation();
  const [acceptBenchmarkProposal] = useAcceptBenchmarkProposalMutation();

  const [selectedTab, setSelectedTab] = useState<ProposalTabType | undefined>(undefined);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  if (!proposal) {
    if (isLoading) {
      return null;
    }

    return <NotFound />;
  }

  const isArchived = proposal.wholesaler.deletedAt !== null;

  return (
    <>
      <ProposalHeader
        proposal={proposal}
        selectedTab={selectedTab}
        onTabSelected={tab => setSelectedTab(tab)}
        onScheduledInstallationDateUpdated={() => refetch()}
      />
      <Container sx={{ paddingY: 4 }}>
        {isArchived && (
          <Box p={2} borderRadius={1} color="white" sx={{ backgroundColor: 'error.main' }} mb={2}>
            <Typography variant="caption" color="inherit">
              {t('benchmark_proposal.archived')}
            </Typography>
          </Box>
        )}

        {selectedTab === 'report' && (
          <>
            {proposal.interventionReport !== null ? (
              <ReportCard report={proposal.interventionReport} />
            ) : (
              <NoReportCard proposal={proposal} />
            )}
          </>
        )}
        {selectedTab === 'proposal' && (
          <ProposalCard
            proposal={proposal}
            onRefreshProposalRequest={() => refetch()}
            onSubmitProposal={salesComments => {
              submitBenchmarkProposal({ benchmarkProposalId: proposal.id, salesComments })
                .unwrap()
                .then(() => {
                  setSnackbarMessage(t('product_adjustment.submitted'));
                  setShowSnackbar(true);
                  refetch();
                })
                .catch(() => {
                  setSnackbarMessage(t('global.error.unknown'));
                  setShowSnackbar(true);
                });
            }}
            onAskReview={salesManagerComments => {
              if (!salesManagerComments) return;

              askBenchmarkProposalReview({ benchmarkProposalId: proposal.id, salesManagerComments })
                .unwrap()
                .then(() => {
                  setSnackbarMessage(t('product_adjustment.review_submitted'));
                  setShowSnackbar(true);
                  refetch();
                })
                .catch(() => {
                  setSnackbarMessage(t('global.error.unknown'));
                  setShowSnackbar(true);
                });
            }}
            onAccept={() => {
              acceptBenchmarkProposal(proposal.id)
                .unwrap()
                .then(() => {
                  setSnackbarMessage(t('benchmark_proposal.accepted'));
                  setShowSnackbar(true);
                  refetch();
                })
                .catch(() => {
                  setSnackbarMessage(t('global.error.unknown'));
                  setShowSnackbar(true);
                });
            }}
          />
        )}
        {selectedTab === 'layout' && <LayoutCard proposal={proposal} />}
        {selectedTab === 'statement' && <StatementCard statement={proposal.benchmarkStatement} />}
        {selectedTab === 'deviation' && <DeviationCard proposal={proposal} />}
      </Container>

      <Snackbar
        sx={{ display: 'flex', justifyContent: 'center' }}
        open={showSnackbar}
        autoHideDuration={2000}
        onClose={() => setShowSnackbar(false)}
        message={snackbarMessage}
      />
    </>
  );
}

export default BenchmarkProposal;
