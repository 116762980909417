import { Box, Button, Card, Divider, Stack, Typography } from '@mui/material';
import useTranslate from '../../../../hooks/useTranslate';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  resetCreateReportFormState,
  setCreateReportFormState,
} from '../../../../store/slices/create-report-form.slice';
import { BenchmarkProposal } from '../../../../services/benchmark-proposal.service';

export interface NoReportCardProps {
  proposal: BenchmarkProposal;
}

function NoReportCard({ proposal }: NoReportCardProps) {
  const { t } = useTranslate();

  const dispatch = useAppDispatch();
  const { step, benchmarkProposalId } = useAppSelector(state => state.createReportForm);

  const hasExistingReportForm = Boolean(benchmarkProposalId) && step > 1;

  return (
    <Card variant="outlined" component="section">
      <Typography variant="h6" component="h1" mb={2}>
        {t('report.title')}
      </Typography>
      <Divider />
      <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
        <Typography variant="body1" component="p">
          {hasExistingReportForm ? t('benchmark_proposal.report_started') : t('benchmark_proposal.report_not_started')}
        </Typography>
        <Stack direction="row" spacing={2} mt={2}>
          {hasExistingReportForm && (
            <Button
              variant="outlined"
              color="primary"
              href="/report/create?step=2"
              onClick={() => {
                dispatch(resetCreateReportFormState());
                dispatch(
                  setCreateReportFormState({
                    benchmarkProposalId: proposal.id,
                    wholesalerId: proposal.wholesaler.id,
                    step: 2,
                  }),
                );
              }}>
              {t('report.new')}
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            href={`/report/create?step=${step > 1 ? step : 2}`}
            onClick={() => {
              if (hasExistingReportForm) return;

              dispatch(
                setCreateReportFormState({
                  benchmarkProposalId: proposal.id,
                  wholesalerId: proposal.wholesaler.id,
                  step: step > 1 ? step : 2,
                }),
              );
            }}>
            {hasExistingReportForm ? t('global.resume') : t('report.new')}
          </Button>
        </Stack>
      </Box>
    </Card>
  );
}

export default NoReportCard;
