import { useEffect, useState } from 'react';
import useTranslate from '../../../../../hooks/useTranslate';
import { Wholesaler, useGetWholesalersQuery } from '../../../../../services/wholesaler.service';
import { CircularProgress, LinearProgress, Stack, Typography } from '@mui/material';
import WholesalerCard from '../../../../../components/wholesaler/WholesalerCard';
import SearchInput from '../../../../../components/ui/form/SearchInput';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { setCreateReportFormState } from '../../../../../store/slices/create-report-form.slice';
import useScrollable from '../../../../../hooks/useScrollable';

function CreateReportStep1WholesalerTab() {
  const { t } = useTranslate();

  const dispatch = useAppDispatch();
  const { wholesalerId } = useAppSelector(state => state.createReportForm);

  const [page, setPage] = useState<number>(1);
  const [query, setQuery] = useState<string>('');

  const [wholesalers, setWholesalers] = useState<Wholesaler[]>([]);
  const { data, isLoading, isFetching } = useGetWholesalersQuery({ page, query });

  useScrollable('window', {
    onBottomReached: () => {
      if (isLoading || isFetching || !data?.pageInfo.hasNextPage) return;
      setPage(page + 1);
    },
  });

  function handleSearch(evt: React.ChangeEvent<HTMLInputElement>) {
    setQuery(evt.currentTarget.value);
    setPage(1);
  }

  // Merge new wholesalers with the previous ones
  useEffect(() => {
    if (!data) return;

    if (page === 1) {
      setWholesalers(data.edges);
    } else {
      setWholesalers(prev => [...prev, ...data.edges]);
    }
  }, [data]);

  return (
    <Stack direction="column" spacing={2}>
      <SearchInput
        id="search-bar"
        placeholder={t('wholesaler.search')}
        size="small"
        value={query}
        onChange={handleSearch}
      />

      {isLoading && <LinearProgress color="primary" />}

      {wholesalers.map(ws => (
        <WholesalerCard
          key={ws.id}
          wholesaler={ws}
          selected={ws.id === wholesalerId}
          onClick={() =>
            dispatch(
              setCreateReportFormState({
                wholesalerId: ws.id,
              }),
            )
          }
        />
      ))}

      <Stack direction="column" alignItems="center" py={1}>
        {data?.pageInfo.hasNextPage ? (
          <CircularProgress color="primary" size={30} />
        ) : (
          <Typography variant="caption" component="div">
            {t('wholesaler.end_of_the_list')}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}

export default CreateReportStep1WholesalerTab;
