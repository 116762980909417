const FrDictionary = {
  // Global
  'global.yes': 'Oui',
  'global.no': 'Non',
  'global.ref': 'Réf',
  'global.quantity_short': 'Qté',
  'global.status': 'Statut',
  'global.step': 'Étape',
  'global.next': 'Suivant',
  'global.previous': 'Précédent',
  'global.back': 'Retour',
  'global.add': 'Ajouter',
  'global.finalize': 'Finaliser',
  'global.remove': 'Supprimer',
  'global.confirm': 'Confirmer',
  'global.cancel': 'Annuler',
  'global.export': 'Exporter',
  'global.resume': 'Reprendre',
  'global.continue': 'Continuer',
  'global.wait': 'Attendre',
  'global.update': 'Mettre à jour',
  'global.all_male': 'Tous',
  'global.all_female': 'Toutes',
  'global.none': 'Aucun(e)',
  'global.name': 'Nom',
  'global.photos': 'Photos',
  'global.archived': 'Archivé',
  'global.year': 'Année',
  'global.month': 'Mois',
  'global.month.january': 'Janvier',
  'global.month.february': 'Février',
  'global.month.march': 'Mars',
  'global.month.april': 'Avril',
  'global.month.may': 'Mai',
  'global.month.june': 'Juin',
  'global.month.july': 'Juillet',
  'global.month.august': 'Août',
  'global.month.september': 'Septembre',
  'global.month.october': 'Octobre',
  'global.month.november': 'Novembre',
  'global.month.december': 'Décembre',
  'global.city': 'Ville',
  'global.zone': 'Zone',
  'global.department': 'Département',
  'global.address': 'Adresse',
  'global.actions': 'Actions',
  'global.error.unknown': 'Une erreur est survenue',
  'global.error.inlavid_date': 'Date invalide',

  // Auth
  'auth.login': 'Connexion',
  'auth.sign_in': 'Se connecter',
  'auth.sign_out': 'Se déconnecter',
  'auth.email': 'Email',
  'auth.password': 'Mot de passe',
  'auth.password_confirm': 'Confirmation du mot de passe',
  'auth.new_password': 'Nouveau mot de passe',
  'auth.update_password': 'Mettre à jour',
  'auth.password_strength': 'Le mot de passe doit contenir au moins 12 caractères dont une majuscule, une minuscule et un chiffre.',
  'auth.error.forbidden': 'Vous n’avez pas les droits suffisants pour accéder à cette page.',
  'auth.error.empty_email_or_password': 'Veuillez renseigner votre email et votre mot de passe.',
  'auth.error.invalid_email_or_password': 'Email ou mot de passe incorrect. Veuillez réessayer.',
  'auth.error.invalid_password_reset_token': 'Le lien de réinitialisation du mot de passe est invalide ou a expiré.',
  'auth.error.passwords_missmatch': 'Les mots de passe ne correspondent pas.',

  // User
  'user.title': 'Utilisateur',
  'user.full_name': 'Nom et prénom',
  'user.role.sales': 'Commercial',
  'user.role.sales_coordinator': 'Animateur',
  'user.role.sales_manager': 'Chef des ventes',
  'user.role.sales_director': 'Directeur commercial',
  'user.all_sales': 'Tous les commerciaux',
  'user.all_sales_coordinators': 'Tous les animateurs',

  // Dashboard
  'dashboard.title': 'Tableau de bord',
  'dashboard.statement_creation': 'Création d’un nouveau relevé',
  'dashboard.statement_creation_description': 'Le relevé permet de faire un état des lieux du magasin et de proposer une nouvelle implantation pour un client.',
  'dashboard.create_statement': 'Créer un nouveau relevé',
  'dashboard.report_creation': 'Création d’un nouveau rapport d’intervention',
  'dashboard.report_creation_description': 'Le rapport d’intervention permet d’avertir le groupe de la nouvelle implantation chez un grossiste.',
  'dashboard.create_report': 'Créer un nouveau rapport d’intervention',
  'dashboard.your_wholesalers': 'Vos grossistes',
  'dashboard.your_wholesalers_description': 'C’est l’ensemble des grossistes qui vous sont associés sur l’application. Il vous permet d’avoir un historique de vos actions.',
  'dashboard.your_wholesalers_description_manager': 'C’est l’ensemble des grossistes qui vous sont associés sur l’application. Il vous permet d’avoir un historique des actions effectués par les commerciaux et animateurs.',
  'dashboard.see_wholesalers': 'Accéder à la liste des grossistes',
  'dashboard.activity_monitoring': 'Suivi d’activité',
  'dashboard.number_of_implanted_meters': 'Nombre de mètres linéaires implantés',
  'dashboard.number_of_reports': 'Nombre d’implantations effectuées',

  // Photos
  'photos.title': 'Photos du rayon',

  // Comments
  'comments.title': 'Commentaire',
  'comments.sales': 'Commentaire commercial',
  'comments.sales_manager': 'Commentaire chef de vente',
  'comments.submit': 'Valider le commentaire',

  // Review
  'review.title': 'Révision',
  'review.submit': 'Demander une modification',

  // Statement
  'statement.title': 'Relevé d’implantation',
  'statement.date': 'Relevé du {date}',
  'statement.layout': 'Agencement',
  'statement.contact': 'Contact',
  'statement.layout_and_contact': 'Agencement & Contact',
  'statement.contact_details': 'Coordonnées',
  'statement.training': 'Formation',
  'statement.create': 'Créer un relevé',
  'statement.create.statement_in_progress': 'Relevé en cours',
  'statement.create.statement_in_progress_description': ' Vous avez déjà un relevé en cours de création. Voulez-vous le continuer ou en commencer un nouveau ?',
  'statement.create.resume': 'Reprendre',
  'statement.create.new_statement': 'Nouveau relevé',
  'statement.create.step_1.title': 'Sélection du grossiste',
  'statement.create.step_2.title': 'Informations',
  'statement.create.step_3.title': 'Agencement',
  'statement.create.step_4.title': 'Scannez les produits',
  'statement.create.step_5.title': 'Liste des produits scannés',
  'statement.create.step_6.title': 'Commentaire et finalisation',
  'statement.create.form.network_label': 'Réseau',
  'statement.create.form.size_label': 'Taille',
  'statement.create.form.benchmark_layout_label': 'L’implantation de référence pour ce type de grossiste est par défaut',
  'statement.create.form.contact_label': 'Contact',
  'statement.create.form.contact_position_label': 'Fonction',
  'statement.create.form.contact_phone_label': 'Téléphone',
  'statement.create.form.contact_email_label': 'Adresse email',
  'statement.create.form.required_training_label': 'Une formation est-elle à prévoir ?',
  'statement.create.form.required_training_comments': 'Commentaire associé',
  'statement.create.form.linear_meters_implanted_label': 'Nombre de mètres déjà implantés',
  'statement.create.form.available_tablets_label': 'Tablettes disponibles ?',
  'statement.create.form.perforated_bottoms_label': 'Fonds perforés installés ou possibilité d’en installer?',
  'statement.create.form.available_load_bars_label': 'Barres de charges disponibles ?',
  'statement.create.form.linear_meters_label': 'Mètres de linéaires mis à disposition par le client pour les gammes',
  'statement.create.form.linear_height_label': 'Hauteur linéaire',
  'statement.create.form.linear_width_label': 'Largeur linéaire',
  'statement.create.form.photos_label': 'Télécharger au moins 1 photo du rayon',
  'statement.create.form.add_photo': 'Ajouter des photos du rayon',
  'statement.create.form.photos_uploading_title': 'Photos en cours de téléchargement',
  'statement.create.form.photos_uploading_description': 'Certaines photos toujours en cours de téléchargement. Si vous passez à l’étape suivante, elles ne seront pas prises en compte.',
  'statement.create.form.current_quantity_label': 'Quantité actuelle',
  'statement.create.form.update_quantity': 'Mettre à jour la quantité',
  'statement.create.form.listing_over': 'Listing terminé',
  'statement.create.search_reference': 'Recherche une référence',
  'statement.create.product_already_added': 'Déjà ajouté',
  'statement.create.no_product_for_barcode': 'Aucun produit pour le code barre "{barcode}"',
  'statement.create.manual_ref_entry': 'Saisie manuelle d’une référence',
  'statement.create.back_to_scan': 'Reprendre le scan',
  'statement.create.product_added': 'Produit ajouté',
  'statement.create.product_removed': 'Produit supprimé',
  'statement.create.quantity_updated': 'Quantité mise à jour',
  'statement.create.comments_label': 'Entrez vos commentaires',
  'statement.contact.updated': 'Contact mis à jour',
  'statement.contact.updated.error': 'Erreur lors de la mise à jour du contact',
  'statement.error.reference_required': 'Veuillez renseigner une référence',
  'statement.error.reference_already_added': 'Référence déjà ajoutée',
  'statement.error.desktop_forbidden': 'Rendez-vous sur l’interface mobile pour effectuer le relevé',
  'statement.error.select_wholesaler': 'Sélectionnez un grossiste',
  'statement.error.select_network_first': 'Sélectionnez d’abord un réseau',
  'statement.error.quantity_required': 'Veuillez renseigner une quantité',
  'statement.error.no_product_for_research': 'Aucun résultat pour "{query}" dans "{market}"',
  'statement.error.no_product_for_market': 'Aucun produit dans "{market}"',
  'statement.error.unknown_reference': 'Référence inconnue',

  // Markets
  'market.title': 'Gamme',
  'market.public_places': 'Lieux publics',
  'market.access': 'Accessibilité / Accessoires',
  'market.renovation': 'Rénovation',
  'market.stainless_steel_sanitary': 'Appareils sanitaires Inox',
  'market.professional_kitchens': 'Cuisines professionnelles',
  'market.hospitals': 'Hôpitaux',
  'market.others': 'Autres',

  // Wholesaler
  'wholesaler.title': 'Grossiste',
  'wholesaler.name': 'Nom grossiste',
  'wholesaler.id': 'Identifiant grossiste',
  'wholesaler.id_code': 'Code client',
  'wholesaler.address': 'Adresse grossiste',
  'wholesaler.search': 'Rechercher un grossiste',
  'wholesaler.list': 'Liste des grossistes',
  'wholesaler.select': 'Sélectionner un grossiste',
  'wholesaler.end_of_the_list': 'Pas plus de grossistes à afficher',
  'wholesaler.empty_for_filters': 'Pas de grossiste pour ces filtres.',
  'wholesaler.last_benchmark': 'Dernière implantation effectuée',
  'wholesaler.archived': 'Ce grossiste a été archivé. Aucun nouveau relevé n’est possible. Les modifications sont désactivées pour ce grossiste.',

  // Layout
  'layout.title': 'Implantation',
  'layout.benchmark': 'Implantation de référence',

  // Product
  'product.title': 'Produit',
  'product.name': 'Nom produit',
  'product.list': 'Liste produits',
  'product.implanted_list': 'Produits implantés',
  'product.empty': 'Aucun produit',
  'product.search_ref': 'Rechercher une référence',
  'product.details_url': 'Lien vers la fiche produit',

  // Benchmark proposal
  'benchmark_proposal.title': 'Proposition de nouvelle implantation',
  'benchmark_proposal.title_accepted': 'Nouvelle implantation',
  'benchmark_proposal.status.to_negociate': 'Proposition à négocier',
  'benchmark_proposal.status.accepted_by_wholesaler': 'En attente de validation',
  'benchmark_proposal.status.accepted_by_sales_manager': 'En attente d’implantation',
  'benchmark_proposal.status.to_be_revised': 'Proposition à réviser',
  'benchmark_proposal.status.done': 'Implantation effectuée',
  'benchmark_proposal.status': 'Statut',
  'benchmark_proposal.intial_statement': 'Relevé initial',
  'benchmark_proposal.date': 'Date implantation',
  'benchmark_proposal.scheduled_intervention_date': 'Date d’implantation prévue',
  'benchmark_proposal.scheduled_on': 'Prévue le {date}',
  'benchmark_proposal.scheduled_date': 'Prévue le',
  'benchmark_proposal.sales': 'Commercial',
  'benchmark_proposal.coordinator': 'Animateur',
  'benchmark_proposal.name': 'Nom',
  'benchmark_proposal.zip': 'Code postal',
  'benchmark_proposal.city': 'Ville',
  'benchmark_proposal.form.ask_price_offer': 'Demander une offre de prix',
  'benchmark_proposal.form.submit': 'Valider l’implantation',
  'benchmark_proposal.form.edit': 'Modifier l’implantation',
  'benchmark_proposal.form.confirm_submit': 'Envoyer la proposition',
  'benchmark_proposal.form.confirm_submit_description': 'Êtes-vous sûr de vouloir envoyer l’implantation en validation à votre chef des ventes ?',
  'benchmark_proposal.report_started': 'Vous avez déjà commencé un rapport pour cette nouvelle implantation.',
  'benchmark_proposal.report_not_started': 'Vous n’avez pas encore commencé de rapport pour cette nouvelle implantation.',
  'benchmark_proposal.empty': 'Pas de proposition d’implantation.',
  'benchmark_proposal.empty_for_filters': 'Pas de proposition d’implantation pour ces filtres.',
  'benchmark_proposal.accept': 'Accepter la proposition d’implantation',
  'benchmark_proposal.accepted': 'Proposition acceptée',
  'benchmark_proposal.archived': 'Le grossiste a été archivé pour cette proposition. Sa modification est désactivée.',

  // Product adjustment
  'product_adjustment.title': 'Ajustement produit',
  'product_adjustment.product_name': 'Nom produit',
  'product_adjustment.product_ref': 'Réf. produit',
  'product_adjustment.status': 'Statut',
  'product_adjustment.current_quantity': 'Quantité actuelle',
  'product_adjustment.current_quantity_short': 'Qté. actuelle',
  'product_adjustment.adjustment': 'Ajustement',
  'product_adjustment.minimum_quantity': 'Quantité minimum recommandée',
  'product_adjustment.minimum_quantity_short': 'Qté. min. reco.',
  'product_adjustment.actions': 'Actions',
  'product_adjustment.edit_quantity': 'Modifier la valeur de l’ajustement',
  'product_adjustment.value': 'Valeur de l’ajustement',
  'product_adjustment.confirm_delete': 'Confirmer la suppression',
  'product_adjustment.confirm_move': 'Confirmer le déplacement',
  'product_adjustment.delete_description': 'Êtes-vous sûrs de vouloir supprimer ce produit de la proposition ?',
  'product_adjustment.move_keep': 'Êtes-vous sûrs de vouloir déplacer ce produit dans la catégorie “À valider ou à retirer” ?',
  'product_adjustment.move_restock': 'Êtes-vous sûrs de vouloir déplacer ce produit dans la catégorie “À valider ou à retirer” ?',
  'product_adjustment.move_remove': 'Êtes-vous sûrs de vouloir déplacer ce produit dans la catégorie “À garder” ?',
  'product_adjustment.add': 'Ajouter un produit',
  'product_adjustment.status.keep': 'Meilleures ventes / Garder',
  'product_adjustment.status.missing': 'Manquant',
  'product_adjustment.status.restock': 'À réappro.',
  'product_adjustment.status.remove': 'À valider ou à retirer',
  'product_adjustment.created': 'Produit ajouté',
  'product_adjustment.deleted': 'Produit supprimé',
  'product_adjustment.updated': 'Produit mis à jour',
  'product_adjustment.submitted': 'La proposition d’implantation a été soumise',
  'product_adjustment.review_submitted': 'La demande de révision a été soumise',
  'product_adjustment.error.existing': 'Ce produit est déjà dans la proposition',

  // Intervention report
  'report.title': 'Rapport d’intervention',
  'report.new': 'Nouveau rapport',
  'report.pending_reports': 'Rapports en attente',
  'report.deviation_from_proposal': 'Déviation par rapport à la proposition',
  'report.number_of_meters': 'Nombre de mètres linéaire implantés',
  'report.no_associated_statement': 'Aucun relevé associé',
  'report.no_associated_proposal': 'Aucune proposition associée',
  'report.create.report_in_progress': 'Rapport en cours',
  'report.create.report_in_progress_description': ' Vous avez déjà un rapport en cours de création. Voulez-vous le continuer ou en commencer un nouveau ?',
  'report.create.resume': 'Reprendre',
  'report.create.new_report': 'Nouveau rapport',
  'report.create.step_1.title': 'Choix de l’implantation à réaliser',
  'report.create.step_2.title': 'Agencement',
  'report.create.step_3.title': 'Scannez les produits',
  'report.create.step_4.title': 'Liste des produits scannés',
  'report.create.step_5.title': 'Photos et finalisation',
  'report.create.missing_product_notice': 'Les produits marqués comme “Manquant” sont ceux supposés être présents d’après la proposition d’implantation, mais n’ayant pas été scannés.',
  'report.create.form.listing_over': 'Listing terminé',
  'report.error.desktop_forbidden': 'Rendez-vous sur l’interface mobile pour effectuer le rapport',
  'report.error.select_wholesaler': 'Sélectionnez un grossiste',

  // Product deviation
  'product_deviation.status.all': 'Tous',
  'product_deviation.status.missing': 'Absent',
  'product_deviation.status.additional': 'Supplémentaire',
  'product_deviation.empty': 'Pas de déviations',

  // 404
  '404.title': '404',
  '404.description': 'La page que vous recherchez n’existe pas.',
  '404.back_home': 'Retourner à l’accueil',
}

export default FrDictionary;
