import { ProductAdjustmentWithStatus } from "../pages/benchmark-proposal/show/components/ProposalCard";
import { BenchmarkProposal, BenchmarkProposalProductAdjustment, BenchmarkProposalStatus, BenchmarkProposalSummary } from "../services/benchmark-proposal.service";
import { BenchmarkStatementProduct } from "../services/benchmark-statement.service";
import { LayoutBenchmark } from "../services/layout-benchmark.service";
import { UserRole, UserSummary } from "../services/user.service";
import { getManagers } from "./user";

/**
 * The status of a product adjustment of a benchmark proposal.
 * - `keep`: The product stock should be kept as is.
 * - `missing`: The product is missing from the wholesaler's store.
 * - `restock`: The product should be restocked in the wholesaler's store.
 * - `remove`: The product should be removed from the wholesaler's store.
 */
export type ProductAdjustmentStatus = 'keep' | 'missing' | 'restock' | 'remove';

/** The color of the status of a benchmark proposal. This a partial copy of the Material Theme colors. */
export type StatusColor = 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';

/**
 * Get the color of the status of a benchmark proposal.
 * @param status The status of the benchmark proposal.
 * @returns The color of the status.
 */
export function getStatusColor(status: BenchmarkProposalStatus): StatusColor {
  switch (status) {
    case BenchmarkProposalStatus.TO_NEGOCIATE:
      return 'info';
    case BenchmarkProposalStatus.ACCEPTED_BY_WHOLESALER:
      return 'secondary';
    case BenchmarkProposalStatus.ACCEPTED_BY_SALES_MANAGER:
      return 'warning';
    case BenchmarkProposalStatus.TO_BE_REVISED:
      return 'error';
    case BenchmarkProposalStatus.DONE:
      return 'success';
  }
}

/**
 * Get the status of the adjustment of a product.
 * @param adjustment The adjustment of the product.
 * @param productStatement The corresponding product statement of the adjustement, if any.
 * @returns The status of the adjustment.
 */
export function getProductAdjustmentStatus(adjustment: BenchmarkProposalProductAdjustment, productStatement?: BenchmarkStatementProduct, benchmarkLayout?: LayoutBenchmark): ProductAdjustmentStatus {
  const currentQuantity = productStatement?.quantity ?? 0;

  // Edge case: the product is not in the layout, the adjustment is 0, and the quantity is 0.
  // Quantity can be 0 if the product barcode is in the wholesaler shop but there is no stock.
  const productInLayout = benchmarkLayout?.products.find(item => item.product.ref === adjustment.product.ref);
  if (!productInLayout && adjustment.quantityAdjustment === 0 && currentQuantity === 0) {
    return 'remove';
  }

  if (adjustment.quantityAdjustment < 0 || (adjustment.quantityAdjustment === 0 && currentQuantity > 0 && !productInLayout)) {
    return 'remove';
  }
  else if (adjustment.quantityAdjustment === 0) {
    return 'keep';
  }
  else if (currentQuantity > 0) {
    return 'restock';
  }
  else {
    return 'missing';
  }
}

/**
 * Get the color of the status of a product adjustment.
 * @param status The status of the product adjustment.
 * @returns The color of the status.
 */
export function getProductAdjustmentStatusColor(status: ProductAdjustmentStatus): StatusColor {
  switch (status) {
    case 'keep':
      return 'success';
    case 'missing':
      return 'error';
    case 'restock':
      return 'error';
    case 'remove':
      return 'info';
  }
}

/**
 * A comparison function for sorting product adjustments.
 * @param a The first product adjustment.
 * @param b The second product adjustment.
 * @returns A number indicating the order of the product adjustments.
 */
export function compareProductAdjustmentsByStatus(a: ProductAdjustmentWithStatus, b: ProductAdjustmentWithStatus): number {
  const statusOrder = ['keep', 'restock', 'missing', 'remove'];
  return statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);
}

/**
 * Adds the status to the product adjustments of a benchmark proposal.
 * @param benchmarkProposal The benchmark proposal to add the status to the product adjustments.
 * @returns The product adjustments with their computed status.
 */
export function addStatusToProductAdjustments(benchmarkProposal: BenchmarkProposal): ProductAdjustmentWithStatus[] {
  return benchmarkProposal.productAdjustments
    .map(adjustment => {
      const productStatement = benchmarkProposal.benchmarkStatement.products.find(
        item => item.product.ref === adjustment.product.ref,
      );
      return {
        ...adjustment,
        status: getProductAdjustmentStatus(adjustment, productStatement, benchmarkProposal.benchmarkStatement.benchmarkLayout),
      };
    });
}

/**
 * Get the min and max createdAt date of the benchmark proposals of the list.
 * @param benchmarkProposals The benchmark proposals to get the min and max date from.
 * @returns The min and max date of the benchmark proposals.
 */
export function getMinAndMaxDate(benchmarkProposals: (BenchmarkProposal | BenchmarkProposalSummary)[]): { minDate: Date, maxDate: Date } {
  if (benchmarkProposals.length === 0) {
    const aYearFromNow = new Date();
    aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);
    return { minDate: new Date(0), maxDate: aYearFromNow };
  }

  let minDate: Date = new Date(benchmarkProposals[0].createdAt);
  let maxDate: Date = new Date(benchmarkProposals[0].createdAt);

  benchmarkProposals.forEach(item => {
    const createdAt = new Date(item.createdAt);
    if (createdAt < minDate) {
      minDate = createdAt;
    }
    if (createdAt > maxDate) {
      maxDate = createdAt;
    }
  });

  return { minDate, maxDate };
}

/**
 * Get the coordinators of a benchmark proposal.
 * @param proposal The benchmark proposal to get the coordinators from.
 * @returns The coordinators of the benchmark proposal.
 */
export function getProposalCoordinators(proposal: BenchmarkProposalSummary): UserSummary[] {
  let coordinators = getManagers(proposal.sales, UserRole.SALES_COORDINATOR);
  if (proposal.expectedCoordinator) {
    coordinators = [proposal.expectedCoordinator];
  }
  if (proposal.interventionReport) {
    coordinators = [proposal.interventionReport.coordinator];
  }

  return coordinators;
}

/**
 * Generates the title for the recap of the benchmark proposal.
 * @param proposal The benchmark proposal to get the title from.
 * @returns The title for the recap of the benchmark proposal.
 */
export function getProposalRecapTitle(proposal: BenchmarkProposal): string {
  const proposalDateForTitle = new Date(proposal.benchmarkStatement.createdAt)
    .toISOString()
    .split('T')[0]
    .replace(/-/g, '');

  return `${proposal.wholesaler.id}_${proposalDateForTitle}`;
}