import { BenchmarkProposal } from "../services/benchmark-proposal.service";
import { Product } from "../services/product.service";

/**
 * The status of a product deviation.
 * - `missing`: The product is missing from the intervention report.
 * - `additional`: The product is in the intervention report but not in the proposal.
 */
export type ProductDeviationStatus = 'missing' | 'additional';

/** A product deviation between a proposal and its intervention report. */
export interface ProductDeviation {
  /** The product. */
  product: Product;
  /** The status of the deviation. */
  status: ProductDeviationStatus;
}

/**
 * Get all product deviations between a proposal and its intervention report.
 * @param proposal The proposal to get the deviations from.
 * @returns The product deviations.
 */
export function getProductDeviationsFromProposal(proposal: BenchmarkProposal): ProductDeviation[] {
  // If there is no intervention report, all products are missing
  if (!proposal.interventionReport) {
    return proposal.productAdjustments.map(adjustment => ({
      product: adjustment.product,
      status: 'missing',
    }));
  }

  const deviations: ProductDeviation[] = [];

  // Find missing products
  for (const adjustment of proposal.productAdjustments) {
    const exists = proposal.interventionReport.products.some(stmt => stmt.product.ref === adjustment.product.ref);
    if (!exists) {
      deviations.push({ product: adjustment.product, status: 'missing' });
    }
  }

  // Find additional products
  for (const stmt of proposal.interventionReport.products) {
    const exists = proposal.productAdjustments.some(adjustment => adjustment.product.ref === stmt.product.ref);
    if (!exists) {
      deviations.push({ product: stmt.product, status: 'additional' });
    }
  }

  return deviations;
}

/**
 * Get the color to use for a product deviation status.
 * @param status The status of the deviation.
 * @returns The color to use for the status.
 */
export function getProductDeviationStatusColor(status: ProductDeviationStatus) {
  switch (status) {
    case 'missing':
      return 'error';
    case 'additional':
      return 'primary';
  }
}