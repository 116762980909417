import { useAppSelector } from '../store';
import { Outlet, useNavigate } from 'react-router-dom';
import Forbidden from '../pages/auth/Forbidden';
import { UserRole } from '../services/user.service';

export interface AuthGuardProps {
  /**
   * The user role needed to access the route. If the user role does not match, the user will be redirected to the forbidden page.
   * @optional If not provided, the user role will not be checked.
   */
  role?: UserRole;
}

/**
 * Provides a guard for authenticated users.
 * Unauthenticated users will be redirected to the sign in form for routes taht are wrapped in the guard.
 */
function AuthGuard({ role }: AuthGuardProps) {
  const navigate = useNavigate();
  const { isAuthenticated, user } = useAppSelector(state => state.auth);

  if (!isAuthenticated) {
    navigate('/');
    return null;
  }

  if (role && role !== user?.role) {
    return <Forbidden />;
  }

  return <Outlet />;
}

export default AuthGuard;
