import { Chip, TableCell, TableRow } from '@mui/material';
import ProductPresentation from '../../components/product/ProductPresentation';
import { ProductStatement } from '../../store/types';
import useTranslate from '../../hooks/useTranslate';

export interface ProductStatementRowProps {
  item: ProductStatement;
  showAsMissing?: boolean;
  onClick?: () => void;
}

function ProductStatementRow({ item, showAsMissing = false, onClick }: ProductStatementRowProps) {
  const { t } = useTranslate();

  return (
    <TableRow key={item.product.ref}>
      <TableCell sx={{ paddingY: 1, paddingX: 1 }} onClick={onClick}>
        <ProductPresentation product={item.product} />
      </TableCell>
      <TableCell align="right" sx={{ paddingY: 1 }} onClick={onClick}>
        {showAsMissing ? (
          <Chip label={t(`product_adjustment.status.missing`)} size="small" color="error" />
        ) : (
          item.quantity
        )}
      </TableCell>
    </TableRow>
  );
}

export default ProductStatementRow;
