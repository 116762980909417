import { useEffect, useState } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Paper, Tab } from '@mui/material';
import ProductStatementTable from './ProductStatementTable';
import useTranslate from '../../hooks/useTranslate';
import { Product, ProductMarket } from '../../services/product.service';
import SearchInput from '../../components/ui/form/SearchInput';
import { ProductStatement } from '../../store/types';
import { castProductMarketToMainMarketOrOthers } from '../../helpers/product';

interface ProductStatementListTab {
  title: string;
  value: ProductMarket | 'others';
  items: ProductStatement[];
  missingProducts: Product[];
}

export interface ProductStatementListProps {
  items: ProductStatement[];
  missingProducts?: Product[];
  actionsComponent?: React.ReactNode;
  onItemClicked?: (item: ProductStatement) => void;
}

function ProductStatementList({
  items,
  missingProducts = [],
  actionsComponent,
  onItemClicked,
}: ProductStatementListProps) {
  const { t } = useTranslate();

  const [query, setQuery] = useState<string>('');
  const [tabs, setTabs] = useState<ProductStatementListTab[]>([
    {
      title: t('market.public_places'),
      value: 'public_places',
      items: [],
      missingProducts: [],
    },
    {
      title: t('market.renovation'),
      value: 'renovation',
      items: [],
      missingProducts: [],
    },
    {
      title: t('market.access'),
      value: 'access',
      items: [],
      missingProducts: [],
    },
    {
      title: t('market.others'),
      value: 'others',
      items: [],
      missingProducts: [],
    },
  ]);

  const [selectedTab, setSelectedTab] = useState<ProductMarket | 'others'>('public_places');

  useEffect(() => {
    const lowerCaseQuery = query.toLowerCase();
    const matchingItems =
      query.length === 0
        ? items
        : items.filter(
            item =>
              item.product.ref.toLowerCase().startsWith(lowerCaseQuery) ||
              item.product.barcodeRef.toLowerCase().startsWith(lowerCaseQuery),
          );
    const matchingMissingProducts =
      query.length === 0
        ? missingProducts
        : missingProducts?.filter(
            product =>
              product.ref.toLowerCase().startsWith(lowerCaseQuery) ||
              product.barcodeRef.toLowerCase().startsWith(lowerCaseQuery),
          );

    const newTabs = tabs.map(tab => ({
      ...tab,
      items: matchingItems.filter(item => castProductMarketToMainMarketOrOthers(item.product) === tab.value),
      missingProducts: matchingMissingProducts?.filter(
        product => castProductMarketToMainMarketOrOthers(product) === tab.value,
      ),
    }));

    if (JSON.stringify(newTabs) === JSON.stringify(tabs)) return;

    setTabs(newTabs);
    if (
      matchingItems.length !== 0 &&
      !matchingItems.some(item => castProductMarketToMainMarketOrOthers(item.product) === selectedTab)
    ) {
      setSelectedTab(castProductMarketToMainMarketOrOthers(matchingItems[0].product));
    }
  }, [items, missingProducts, query, tabs]);

  return (
    <Paper elevation={0}>
      <SearchInput
        id="search-bar"
        placeholder={t('statement.create.search_reference')}
        value={query}
        onChange={evt => setQuery(evt.currentTarget.value)}
        size="small"
        fullWidth
      />
      <TabContext value={selectedTab}>
        <Box mt={1} sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList variant="fullWidth" onChange={(_, value) => setSelectedTab(value)} aria-label="statement markets">
            {tabs.map(tab => (
              <Tab key={tab.value} label={tab.title} value={tab.value} wrapped />
            ))}
          </TabList>
        </Box>

        {actionsComponent}

        {tabs.map(tab => (
          <TabPanel key={tab.value} value={tab.value} sx={{ padding: 0 }}>
            <ProductStatementTable
              title={tab.title}
              items={tab.items}
              missingProducts={tab.missingProducts}
              userQuery={query}
              onItemClicked={onItemClicked}
            />
          </TabPanel>
        ))}
      </TabContext>
    </Paper>
  );
}

export default ProductStatementList;
