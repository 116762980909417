import React, { useEffect, useState } from 'react';
import { Box, Button, Card, Container, Stack, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useTranslate from '../../hooks/useTranslate';
import ScreenCenter from '../../components/layout/ScreenCenter';
import { useResetPasswordMutation } from '../../services/auth.service';
import { isPasswordValid } from '../../helpers/auth';
import { useAppDispatch } from '../../store';
import { signIn } from '../../store/slices/auth.slice';
import styled from '@emotion/styled';

function Password() {
  const { t } = useTranslate();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [password, setPassword] = useState<string>('');
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [passwordConfirmationError, setPasswordConfirmationError] = useState<string | null>(null);
  const [passwordResetToken, setPasswordResetToken] = useState<string>('');

  const [resetPassword] = useResetPasswordMutation();

  async function handleSubmit(evt: React.FormEvent) {
    evt.preventDefault();
    evt.stopPropagation();

    setPasswordError(null);
    setPasswordConfirmationError(null);

    if (!isPasswordValid(password)) {
      setPasswordError(t('auth.password_strength'));
      return;
    }

    if (password !== passwordConfirmation) {
      setPasswordConfirmationError(t('auth.error.passwords_missmatch'));
      return;
    }

    resetPassword({ password, passwordResetToken })
      .unwrap()
      .then(({ access_token, refresh_token }) => {
        dispatch(signIn({ accessToken: access_token, refreshToken: refresh_token }));
        navigate('/');
      })
      .catch(() => {
        setPasswordError(t('auth.error.invalid_password_reset_token'));
      });
  }

  // The the password reset token from the URL
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setPasswordResetToken(urlParams.get('token') || '');
  }, []);

  return (
    <ScreenCenter>
      <Container maxWidth="xs">
        <Card variant="outlined">
          <Box display="flex" justifyContent="center" marginY={1}>
            <Logo src="/assets/delabie-logo.webp" />
          </Box>
          <Typography component="h1" variant="h5" textAlign="center" marginY={2}>
            {t('auth.new_password')}
          </Typography>
          <form onSubmit={handleSubmit}>
            <Stack direction="column" spacing={2}>
              <TextField
                id="password"
                type="password"
                label={t('auth.new_password')}
                variant="outlined"
                error={passwordError !== null}
                helperText={passwordError ?? t('auth.password_strength')}
                onChange={evt => setPassword(evt.currentTarget.value)}
              />

              <TextField
                id="password-confirmation"
                type="password"
                label={t('auth.password_confirm')}
                variant="outlined"
                error={passwordConfirmationError !== null}
                helperText={passwordConfirmationError}
                onChange={evt => setPasswordConfirmation(evt.currentTarget.value)}
              />

              <Button variant="contained" type="submit">
                {t('auth.update_password')}
              </Button>
            </Stack>
          </form>
        </Card>
      </Container>
    </ScreenCenter>
  );
}

const Logo = styled.img`
  height: 24px;
`;

export default Password;
