import { Avatar } from '@mui/material';
import { Product } from '../../services/product.service';
import themeImages from '../../theme/images';

export interface ProductAvatarProps {
  product: Product;
}

function ProductAvatar({ product }: ProductAvatarProps) {
  return (
    <Avatar
      alt={product.title}
      src={product.imageUrl ?? undefined}
      variant="rounded"
      sx={{ backgroundImage: `url('${themeImages.productBackground}')` }}>
      ?
    </Avatar>
  );
}

export default ProductAvatar;
